import React, { useState, useEffect } from "react";
import { Input, Image, Label, InputProps, Button } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";

// UX
import PhoneNumberInput from "./PhoneNumberInput";

// Type
import { registerInfo } from "bplus-lib/register/TypeModal";

// Styles
import "./RegisterCSS.scss";

//type

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
};

const icons = {
  hn: "/images/register/icon_type_hn.png",
  cid: "/images/register/icon_type_cid.png",
  passport: "/images/register/icon_type_passport.png",
  passProtect: "/images/register/create_password.png",
};

type MainRegisterProps = {
  onEvent: (e: any) => any;
  history: any;
  registerInfo?: registerInfo;
  loading?: boolean;
  forgotPass?: boolean;
  uiMode?: string;
};

const MainRegister = (props: MainRegisterProps) => {
  const [useEmail, setUseEmail] = useState<boolean>(false);
  const [typeId, setTypeId] = useState<string>("hn");
  const [valueCid, setValueCid] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  useEffect(() => {
    if (
      props.registerInfo?.typeUsername === "email" &&
      props.registerInfo?.username
    ) {
      setUseEmail(true);
      setUsername(props.registerInfo?.username.replace("-", "@") || "");
    } else if (
      props.registerInfo?.typeUsername === "phoneNumber" &&
      props.registerInfo?.username
    ) {
      setUsername(props.registerInfo?.username || "");
    }
    if (
      props.registerInfo?.typeUsername &&
      (props.registerInfo?.valuecid || props.registerInfo?.valueHn)
    ) {
      if (props.registerInfo?.valueHn) {
        setTypeId("hn");
        setValueCid(props.registerInfo?.valueHn || "");
      } else {
        setTypeId(props.registerInfo?.typeId || "hn");
        setValueCid(props.registerInfo?.valuecid || "");
      }
    }
  }, [props.registerInfo?.typeId, props.registerInfo?.typeUsername]);

  const handleForgotPass = () => {
    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "valuecid", value: valueCid },
    });

    props.onEvent({
      message: "HandleForgotPassword",
      params: { history: props.history },
    });
  };

  const handleRegister = () => {
    handleClearError("message");
    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "valuecid", value: valueCid },
    });

    if (typeId === "hn") {
      let hn = (valueCid || "").replace(/-/g, "");
      hn = hn.replace(
        /\d{2}/g,
        (...reg) => `${reg[0]}${reg[1] <= 2 ? "-" : ""}`
      );
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: { key: "valueHn", value: hn },
      });

      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: { key: "valuecid", value: "" },
      });
    } else {
      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: { key: "valuecid", value: valueCid },
      });

      props.onEvent({
        message: "HandleSetValueRegisterInfo",
        params: { key: "valueHn", value: "" },
      });
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "typeId", value: typeId },
    });

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "username", value: username },
    });

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: {
        key: "typeUsername",
        value: useEmail ? "email" : "phoneNumber",
      },
    });

    props.onEvent({
      message: "HandleRegister",
      params: { history: props.history, forgotPass: props.forgotPass },
    });
  };

  const handleCheckTitleTypeId = () => {
    switch (typeId) {
      case "hn":
        return <FormattedMessage id="bplusClinicKey960" />;
      case "cid":
        return <FormattedMessage id="bplusClinicKey961" />;
      case "passport":
        return <FormattedMessage id="bplusClinicKey962" />;
      default:
        return <FormattedMessage id="bplusClinicKey960" />;
    }
  };

  const handleCheckTitleErrorTypeId = () => {
    switch (typeId) {
      case "hn":
        return <FormattedMessage id="bplusClinicKey1103" />;
      case "cid":
        return <FormattedMessage id="bplusClinicKey1102" />;
      case "passport":
        return <FormattedMessage id="bplusClinicKey962" />;
      default:
        return <FormattedMessage id="bplusClinicKey1103" />;
    }
  };

  const handleCheckIncorrect = () => {
    switch (typeId) {
      case "hn":
        return <FormattedMessage id="bplusClinicKey1125" />;
      case "cid":
        return <FormattedMessage id="bplusClinicKey1126" />;
      case "passport":
        return <FormattedMessage id="bplusClinicKey1127" />;
      default:
        return <FormattedMessage id="bplusClinicKey1125" />;
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};
    if (filed === "username" && props.registerInfo?.error?.message) {
      if (filedAll["message"]) {
        filedAll["message"] = "";
      }
    }
    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  const handleHistoryGoTo = (action: string) => {
    switch (action) {
      case "login":
        props.history.push("/login/?app=MobRegister");
        break;
      default:
        props.history.goBack();
        break;
    }
  };

  const handleCheckFile = () => {
    let disable = false;
    if (props.forgotPass) {
      if (props.registerInfo?.error?.valuecid) {
        disable = true;
      }
    } else {
      if (
        props.registerInfo?.error?.username ||
        props.registerInfo?.error?.valuecid
      ) {
        disable = true;
      }
    }
    return disable;
  };

  const handleSetCIDType = (type: string) => {
    setValueCid("")
    setTypeId(type)
  }

  const handleSetEmailType = (type: boolean) => {
    setUsername("")
    setUseEmail(type)
  }

  console.log("saika ~ props:", props);

  return (
    ////////////////////////////////////////////////////////////////
    //                       forgot pass                           //
    ////////////////////////////////////////////////////////////////
    <>
      {props.forgotPass ? (
        <>
          <div className="title-text-blue">
            {props.forgotPass ? (
              <FormattedMessage id="bplusClinicKey965" />
            ) : (
              <FormattedMessage id="bplusClinicKey962" />
            )}
          </div>
          <div className="text-gray">
            {<FormattedMessage id="bplusClinicKey963" />}
          </div>
          <div className="content-filed-input" style={{ margin: "30px 0px" }}>
            <Label>{<FormattedMessage id="bplusClinicKey964" />}</Label>
            <div onClick={() => handleClearError("valuecid")}>
              <Input
                {...(props.registerInfo?.error?.valuecid && {
                  className: "error-input",
                })}
                value={valueCid || ""}
                onChange={(e: any, v: InputProps) => {
                  setValueCid(v.value);
                }}
              ></Input>
            </div>
            <div
              {...((props.registerInfo?.error?.valuecid ||
                props.registerInfo?.error?.message) && {
                className: "error",
              })}
            >
              {props.registerInfo?.error?.valuecid === "none-file" ? (
                <FormattedMessage id="profile.requiredGuide" />
              ) : (
                props.registerInfo?.error?.message
              )}
            </div>
          </div>
          <div className="div-center" style={{ margin: "10px 0px 30px" }}>
            <Image src={icons.passProtect} />
          </div>
          <div className="div-center-bottom">
            <div className="bplus-buttom">
              <Button
                loading={props.loading}
                disabled={handleCheckFile()}
                onClick={handleForgotPass}
              >
                {<FormattedMessage id="common.confirm" />}
              </Button>
            </div>
          </div>
        </>
      ) : (
        ////////////////////////////////////////////////////////////////
        //                         Register                           //
        ////////////////////////////////////////////////////////////////
        <>
          <div className="title-text-blue">
            {<FormattedMessage id="bplusClinicKey957" />}
          </div>
          <div className="text-gray">
            {useEmail ? (
              <FormattedMessage id="bplusClinicKey1106" />
            ) : (
              <FormattedMessage id="bplusClinicKey978" />
            )}
          </div>
          <div className="type-register">
            <div
              {...(!useEmail && { className: "active" })}
              onClick={() => {handleSetEmailType(false)}}
            >
              {<FormattedMessage id="profile.phone_no" />}
            </div>
            <div
              {...(useEmail && { className: "active" })}
              onClick={() => {handleSetEmailType(true)}}
            >
              {<FormattedMessage id="profile.email" />}
            </div>
          </div>
          <div className="title-text-black">
            {
              <>
                <FormattedMessage id="bplusClinicKey976" />:
              </>
            }
          </div>
          <div className="type-id">
            <div
              {...(typeId === "hn" && { className: "active" })}
              onClick={() => {handleSetCIDType("hn")}}
            >
              <Image src={icons.hn} />{" "}
              {<FormattedMessage id="bplusClinicKey960" />}
            </div>
            <div
              {...(typeId === "cid" && { className: "active" })}
              onClick={() => {handleSetCIDType("cid")}}
            >
              <Image src={icons.cid} />
              {<FormattedMessage id="bplusClinicKey961" />}
            </div>
            <div
              {...(typeId === "passport" && { className: "active" })}
              onClick={() => {handleSetCIDType("passport")}}
            >
              <Image src={icons.passport} />
              {<FormattedMessage id="bplusClinicKey962" />}
            </div>
          </div>
          <div className="content-filed-input">
            <Label>{handleCheckTitleTypeId()}</Label>
            <div onClick={() => handleClearError("valuecid")}>
              <Input
                {...(props.registerInfo?.error?.valuecid && {
                  className: "error-input",
                })}
                value={valueCid || ""}
                onChange={(e: any, v: InputProps) => {
                  setValueCid(v.value);
                }}
              ></Input>
            </div>
            <div
              {...(props.registerInfo?.error?.valuecid && {
                className: "error",
              })}
            >
              {props.registerInfo?.error?.valuecid === "none-file" ? (
                <>
                  {<FormattedMessage id="bplusClinicKey1104" />}{" "}
                  {handleCheckTitleErrorTypeId()}
                </>
              ) : (
                <>{handleCheckIncorrect()}</>
              )}
            </div>
          </div>
          <div
            className={
              useEmail ? "content-filed-input" : "content-filed-input-phone"
            }
          >
            <Label>
              {useEmail ? (
                <FormattedMessage id="profile.email" />
              ) : (
                <FormattedMessage id="profile.phone_no" />
              )}
            </Label>
            <div onClick={() => handleClearError("username")}>
              {useEmail ? (
                <Input
                  {...(props.registerInfo?.error?.username && {
                    className: "error-input",
                  })}
                  value={username || ""}
                  onChange={(e: any, v: InputProps) => {
                    setUsername(v.value);
                  }}
                ></Input>
              ) : (
                <PhoneNumberInput
                  error={
                    props.registerInfo?.error?.username ||
                    props.registerInfo?.error?.message ||
                    ""
                  }
                  value={username || ""}
                  onChange={(e, data) => {
                    setUsername(data.value as string);
                  }}
                />
              )}
            </div>
            <div
              className={
                props.registerInfo?.error?.username ||
                props.registerInfo?.error?.message
                  ? "error"
                  : "normal"
              }
            >
              {props.registerInfo?.error?.message ? (
                `${props.registerInfo?.error?.message}`
              ) : !props.registerInfo?.username ? (
                <>
                  {<FormattedMessage id="bplusClinicKey1104" />}{" "}
                  {useEmail ? (
                    <FormattedMessage id="profile.email" />
                  ) : (
                    <FormattedMessage id="bplusClinicKey1101" />
                  )}
                </>
              ) : (
                <>
                  {useEmail ? (
                    <FormattedMessage id="bplusClinicKey1129" />
                  ) : (
                    <FormattedMessage id="bplusClinicKey1128" />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="div-center-bottom">
            <div className="bplus-buttom">
              <Button
                loading={props.loading}
                disabled={handleCheckFile()}
                onClick={handleRegister}
              >
                {<FormattedMessage id="bplusClinicKey959" />}
              </Button>
            </div>
            <div className="text-backtologin">
              <div>{<FormattedMessage id="bplusClinicKey977" />}</div>
              <div onClick={() => handleHistoryGoTo("login")}>
                {<FormattedMessage id="bplusClinicKey958" />}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(MainRegister);
