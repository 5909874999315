import React, { useState, useMemo, ReactElement } from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// Icon
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// Types
type CardListItemProps = {
  // data
  selected?: number | string;
  items: {
    id: number | string;
    name: string;
    image?: string;
    image_active?: string;
  }[];
  rightIcon?: ReactElement;
  // config
  hideRightIcon?: boolean;
  isIcon?: boolean;
  maxContent?: number;
  maxPlaceholder?: string;
  // callback
  onSelect?: (selected: Record<string, any>) => any;
};

// Styles
const COLORS = {
  font: "rgba(57, 57, 57, 1)",
  border: "rgba(233, 233, 233, 1)",
  very_light_blue: "rgba(236, 244, 255, 1)",
  primary: "var(--blue-bdms-color)",
  bg: "#ECF4FF",
};

const cardStyles = {
  marginBottom: "0.75rem",
  "&.MuiPaper-root": {
    boxShadow: "none",
    border: `1px solid ${COLORS.border}`,
    borderRadius: "8px",
    "& .MuiCardContent-root": {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      alignItems: "center",
      fontWeight: "bold",
      lineHeight: 1.5,
      padding: "16px",
      width: "100%",
      "& > div": {
        display: "flex",
        alignItems: "center",
      },
      "& img": {
        width: "100px",
        height: "55px",
        borderRadius: "8px",
        objectFit: "cover",
        marginRight: "1rem",
      },
      "& .MuiButton-root": {
        minWidth: "32px",
        minHeight: "32px",
        maxWidth: "32px",
        maxHeight: "32px",
        backgroundColor: COLORS.very_light_blue,
        borderRadius: "500rem",
        "& svg": {
          color: COLORS.primary,
          fontSize: "1.35rem",
        },
      },
      "&.--icon": {
        padding: "18px 16px 18px",
        fontWeight: "normal",
        "& img": {
          width: "20px",
          height: "auto",
          marginRight: "0.75rem",
        },
      },
    },
  },
  "&.active": {
    "&.MuiPaper-root": {
      border: `1px solid ${COLORS.primary}`,
      backgroundColor: COLORS.bg,
      color: COLORS.primary,
    },
  },
};

const useStyles = makeStyles(() => ({
  card: cardStyles,
  see_all: {
    color: COLORS.primary,
    fontSize: ".875rem",
    fontWeight: "bold",
    marginTop: "0.65rem",
    display: "flex",
    alignItems: "center",
    textDecorationLine: "underline",
    "& svg": {
      margin: "0 0 0 5px",
    },
  },
}));

const CardListItem = (props: CardListItemProps) => {
  const classes = useStyles();

  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  // Memo
  const items = useMemo(() => {
    const list = props.items;

    return isSeeAll ? list : list.slice(0, props.maxContent || list.length);
  }, [isSeeAll, props.maxContent, props.items]);

  // Handler
  const handleSelect = (item: any) => () => {
    props.onSelect?.(item);
  };

  const handleToggle = () => {
    setIsSeeAll(!isSeeAll);
  };

  return (
    <>
      {items.map((item) => (
        <Card
          key={"card-" + item.id}
          className={`${classes.card}${
            props.selected === item.id ? " active" : ""
          }`}
          onClick={handleSelect(item)}
        >
          <CardActionArea>
            <CardContent className={props.isIcon ? "--icon" : ""}>
              <div>
                {item.image && (
                  <img
                    src={
                      props.selected === item.id && !!item.image_active
                        ? item.image_active
                        : item.image
                    }
                  />
                )}
              </div>
              <div>{item.name}</div>
              <div>
                {!props.hideRightIcon &&
                  (!props.rightIcon ? (
                    <MuiButton>
                      <EastRoundedIcon />
                    </MuiButton>
                  ) : (
                    props.rightIcon
                  ))}
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}

      {!!props.maxContent && props.items.length > props.maxContent && (
        <div
          aria-hidden="true"
          className={classes.see_all}
          onClick={handleToggle}
        >
          <label>{isSeeAll ? "แสดงน้อยลง" : props.maxPlaceholder || "-"}</label>
          <KeyboardArrowDownRoundedIcon />
        </div>
      )}
    </>
  );
};

export default React.memo(CardListItem);
