import React, { useMemo, useState, useEffect, useCallback } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import {
  Label,
  Header,
  Icon,
  Image,
  Message,
  MessageContent,
  MessageHeader,
  Container,
  HeaderSubheader,
  HeaderContent,
  Button,
} from "semantic-ui-react";

import PulltoRefresh from "../appointment/PulltoRefresh";
import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";
// medication

// Interface
import { Event, State } from "../MobInsuranceInterface";
import HeaderBar from "bplus-lib/medication/HeaderBar";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
  gray: "#797878",
};

const ICONS = {
  cancel: "/images/insurance/icon_insurance_cancel.png",
  consent: "/images/insurance/icon_insurance_consent.png",
  close: "/images/insurance/icon_close.png",
  success: "/images/register/success-check.png",
};

// Styles
const COLORS = {
  btn_gradient: "linear-gradient(90deg, #0147A3 0%, #0B87CD 100%)",
  bg_gradient: "linear-gradient(147.46deg, #0669EB 0%, #00357A 71.53%)",
  bg_opacity: "rgba(255, 255, 255, 0.12)",
};


const params = new URLSearchParams(window.location.search);

// Types
type InsuranceConsentProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
};

const useStyles = makeStyles((theme) => ({
  mainScreen: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    background: "white",
    position: "relative",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    gap: "20px",
    justifyContent: "center",
    margin: "10px 0",
  },
  centerBottom: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginBottom: "30px",
    flex: 1,
  },
  textHeader: {
    fontSize: "24px",
    fontWeight: "bold",
    whiteSpace: "pre-line",
    lineHeight: "29px",
    color: COLOR.font_black,
  },
  textNormal: {
    fontSize: "16px",
    whiteSpace: "pre-line",
    color: COLOR.gray,
  },
  textUnderline: {
    fontSize: "16px",
    whiteSpace: "pre-line",
    textDecorationLine: "underline",
    color: COLOR.BlueBDMS,
    marginTop: "30px",
  },
  bplusButtom: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
    "& > div": {
      minWidth: "70%",
      width: "100%",
      display: "flex",
      minHeight: "45px",
      justifyContent: "center",
      alignItems: "center",
      background: COLOR.BlueBDMS,
      color: "white",
      borderRadius: "8px",
      fontWize: "16px",
      "& .disable": {
        opacity: "0.4",
      },
      "& .backward": {
        background: "transparent",
        color: "var(--primary-theme-color)",
        border: "1px solid var(--primary-theme-color)",
      },
    },
    "& .ui.button": {
      minWidth: " 70%",
      display: "flex",
      minHeight: "45px",
      justifyContent: "center",
      alignItems: "center",
      background: COLOR.BlueBDMS,
      color: "white",
      borderRadius: "8px",
      fontWize: "16px",
      fontWeight: "500",
    },
  },
}));

type ICLocationType = {

}

const InsuranceConsent = (props: InsuranceConsentProps) => {
  const classes = useStyles();
  
  const [openDialogCancel, setOpenDialogCancel] = useState<boolean>(false);
  const [openDialogSuccess, setOpenDialogSuccess] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation<ICLocationType>();

  useEffect(() => {
    const statue = params.get("isVerified")
    if (statue === "true") {
      setOpenDialogSuccess(true);
    }
  }, []);

  const handleOpenPdf = () => {
    history.push("/insurance-pdf/?app=MobInsurance")
  };

  const handleCloseConsent = () => {
    history.push("/claim-list/?app=MobInsurance")
  };

  const handleContinue = () => {
    history.push("/claim-detail/?app=MobInsurance")
  };

  return (
    <div className={classes.mainScreen}>
      <HeaderBar
        setTitle="ยินยอมให้ข้อมูลเพิ่มเติม"
        whiteTheme={true}
        titleStyle={{ fontWeight: "bold", fontSize: "16px" }}
        // config
        hiddenLeft={true}
        iconRight={
          <Image src={ICONS.close} style={{ height: "25px", filter: "none" }} />
        }
        // function
        rightIconClick={()=> setOpenDialogCancel(true)}
      />
      <div className={classes.mainContent}>
        <Image
          src={ICONS.consent}
          style={{ height: "150px", width: "150px", marginTop: "30px" }}
        />
        <div className={classes.textHeader}>
          {"บริษัทประกันขอเอกสารเพิ่ม\nเติมในการตรวจสอบสิทธิ์"}
        </div>
        <div className={classes.textNormal}>
          {"รายละเอียดเอกสารที่ประกันต้องการเพิ่ม\nในการสอบความคุ้มครอง"}
        </div>
        <div className={classes.textUnderline} onClick={() => handleOpenPdf()}>{"ดูรายละเอียด"}</div>
      </div>

      <div className={classes.centerBottom}>
        <div className={classes.bplusButtom}>
          <Button
            loading={false}
            // disabled={handleCheckFile()}
            onClick={() => {}}
          >
            {"ยินยอม"}
          </Button>
        </div>
      </div>

      <DialogConfirm
        open={openDialogSuccess}
        title={"ยินยอมให้ข้อมูลเพิ่มเติมสำเร็จ"}
        approveText="เสร็จสิ้น"
        subTitle="ทางโรงพยาบาลจะดำเนินการส่งเอกสาร เพิ่มเติมให้กับบริษัทประกัน"
        img={ICONS.success}
        imgSize="55px"
        // config
        hideDeny={true}
        onApprove={handleContinue}
      />

      <DialogConfirm
        open={openDialogCancel}
        title={"ยืนยันการยกเลิก"}
        approveText="ยืนยัน"
        denyText="ยกเลิก"
        img={ICONS.cancel}
        imgSize="150px"
        denyStyle={{ border: "0px" }}
        // config
        closeIcon={true}
        onApprove={handleCloseConsent}
        onDeny={()=> setOpenDialogCancel(false)}
      >
        <>
          <div className={classes.textNormal} style={{ margin: "10px 0" }}>
            {"คุณต้องการยกเลิกการส่งเอกสารเพิ่มเติมใช่หรือไม่"}
          </div>
          <div className={classes.textNormal}>
            {
              "หากคุณยืนยันการยกเลิก ประกันจะไม่สามารถ ทำการตรวจสอบสิทธิ์ความคุ้มครองได้"
            }
          </div>
        </>
      </DialogConfirm>
    </div>
  );
};

export default React.memo(InsuranceConsent);
