import React, {
  CSSProperties,
  useMemo,
  useEffect,
  useState,
  useRef,
  SyntheticEvent,
  MutableRefObject,
} from "react";
import { IntlProvider, FormattedMessage, useIntl } from "react-intl";
import {
  Dimmer,
  Dropdown,
  Icon,
  Form,
  Loader,
  Input,
  Checkbox,
  TextArea,
  Button,
  Divider,
  Radio,
  InputProps,
} from "semantic-ui-react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";

import ReactTable from "react-table-6";
import moment from "moment";

// Common
import {
  DateTextBox,
  TimeFreeTextBox24,
  ModConfirm,
  ErrorMessage,
} from "../../../react-lib/apps/common";

// Interface
import { State, Event } from "../../ManageInterface";
import {
  convertToADDate,
  formatDate,
  calculateDelivery,
  BACKEND_TIME_FORMAT,
} from "../../MobClinicInterface";

// UI
import SnackMessage from "../../clinic/SnackMessage";

// Style
import "../../clinic/DMWeight.scss";

// Constant
export const DATE_FORMAT = "DD/MM/YYYY";
export const BE_FORMAT_DATE = "YYYY-MM-DD";

const COLOR_CODE = {
  blue: "var(--theme-icon-color-dm)",
  gray: "#4F4F4F",
  light_grey: "#A3A3A3",
};

const styles = {
  noPadding: {
    padding: "0px",
  } as CSSProperties,
  headerMonitor: {
    color: "var(--text-dark-blue)",
    textAlign: "center",
  } as CSSProperties,
  divDetail: {
    display: "flex",
    justifyContent: "center",
    padding: "30px 20px",
  } as CSSProperties,
  flexCenter: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  } as CSSProperties,
  tabBaby: {
    minWidth: "130px",
    height: "30px",
    backgroundColor: "#F2F2F2",
    marginRight: "10px",
    borderRadius: "8px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer",
  } as CSSProperties,
};

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    "& input": {
      width: "100% !important",
    },
  },
  header: {
    fontSize: "18px",
    color: COLOR_CODE.blue,
    fontWeight: "bold",
    marginBottom: "10px",
  },
  textarea: {
    border: "1px solid #E0E0E0",
    width: "100%",
    fontSize: "18px",
    padding: "15px",
    color: "#A3A3A3",
    outlineColor: "#85b7d9",
    borderRadius: "8px",
  },
  checkbox_blue: {
    "& label": {
      color: `${COLOR_CODE.blue} !important`,
    },
  },
}));

export const bloodOptions = [
  { key: "a", text: "A", value: "a" },
  { key: "b", text: "B", value: "b" },
  { key: "o", text: "O", value: "o" },
  { key: "ab", text: "AB", value: "ab" },
];

export const rhOptions = [
  { key: "positive", text: "Rh positive", value: "positive" },
  { key: "negative", text: "Rh negative", value: "negative" },
];

export const apgarScore = {
  heart_rate: { name: "Heart rate" },
  respiration: { name: "Respiration" },
  muscle_tone: { name: "Muscle tone" },
  color: { name: "Color" },
  reflex: { name: "Reflex" },
  total: { name: "Total" },
};

type WebMomObstreticRecordProps = {
  onEvent: (e: Event) => any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedPatient: any;
} & Pick<
  State,
  | "obstetricHistoryList"
  | "activeIndexBaby"
  | "activeIndexObstetricHistory"
  | "obstetricChildList"
  | "openModConfirmCreate"
>;

const WebMomObstreticRecordInitial: WebMomObstreticRecordProps = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedPatient: {},
  obstetricHistoryList: [],
  activeIndexBaby: null,
  activeIndexObstetricHistory: 0,
  obstetricChildList: [],
  openModConfirmCreate: null,
};

const HeaderCustom: React.FunctionComponent<any> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.header} style={props.style || {}}>
      {props.children}
    </div>
  );
};

const TextBlue: React.FunctionComponent<any> = (props) => {
  return (
    <label
      style={{
        fontSize: `${props.size || 14}px`,
        color: COLOR_CODE.blue,
        paddingRight: "8px",
        ...(props.style || {}),
      }}
    >
      {props.children}
    </label>
  );
};

const TextGray: React.FunctionComponent<any> = (props) => {
  return (
    <label
      style={{
        fontSize: `${props.size || 14}px`,
        color: COLOR_CODE.gray,
        paddingRight: "8px",
        ...(props.style || {}),
      }}
    >
      {props.children}
    </label>
  );
};

export const formateDate = (date?: string) => {
  return date
    ? moment(date)
        .add(+543, "years")
        .format(DATE_FORMAT)
    : "";
};

const WebMomObstreticRecord: React.FC<WebMomObstreticRecordProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles();

  const [openInput, setOpenInput] = useState<{
    key: string;
    accessor: string;
    value: any;
  }>({ key: "", accessor: "", value: "" });

  const inputRef = useRef() as MutableRefObject<Input>;

  useEffect(() => {
    props.onEvent({ message: "GetObstetricHistoryList", params: {} });
  }, []);

  const columnsRecord = useMemo(
    () => [
      {
        Header: "Apgar Score",
        minWidth: 80,
        accessor: "name",
        Cell: (row: any = {}) => {
          return <div>{row?.original?.[1]?.["name"]}</div>;
        },
      },
      {
        Header: "1 minute",
        minWidth: 75,
        accessor: "1_min",
        Cell: (row: any = {}) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {row?.original?.[0] === openInput.key &&
              openInput.accessor === "1_min" ? (
                <Input
                  ref={inputRef}
                  value={openInput.value || ""}
                  onChange={handleChange}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                  onBlur={handleOnBlur}
                />
              ) : (
                row?.original?.[1]?.["1_min"]
              )}
            </div>
          );
        },
      },
      {
        Header: "5 minute",
        minWidth: 75,
        accessor: "5_min",
        Cell: (row: any = {}) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {row?.original?.[0] === openInput.key &&
              openInput.accessor === "5_min" ? (
                <Input
                  ref={inputRef}
                  value={openInput.value || ""}
                  onChange={handleChange}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                  onBlur={handleOnBlur}
                />
              ) : (
                row?.original?.[1]?.["5_min"]
              )}
            </div>
          );
        },
      },
      {
        Header: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0 25px",
            }}
          >
            <FormattedMessage id="bplusClinicKey19" />
            <div
              style={{ fontSize: "10px", color: "white", fontWeight: "normal" }}
            >
              <FormattedMessage id="bplusClinicKey4" />
            </div>
          </div>
        ),
        minWidth: 75,
        accessor: "10_min",
        Cell: (row: any = {}) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {row?.original?.[0] === openInput.key &&
              openInput.accessor === "10_min" ? (
                <Input
                  ref={inputRef}
                  value={openInput.value || ""}
                  onChange={handleChange}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      handleOnBlur();
                    }
                  }}
                  onBlur={handleOnBlur}
                />
              ) : (
                row?.original?.[1]?.["10_min"]
              )}
            </div>
          );
        },
      },
    ],
    [openInput]
  );

  const index = useMemo(() => {
    return props.activeIndexBaby || 0;
  }, [props.activeIndexBaby]);

  const obstreticRecordDetail = useMemo(() => {
    return (
      props.obstetricHistoryList?.[props.activeIndexObstetricHistory || 0]
        ?.data || {}
    );
  }, [props.obstetricHistoryList, props.activeIndexObstetricHistory]);

  const obstreticChildDetail = useMemo(() => {
    return obstreticRecordDetail?.babies?.[index]?.data;
  }, [
    obstreticRecordDetail,
    index,
    props.activeIndexBaby,
    props.activeIndexObstetricHistory,
  ]);

  const prefixBaby = useMemo(() => {
    return `babies[${index}].data`;
  }, [index]);

  const handleChange = (e: any, v: InputProps) => {
    setOpenInput({ ...openInput, value: v.value });
  };

  const handleOnBlur = () => {
    handleValueChange({
      key: `${prefixBaby}.newborn.apgar_score.${openInput.key}.${openInput.accessor}`,
      value: openInput.value,
    });
    setOpenInput({ key: "", accessor: "", value: "" });
  };

  const handleGetTheadThProps = (state: any, rowInfo: any) => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleGetTdProps = (state: any, rowInfo: any, cellInfo: any) => {
    return {
      onDoubleClick: () => {
        if (cellInfo.id === "name") {
          return;
        }
        setTimeout(() => {
          inputRef.current.focus();
        });
        setOpenInput({
          key: rowInfo.original?.[0],
          accessor: cellInfo.id,
          value: rowInfo.original?.[1]?.[cellInfo.id],
        });
      },
    };
  };

  const handleValueChange = (params: {
    key: string;
    value: any;
    label?: any;
  }) => {
    const { key, value, label = "" } = params;
    props.onEvent({
      message: "HandleSetObstreticHistoryList",
      params: { key, value, label },
    });
  };

  // Utils
  const ordinalNumber = (number: number) => {
    const splitNumber = number.toString().split("");
    const last = splitNumber[splitNumber.length - 1];
    const ordinal = ["st", "nd", "rd"];
    const string = ordinal[+last];
    const time = `${number + 1}${string || "th"} obstretic history`;
    return time;
  };

  const handleConfirmCreate = () => {
    handleSaveChild({ confirm_create: true });
    handleClose();
  };

  const handleClose = () => {
    props.onEvent({
      message: "HandleSetOpenModConfirmCreate",
      params: { value: null },
    });
  };

  const handleSaveChild = (params = {}) => {
    props.onEvent({
      message: "SaveObstreticRecord",
      params: {
        data: obstreticRecordDetail?.babies?.[index],
        measure_date: `${obstreticChildDetail?.labour?.dob} ${moment().format(
          BACKEND_TIME_FORMAT
        )}`,
        type: "child",
        ...params,
      },
    });
  };

  console.log(props, obstreticChildDetail?.labour?.dob);

  return (
    <div className="main-layout web-mom">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <ModConfirm
        openModal={!!props.openModConfirmCreate}
        onApprove={handleConfirmCreate}
        onDeny={handleClose}
        onCloseWithDimmeClick={handleClose}
        titleColor={"red"}
        content={
          <ErrorMessage
            error={props.openModConfirmCreate}
            style={{ marginBottom: "-20px" }}
          />
        }
        approveButtonText="Yes"
        denyButtonText="No"
      />

      <div className="box-header">
        <div className="div-header" style={{ marginTop: 0 }}>
          <img
            alt="ultrasound.icon"
            height="36px"
            style={{ color: "var(--theme-icon-color-dm)" }}
            src="/images/mom/ultrasound.png"
          />
          <span>
            <FormattedMessage id="bplusClinicKey351" />
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            right: "30px",
            top: "30%",
          }}
        >
          <Button
            color="green"
            onClick={() =>
              props.onEvent({
                message: "HandleAddObstreticHistoryList",
                params: {},
              })
            }
          >
            <FormattedMessage id="bplusClinicKey333" />
          </Button>
          <Button
            color="orange"
            onClick={() => {
              props.onEvent({
                message: "HandleCreateObstreticRecordPDF",
                params: {},
              });
            }}
          >
            <FormattedMessage id="bplusClinicKey135" />
          </Button>
          <Button
            color="blue"
            onClick={() =>
              props.onEvent({
                message: "SaveObstreticRecord",
                params: {
                  data:
                    props.obstetricHistoryList?.[
                      props.activeIndexObstetricHistory || 0
                    ] || {},
                  measure_date: `${
                    obstreticRecordDetail?.start_pregnancy_date
                  } ${moment().format(BACKEND_TIME_FORMAT)}`,
                  type: "mom",
                },
              })
            }
            disabled={!obstreticRecordDetail?.start_pregnancy_date}
          >
            <FormattedMessage id="bplusClinicKey431" />
          </Button>
        </div>
      </div>
      <div className="sub-layout">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Dropdown
            value={props.activeIndexObstetricHistory}
            selection={true}
            options={(props.obstetricHistoryList || []).map(
              (_: any, index: number) => ({
                key: index + 1,
                value: index,
                text: ordinalNumber(index),
              })
            )}
            onChange={(e, v: any) =>
              props.onEvent({
                message: "HandleSetactiveIndexObstetricHistory",
                params: { index: v.value },
              })
            }
          />
        </div>
        <HeaderCustom>
          <FormattedMessage id="bplusClinicKey349" />
        </HeaderCustom>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlue>
            <FormattedMessage id="bplusClinicKey280" />
          </TextBlue>
          <DateTextBox
            maxDate={formatDate(moment().format(DATE_FORMAT))}
            value={formateDate(obstreticRecordDetail?.lmp_date)}
            onChange={(date: string) =>
              handleValueChange({
                key: "lmp_date",
                value: date
                  ? moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    )
                  : "",
              })
            }
          />
          <TextBlue style={{ marginLeft: "8px" }}>
            <FormattedMessage id="bplusClinicKey175" />
          </TextBlue>
          {/* {obstreticRecordDetail?.by_lmp_date && ( */}
          <DateTextBox
            // maxDate={formatDate(moment().format(DATE_FORMAT))}
            style={{ marginRight: "15px" }}
            value={formateDate(obstreticRecordDetail.edc_date)}
            onChange={(date: string) =>
              handleValueChange({
                key: "edc_date",
                value: moment(convertToADDate(date), DATE_FORMAT).format(
                  BE_FORMAT_DATE
                ),
              })
            }
            // readOnly={true}
          />
          {/* )} */}
          {/* {obstreticRecordDetail?.by_ultrasound && (
            <Input
              style={{ marginRight: "10px" }}
              value={obstreticRecordDetail?.edc_date || ""}
              onChange={(e, v) => {
                handleValueChange("edc_date", v.value);
              }}
            />
          )} */}
          <Checkbox
            label="by Date"
            checked={obstreticRecordDetail?.by_lmp_date || false}
            onChange={(e, v) => {
              handleValueChange({ key: "by_lmp_date", value: v.checked });
            }}
            readOnly={
              obstreticRecordDetail?.by_lmp_date &&
              !obstreticRecordDetail?.by_ultrasound
            }
          />
          <Checkbox
            label="by Ultrasound"
            checked={obstreticRecordDetail?.by_ultrasound || false}
            onChange={(e, v) => {
              handleValueChange({ key: "by_ultrasound", value: v.checked });
            }}
            readOnly={
              !obstreticRecordDetail?.by_lmp_date &&
              obstreticRecordDetail?.by_ultrasound
            }
          />
          <TextBlue style={{ marginLeft: "8px", width: "155px" }}>
            <FormattedMessage id="bplusClinicKey209" />
          </TextBlue>
          {/* <DateTextBox
            maxDate={formatDate(moment().format(DATE_FORMAT))}
            value={formateDate(obstreticRecordDetail?.start_pregnancy_date)}
            onChange={(date: string) =>
              handleValueChange(
                "start_pregnancy_date",
                moment(convertToADDate(date), DATE_FORMAT).format(
                  BE_FORMAT_DATE
                )
              )
            }
          /> */}
          <Input
            type="number"
            style={{ marginRight: "10px", width: "15%" }}
            value={obstreticRecordDetail?.ga_weeks}
            onChange={(e, v) => {
              handleValueChange({
                key: "ga_weeks",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
        </div>

        <div style={styles.flexCenter}>
          <TextBlue>
            <FormattedMessage id="bplusClinicKey371" />
          </TextBlue>
          <Input
            style={{ marginRight: "10px" }}
            type="number"
            value={obstreticRecordDetail?.past_delivery_last ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "past_delivery_last",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
          <TextBlue>
            <FormattedMessage id="bplusClinicKey590" />
          </TextBlue>
        </div>

        <div style={styles.flexCenter}>
          <TextGray>
            <FormattedMessage id="bplusClinicKey217" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.gravida ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "gravida",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
          <TextGray>
            <FormattedMessage id="bplusClinicKey206" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.full_term ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "full_term",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
            readOnly={!(obstreticRecordDetail?.gravida > 0)}
          />
          <TextGray>
            <FormattedMessage id="bplusClinicKey395" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.premature ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "premature",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
            readOnly={!(obstreticRecordDetail?.gravida > 0)}
          />
          <TextGray>
            <FormattedMessage id="bplusClinicKey37" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.abortion ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "abortion",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
            readOnly={!(obstreticRecordDetail?.gravida > 0)}
          />
          <TextGray>
            <FormattedMessage id="bplusClinicKey296" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.living ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "living",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
            readOnly={!(obstreticRecordDetail?.gravida > 0)}
          />
          <TextGray>
            <FormattedMessage id="bplusClinicKey470" />
          </TextGray>
          <Input
            fluid
            type="number"
            style={{ width: "72px", marginRight: "10px" }}
            value={obstreticRecordDetail?.stillbirth ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "stillbirth",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
            readOnly={!(obstreticRecordDetail?.gravida > 0)}
          />
        </div>

        <Form
          style={{
            display: "grid",
            marginTop: "15px",
            gridTemplateColumns: "40% auto",
          }}
        >
          <TextBlue>
            <FormattedMessage id="bplusClinicKey400" />
          </TextBlue>
          <div style={{ display: "flex" }}>
            <Checkbox
              label="Yes, specify;"
              style={{ marginRight: "15px" }}
              checked={obstreticRecordDetail?.pdp?.yes_specify || false}
              onChange={(e, v) => {
                handleValueChange({ key: "pdp.yes_specify", value: v.checked });
              }}
            />
            <TextArea
              style={{ width: "50%" }}
              value={obstreticRecordDetail?.pdp?.yes_specify_description || ""}
              onChange={(e, v) => {
                handleValueChange({
                  key: "pdp.yes_specify_description",
                  value: v.value,
                });
              }}
              readOnly={!obstreticRecordDetail?.pdp?.yes_specify}
            ></TextArea>
          </div>
        </Form>

        <div style={{ ...styles.flexCenter, alignItems: "baseline" }}>
          <TextBlue>
            <FormattedMessage id="bplusClinicKey315" />
          </TextBlue>
          <Checkbox
            label="No"
            style={{ marginRight: "15px" }}
            checked={obstreticRecordDetail?.mdp?.no || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "mdp.no",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Yes"
            style={{ marginRight: "10px" }}
            checked={obstreticRecordDetail?.mdp?.yes || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "mdp.yes",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <div style={{ display: "grid" }}>
            {(obstreticRecordDetail?.mdp?.description || [""])?.map(
              (value: any, index: number) => (
                <div style={styles.flexCenter}>
                  <Input
                    value={value || ""}
                    onChange={(e, v) => {
                      handleValueChange({
                        key: `mdp.description[${index}]`,
                        value: v.value,
                      });
                    }}
                    readOnly={!obstreticRecordDetail?.mdp?.yes}
                  />
                  <Button
                    circular
                    icon={
                      (obstreticRecordDetail?.mdp?.description || [""])
                        ?.length -
                        1 ===
                      index
                        ? "plus"
                        : "minus"
                    }
                    style={{
                      backgroundColor:
                        (obstreticRecordDetail?.mdp?.description || [""])
                          ?.length -
                          1 ===
                        index
                          ? COLOR_CODE.blue
                          : "red",
                      color: "white",
                      transform: "scale(0.6)",
                      marginLeft: "20px",
                    }}
                    onClick={() => {
                      props.onEvent({
                        message: "HandleClickButtonMedicationPregnancyOther",
                        params: {
                          type:
                            (obstreticRecordDetail?.mdp?.description || [""])
                              ?.length -
                              1 ===
                            index
                              ? "add"
                              : "delete",
                          index,
                        },
                      });
                    }}
                    disabled={!obstreticRecordDetail?.mdp?.yes}
                  />
                </div>
              )
            )}
          </div>
        </div>

        <div style={styles.flexCenter}>
          <TextBlue>
            <FormattedMessage id="bplusClinicKey281" />
          </TextBlue>
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey92" />
          </TextGray>
          <Dropdown
            value={obstreticRecordDetail?.lab_result?.blood_group}
            selection
            options={bloodOptions}
            fluid
            style={{ width: "10%", marginRight: "10px" }}
            onChange={(e: any, v) => {
              handleValueChange({
                key: "lab_result.blood_group",
                value: v.value,
              });
            }}
          />
          <TextGray style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "profile.rh" })}
          </TextGray>
          <Dropdown
            value={obstreticRecordDetail?.lab_result?.rh}
            selection
            options={rhOptions}
            fluid
            style={{ width: "10%", marginRight: "10px" }}
            onChange={(e: any, v) => {
              handleValueChange({ key: "lab_result.rh", value: v.value });
            }}
          />
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey238" />
          </TextGray>
          <Input
            fluid
            style={{ width: "72px", marginRight: "10px" }}
            type="number"
            value={obstreticRecordDetail?.lab_result?.hct ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hct",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
          <TextGray>%</TextGray>
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey310" />
          </TextGray>
          <Input
            fluid
            style={{ width: "72px", marginRight: "10px" }}
            type="number"
            value={obstreticRecordDetail?.lab_result?.mcv ?? ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.mcv",
                value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
          <TextGray style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "bplusClinicKey565" })}
          </TextGray>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "340px 15% 10% 8% auto",
            marginTop: "15px",
            alignItems: "center",
          }}
        >
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey521" />
          </TextGray>
          <Checkbox
            label="Non reactive"
            checked={
              obstreticRecordDetail?.lab_result?.vdrl?.non_reactive || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.vdrl.non_reactive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Reactive"
            checked={obstreticRecordDetail?.lab_result?.vdrl?.reactive || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.vdrl.reactive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Other"
            checked={obstreticRecordDetail?.lab_result?.vdrl?.other || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.vdrl.other",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Input
            fluid
            style={{ width: "72px" }}
            value={
              obstreticRecordDetail?.lab_result?.vdrl?.other_description || ""
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.vdrl.other_description",
                value: v.value,
              });
            }}
            readOnly={!obstreticRecordDetail?.lab_result?.vdrl?.other}
          />
        </div>

        <div
          style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
        >
          <TextGray style={{ fontWeight: "bold", width: "340px" }}>
            <FormattedMessage id="bplusClinicKey66" />
          </TextGray>
          <TextGray>
            <FormattedMessage id="bplusClinicKey118" />
          </TextGray>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "340px 15% 10% 8% auto",
            marginTop: "15px",
            alignItems: "center",
            rowGap: "15px",
          }}
        >
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey243" />
          </TextGray>
          <Checkbox
            label="Negative"
            checked={
              obstreticRecordDetail?.lab_result?.hbsag?.negative || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsag.negative",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Positive"
            checked={
              obstreticRecordDetail?.lab_result?.hbsag?.positive || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsag.positive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Other"
            checked={obstreticRecordDetail?.lab_result?.hbsag?.other || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsag.other",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Input
            fluid
            style={{ width: "72px" }}
            value={
              obstreticRecordDetail?.lab_result?.hbsag?.other_description || ""
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsag.other_description",
                value: v.value,
              });
            }}
            readOnly={!obstreticRecordDetail?.lab_result?.hbsag?.other}
          />
          {/*  */}
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey242" />
          </TextGray>
          <Checkbox
            label="Negative"
            checked={
              obstreticRecordDetail?.lab_result?.hbsab?.negative || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsab.negative",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Positive"
            checked={
              obstreticRecordDetail?.lab_result?.hbsab?.positive || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsab.positive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Other"
            checked={obstreticRecordDetail?.lab_result?.hbsab?.other || false}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsab.other",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Input
            fluid
            style={{ width: "72px" }}
            value={
              obstreticRecordDetail?.lab_result?.hbsab?.other_description || ""
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hbsab.other_description",
                value: v.value,
              });
            }}
            readOnly={!obstreticRecordDetail?.lab_result?.hbsab?.other}
          />
          {/*  */}
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey430" />
          </TextGray>
          <Checkbox
            label="Non reactive"
            checked={
              obstreticRecordDetail?.lab_result?.rubella_igg?.non_reactive ||
              false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.rubella_igg.non_reactive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Reactive"
            checked={
              obstreticRecordDetail?.lab_result?.rubella_igg?.reactive || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.rubella_igg.reactive",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Other"
            checked={
              obstreticRecordDetail?.lab_result?.rubella_igg?.other || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.rubella_igg.other",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Input
            fluid
            style={{ width: "72px" }}
            value={
              obstreticRecordDetail?.lab_result?.rubella_igg
                ?.other_description || ""
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.rubella_igg.other_description",
                value: v.value,
              });
            }}
            readOnly={!obstreticRecordDetail?.lab_result?.rubella_igg?.other}
          />
          {/*  */}
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey219" />
          </TextGray>
          <Checkbox
            label="No growth"
            checked={
              obstreticRecordDetail?.lab_result?.group_b_streptococcus
                ?.no_growth || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.group_b_streptococcus.no_growth",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Growth"
            checked={
              obstreticRecordDetail?.lab_result?.group_b_streptococcus
                ?.growth || false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.group_b_streptococcus.growth",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Checkbox
            label="Other"
            checked={
              obstreticRecordDetail?.lab_result?.group_b_streptococcus?.other ||
              false
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.group_b_streptococcus.other",
                value: v.checked,
                label: v.label,
              });
            }}
          />
          <Input
            fluid
            style={{ width: "72px" }}
            value={
              obstreticRecordDetail?.lab_result?.group_b_streptococcus
                ?.other_description || ""
            }
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.group_b_streptococcus.other_description",
                value: v.value,
              });
            }}
            readOnly={
              !obstreticRecordDetail?.lab_result?.group_b_streptococcus?.other
            }
          />
        </div>

        <div style={styles.flexCenter}>
          <TextGray style={{ fontWeight: "bold" }}>
            <FormattedMessage id="bplusClinicKey239" />
          </TextGray>
          <Input
            value={obstreticRecordDetail?.lab_result?.hemoglobin_typing || ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "lab_result.hemoglobin_typing",
                value: v.value,
              });
            }}
          />
        </div>

        <div style={styles.flexCenter}>
          <TextBlue>
            <FormattedMessage id="bplusClinicKey343" />
          </TextBlue>
          <Input
            style={{ marginRight: "15px" }}
            value={obstreticRecordDetail?.obstetric_history?.nurse_name || ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "obstetric_history.nurse_name",
                value: v.value,
              });
            }}
          />
          <TextBlue>
            <FormattedMessage id="bplusClinicKey466" />
          </TextBlue>
          <Input
            style={{ marginRight: "15px" }}
            value={obstreticRecordDetail?.obstetric_history?.staff_id || ""}
            onChange={(e, v) => {
              handleValueChange({
                key: "obstetric_history.staff_id",
                value: v.value,
              });
            }}
          />
          <TextBlue>
            <FormattedMessage id="bplusClinicKey152" />
          </TextBlue>
          <DateTextBox
            maxDate={formatDate(moment().format(DATE_FORMAT))}
            style={{ marginRight: "15px" }}
            value={formateDate(obstreticRecordDetail?.obstetric_history?.date)}
            onChange={(date: string) =>
              handleValueChange({
                key: "obstetric_history.date",
                value: moment(convertToADDate(date), DATE_FORMAT).format(
                  BE_FORMAT_DATE
                ),
              })
            }
          />
          <TextBlue>
            <FormattedMessage id="bplusClinicKey487" />
          </TextBlue>
          <TimeFreeTextBox24
            style={{
              display: "flex",
              alignItems: "left",
            }}
            className="time-custom-react-picker"
            autoFocus={false}
            checkTimeOverflow={true}
            value={obstreticRecordDetail?.obstetric_history?.time || ""}
            onChange={(time: string) => {
              handleValueChange({ key: "obstetric_history.time", value: time });
            }}
          />
        </div>

        <Divider
          style={{
            borderBottom: `2px solid ${COLOR_CODE.blue}`,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            margin: "40px 0 30px",
          }}
        />

        <HeaderCustom>
          <FormattedMessage id="bplusClinicKey282" />
        </HeaderCustom>
        <Button
          icon
          labelPosition="left"
          style={{
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#9DE6EB",
          }}
          onClick={() =>
            props.onEvent({
              message: "HandleAddBabyObstreticRecord",
              params: {},
            })
          }
        >
          <Icon
            name="plus"
            style={{
              backgroundColor: COLOR_CODE.blue,
              borderRadius: "50rem",
              transform: "scale(0.6)",
              color: "#9DE6EB",
              left: "12px",
            }}
          />
          <FormattedMessage id="bplusClinicKey40" />
        </Button>

        <div className="ObstetricChild-list">
          {obstreticRecordDetail?.babies?.map((_: any, index: number) => (
            <div
              key={"baby-" + index}
              style={{
                ...styles.tabBaby,
                boxShadow:
                  index === props.activeIndexBaby
                    ? "4px 0px 4px rgba(0, 0, 0, 0.25)"
                    : "",
              }}
              onClick={() =>
                props.onEvent({
                  message: "HandleSetActiveIndexBaby",
                  params: { index },
                })
              }
            >
              <FormattedMessage id="bplusClinicKey75" /> {index + 1}
            </div>
          ))}
        </div>

        {typeof props.activeIndexBaby === "number" && (
          <>
            <div style={styles.flexCenter}>
              <TextGray style={{ fontWeight: "bold" }}>HN:</TextGray>
              <Input
                value={obstreticChildDetail?.labour?.hn || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.hn`,
                    value: v.value,
                  });
                  handleValueChange({
                    key: `${prefixBaby}.prepare_child_id`,
                    value: "CHANGE",
                  });
                }}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter" && obstreticChildDetail?.labour?.hn) {
                    props.onEvent({
                      message: "ObstreticGetPatientByHn",
                      params: {
                        hn: obstreticChildDetail?.labour?.hn,
                      },
                    });
                  }
                }}
                style={{ width: "15%" }}
              />
              <TextGray style={{ fontWeight: "bold", marginLeft: "25px" }}>
                <FormattedMessage id="profile.first_name" />
              </TextGray>
              <Input
                value={obstreticChildDetail?.labour?.first_name || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.first_name`,
                    value: v.value,
                  });
                }}
                style={{ width: "15%" }}
              />
              <TextGray style={{ fontWeight: "bold", marginLeft: "25px" }}>
                <FormattedMessage id="profile.last_name" />
              </TextGray>
              <Input
                value={obstreticChildDetail?.labour?.last_name || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.last_name`,
                    value: v.value,
                  });
                }}
                style={{ width: "15%" }}
              />
              <TextGray style={{ fontWeight: "bold", marginLeft: "25px" }}>
                <FormattedMessage id="bplusClinicKey142" />
              </TextGray>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(obstreticChildDetail?.labour?.dob || "")}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.dob`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <Button
                style={{
                  backgroundColor: COLOR_CODE.blue,
                  color: "white",
                  marginLeft: "25px",
                }}
                onClick={() => handleSaveChild()}
                disabled={
                  !(
                    (obstreticChildDetail?.prepare_child_id ||
                      obstreticRecordDetail?.babies?.[index]?.id) &&
                    props.obstetricHistoryList?.[
                      props.activeIndexObstetricHistory || 0
                    ].id &&
                    obstreticChildDetail?.prepare_child_id !== "CHANGE"
                  )
                }
              >
                <FormattedMessage id="bplusClinicKey431" />
              </Button>
            </div>

            <div style={styles.flexCenter}>
              <Checkbox
                className={classes.checkbox_blue}
                label="Not in labour"
                style={{ marginRight: "25px" }}
                checked={obstreticChildDetail?.labour?.not_in_labour || false}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.not_in_labour`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                className={classes.checkbox_blue}
                label="Spontaneous"
                style={{ marginRight: "25px" }}
                checked={obstreticChildDetail?.labour?.spontaneous || false}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.spontaneous`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                className={classes.checkbox_blue}
                label="Induced, method"
                style={{ marginRight: "25px" }}
                checked={obstreticChildDetail?.labour?.induced_method || false}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.induced_method`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Input
                value={
                  obstreticChildDetail?.labour?.induced_method_description || ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.induced_method_description`,
                    value: v.value,
                  });
                }}
                readOnly={!obstreticChildDetail?.labour?.induced_method}
              />
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey318" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(
                  obstreticChildDetail?.labour?.membranes_ruptured?.date || ""
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.membranes_ruptured.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue style={{ marginLeft: "15px" }}>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={
                  obstreticChildDetail?.labour?.membranes_ruptured?.time || ""
                }
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.membranes_ruptured.time`,
                    value: time,
                  });
                }}
              />
              <Checkbox
                label="Spontaneous"
                style={{ marginLeft: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.membranes_ruptured
                    ?.spontaneous || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.membranes_ruptured.spontaneous`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Artificial"
                style={{ marginLeft: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.membranes_ruptured
                    ?.artificial || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.membranes_ruptured.artificial`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey63" />
              </TextBlue>
              <Checkbox
                label="Clear"
                style={{ marginLeft: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.amniotic_fluid_appearance
                    ?.clear || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.amniotic_fluid_appearance.clear`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Thin meconium"
                style={{ marginLeft: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.amniotic_fluid_appearance
                    ?.thin_meconium || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.amniotic_fluid_appearance.thin_meconium`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Think meconium"
                style={{ marginLeft: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.amniotic_fluid_appearance
                    ?.think_meconium || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.amniotic_fluid_appearance.think_meconium`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
            </div>

            <div
              style={{
                display: "grid",
                alignItems: "center",
                gridTemplateColumns: "12% 4% 21% 5% 14% 16% 7% 3% 7% 6% 5%",
                rowGap: "15px",
                marginTop: "15px",
              }}
            >
              <TextBlue>
                <FormattedMessage id="bplusClinicKey355" />
              </TextBlue>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(
                  obstreticChildDetail?.labour?.labour_onset?.date || ""
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.labour_onset.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.labour?.labour_onset?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.labour_onset.time`,
                    value: time,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey170" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.labour_onset?.duration_hour ??
                  ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.labour_onset.duration_hour`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey570" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.labour_onset?.duration_min ?? ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.labour_onset.duration_min`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey574" />
              </TextBlue>

              <Checkbox
                label="N/A"
                checked={
                  obstreticChildDetail?.labour?.labour_onset?.na ?? false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.labour_onset.na`,
                    value: v.checked,
                  });
                }}
              />
              {/*  */}
              <TextBlue>
                <FormattedMessage id="bplusClinicKey207" />
              </TextBlue>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(
                  obstreticChildDetail?.labour?.full_dilatation?.date || ""
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.full_dilatation.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={
                  obstreticChildDetail?.labour?.full_dilatation?.time || ""
                }
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.full_dilatation.time`,
                    value: time,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey172" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.full_dilatation
                    ?.duration_hour ?? ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.full_dilatation.duration_hour`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey570" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.full_dilatation?.duration_min ??
                  ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.full_dilatation.duration_min`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey574" />
              </TextBlue>

              <Checkbox
                label="N/A"
                checked={
                  obstreticChildDetail?.labour?.full_dilatation?.na || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.full_dilatation.na`,
                    value: v.checked,
                  });
                }}
              />
              {/*  */}
              <TextBlue>
                <FormattedMessage id="bplusClinicKey86" />
              </TextBlue>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(
                  obstreticChildDetail?.labour?.child_birth?.date || ""
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.child_birth.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.labour?.child_birth?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.child_birth.time`,
                    value: time,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey173" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.child_birth?.duration_hour ?? ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.child_birth.duration_hour`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey570" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.child_birth?.duration_min ?? ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.child_birth.duration_min`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey574" />
              </TextBlue>
              <div></div>
              {/*  */}
              <TextBlue>
                <FormattedMessage id="bplusClinicKey87" />
              </TextBlue>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                value={formateDate(
                  obstreticChildDetail?.labour?.placenta_birth?.date || ""
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.placenta_birth.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.labour?.placenta_birth?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.placenta_birth.time`,
                    value: time,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey171" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.placenta_birth?.duration_hour ??
                  ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.placenta_birth.duration_hour`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey570" />
              </TextBlue>
              <Input
                type="number"
                value={
                  obstreticChildDetail?.labour?.placenta_birth?.duration_min ??
                  ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.placenta_birth.duration_min`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextBlue style={{ paddingLeft: "10px" }}>
                <FormattedMessage id="bplusClinicKey574" />
              </TextBlue>
            </div>

            <div style={styles.flexCenter}>
              <TextBlue style={{ width: "200px" }}>
                <FormattedMessage id="bplusClinicKey321" />
              </TextBlue>
              <Checkbox
                label="Normal Labour"
                style={{ marginRight: "15px" }}
                checked={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.normal_labour || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.normal_labour`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Forcep Extraction, indication"
                style={{ marginRight: "10px" }}
                checked={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.forcep_extraction || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.forcep_extraction`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Input
                value={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.forcep_extraction_description || ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.forcep_extraction_description`,
                    value: v.value,
                  });
                }}
                readOnly={
                  !obstreticChildDetail?.labour?.delivery_method
                    ?.forcep_extraction
                }
              />
            </div>
            <div style={styles.flexCenter}>
              <TextBlue style={{ width: "200px" }}></TextBlue>
              <Checkbox
                label="Vacuum Extraction, indication"
                style={{ marginRight: "10px", width: "215px" }}
                checked={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.vacuum_extraction || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.vacuum_extraction`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Input
                value={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.vacuum_extraction_description || ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.vacuum_extraction_description`,
                    value: v.value,
                  });
                }}
                readOnly={
                  !obstreticChildDetail?.labour?.delivery_method
                    ?.vacuum_extraction
                }
              />
            </div>
            <div style={styles.flexCenter}>
              <TextBlue style={{ width: "200px" }}></TextBlue>
              <Checkbox
                label="Cesarean section due to"
                style={{ marginRight: "10px", width: "215px" }}
                checked={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.cesarean_section_due_to || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.cesarean_section_due_to`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Input
                value={
                  obstreticChildDetail?.labour?.delivery_method
                    ?.cesarean_section_due_to_description || ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.delivery_method.cesarean_section_due_to_description`,
                    value: v.value,
                  });
                }}
                readOnly={
                  !obstreticChildDetail?.labour?.delivery_method
                    ?.cesarean_section_due_to
                }
              />
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey343" />
              </TextBlue>
              <Input
                style={{ marginRight: "15px" }}
                value={obstreticChildDetail?.labour?.nurse_name || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.nurse_name`,
                    value: v.value,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey466" />
              </TextBlue>
              <Input
                style={{ marginRight: "15px" }}
                value={obstreticChildDetail?.labour?.staff_id || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.staff_id`,
                    value: v.value,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                style={{ marginRight: "15px" }}
                value={formateDate(obstreticChildDetail?.labour?.date)}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.labour.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.labour?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.labour.time`,
                    value: time,
                  });
                }}
              />
            </div>

            <div
              style={{
                position: "relative",
                borderRadius: "5px",
                border: `1px solid ${COLOR_CODE.light_grey}`,
                padding: "15px",
                marginTop: "30px",
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  padding: "8px",
                  position: "absolute",
                  top: "-20px",
                }}
              >
                <HeaderCustom style={{ margin: 0 }}>
                  <FormattedMessage id="bplusClinicKey518" />
                </HeaderCustom>
              </div>

              <div style={styles.flexCenter}>
                <TextBlue style={{ width: "200px" }}>
                  <FormattedMessage id="bplusClinicKey397" />
                </TextBlue>
                <Checkbox
                  label="Cephalic"
                  style={{ width: "150px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.presentation
                      ?.cephalic || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.presentation.cephalic`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Breech"
                  style={{ width: "150px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.presentation
                      ?.breech || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.presentation.breech`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Other"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.presentation
                      ?.other || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.presentation.other`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery?.presentation
                      ?.other_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.presentation.other_description`,
                      value: v.value,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.presentation?.other
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue style={{ width: "200px" }}>
                  <FormattedMessage id="bplusClinicKey184" />
                </TextBlue>
                <Checkbox
                  label="No"
                  style={{ width: "75px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.episiotomy?.no ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.episiotomy.no`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Yes"
                  style={{ width: "75px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.episiotomy?.yes ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.episiotomy.yes`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Radio
                  label="Right mediolateral"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.episiotomy
                      ?.right_mediolateral || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.episiotomy.right_mediolateral`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.episiotomy?.yes
                  }
                />
                <Radio
                  label="Left mediolateral"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.episiotomy
                      ?.left_mediolateral || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.episiotomy.left_mediolateral`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.episiotomy?.yes
                  }
                />
                <Radio
                  label="Median"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.episiotomy
                      ?.median || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.episiotomy.median`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.episiotomy?.yes
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue style={{ width: "200px" }}>
                  <FormattedMessage id="bplusClinicKey285" />
                </TextBlue>
                <Checkbox
                  label="No"
                  style={{ width: "75px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration?.no ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.no`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Yes"
                  style={{ width: "75px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration?.yes ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.yes`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Radio
                  label="First"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration?.first ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.first`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.laceration?.yes
                  }
                />
                <Radio
                  label="Second"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration
                      ?.second || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.second`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.laceration?.yes
                  }
                />
                <Radio
                  label="Third"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration?.third ||
                    false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.third`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.laceration?.yes
                  }
                />
                <Radio
                  label="Fourth degree tear"
                  style={{ marginRight: "15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.laceration
                      ?.fourth_degree_tear || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.laceration.fourth_degree_tear`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.laceration?.yes
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey399" />
                </TextBlue>
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery
                      ?.problem_during_labour || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.problem_during_labour`,
                      value: v.value,
                    });
                  }}
                />
              </div>

              <div style={{ ...styles.flexCenter, alignItems: "baseline" }}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey314" />
                </TextBlue>
                <div style={{ display: "grid" }}>
                  {(
                    obstreticChildDetail?.vaginal_delivery
                      ?.medication_during_labour || [""]
                  )?.map((value: any, idx: number) => (
                    <div style={styles.flexCenter}>
                      <Input
                        value={value || ""}
                        onChange={(e, v) => {
                          handleValueChange({
                            key: `${prefixBaby}.vaginal_delivery.medication_during_labour[${idx}]`,
                            value: v.value,
                          });
                        }}
                      />
                      <Button
                        circular
                        icon={
                          (
                            obstreticChildDetail?.vaginal_delivery
                              ?.medication_during_labour || [""]
                          )?.length -
                            1 ===
                          idx
                            ? "plus"
                            : "minus"
                        }
                        style={{
                          backgroundColor:
                            (
                              obstreticChildDetail?.vaginal_delivery
                                ?.medication_during_labour || [""]
                            )?.length -
                              1 ===
                            idx
                              ? COLOR_CODE.blue
                              : "red",
                          color: "white",
                          transform: "scale(0.6)",
                          marginLeft: "20px",
                        }}
                        onClick={() => {
                          props.onEvent({
                            message:
                              "HandleClickButtonMedicationDuringLabourOther",
                            params: {
                              type:
                                (
                                  obstreticChildDetail?.vaginal_delivery
                                    ?.medication_during_labour || [""]
                                )?.length -
                                  1 ===
                                idx
                                  ? "add"
                                  : "delete",
                              index: idx,
                            },
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div style={styles.flexCenter}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey377" />
                </TextBlue>
                <TextGray style={{ marginLeft: "20px" }}>
                  <FormattedMessage id="weight.weight" />
                </TextGray>
                <Input
                  type="number"
                  value={
                    obstreticChildDetail?.vaginal_delivery?.placenta_membranes
                      ?.weight ?? ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.placenta_membranes.weight`,
                      value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                    });
                  }}
                />
                <TextGray style={{ margin: "0 20px 0 10px" }}>
                  <FormattedMessage id="bplusClinicKey568" />
                </TextGray>
                <Checkbox
                  label="Spontaneous delivery"
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.placenta_membranes
                      ?.spontaneous_delivery || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.placenta_membranes.spontaneous_delivery`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Manual removal"
                  style={{ marginLeft: "25px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.placenta_membranes
                      ?.manual_removal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.placenta_membranes.manual_removal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey218" />
                </TextBlue>
                <Checkbox
                  label="Normal"
                  style={{ marginLeft: "25px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.normal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.gross_appearance.normal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Abnormal"
                  style={{ margin: "0 10px 0 25px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.abnormal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.gross_appearance.abnormal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.abnormal_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.gross_appearance.abnormal_description`,
                      value: v.value,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.abnormal
                  }
                />
                <Radio
                  label="Complete"
                  style={{ margin: "0 20px 0 10px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.complete || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.gross_appearance.complete`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Radio
                  label="Incomplete"
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.gross_appearance
                      ?.incomplete || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.gross_appearance.incomplete`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey132" />
                </TextBlue>
                <Input
                  type="number"
                  value={
                    obstreticChildDetail?.vaginal_delivery?.cord_length ?? ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_length`,
                      value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                    });
                  }}
                />
                <TextGray style={{ margin: "0 20px 0 10px" }}>
                  <FormattedMessage id="bplusClinicKey559" />
                </TextGray>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey126" />
                </TextBlue>
                <Checkbox
                  label="Normal"
                  style={{ margin: "0 25px 0 15px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.cord_insertion
                      ?.normal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_insertion.normal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Abnormal"
                  style={{ marginRight: "10px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.cord_insertion
                      ?.abnormal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_insertion.abnormal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery?.cord_insertion
                      ?.abnormal_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_insertion.abnormal_description`,
                      value: v.value,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.cord_insertion
                      ?.abnormal
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue style={{ width: "100px" }}>
                  <FormattedMessage id="bplusClinicKey128" />
                </TextBlue>
                <Checkbox
                  label="Normal"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.cord_vessels
                      ?.normal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_vessels.normal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Abnormal"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.cord_vessels
                      ?.abnormal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_vessels.abnormal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery?.cord_vessels
                      ?.abnormal_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.cord_vessels.abnormal_description`,
                      value: v.value,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.cord_vessels
                      ?.abnormal
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue style={{ width: "100px" }}>
                  <FormattedMessage id="bplusClinicKey366" />
                </TextBlue>
                <Checkbox
                  label="Normal"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.normal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.normal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Abnormal"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.abnormal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.abnormal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Radio
                  label="Oxytocin"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.oxytocin || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.oxytocin`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.abnormal
                  }
                />
                <Radio
                  label="Methergin"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.methergin || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.methergin`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.abnormal
                  }
                />
                <Radio
                  label="Duratocin"
                  style={{ width: "100px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.duratocin || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.duratocin`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.abnormal
                  }
                />
                <Radio
                  label="Other"
                  style={{ marginRight: "10px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.other || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.other`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.abnormal
                  }
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.other_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.oxytocin_drug.other_description`,
                      value: v.value,
                      label: v.label,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery?.oxytocin_drug
                      ?.other
                  }
                />
              </div>

              <div style={styles.flexCenter}>
                <TextBlue>
                  <FormattedMessage id="bplusClinicKey388" />
                </TextBlue>
                <Checkbox
                  label="Normal"
                  style={{ marginRight: "25px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery
                      ?.post_partum_condition?.normal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.post_partum_condition.normal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Checkbox
                  label="Abnormal"
                  style={{ marginRight: "10px" }}
                  checked={
                    obstreticChildDetail?.vaginal_delivery
                      ?.post_partum_condition?.abnormal || false
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.post_partum_condition.abnormal`,
                      value: v.checked,
                      label: v.label,
                    });
                  }}
                />
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery
                      ?.post_partum_condition?.abnormal_description || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.post_partum_condition.abnormal_description`,
                      value: v.value,
                    });
                  }}
                  readOnly={
                    !obstreticChildDetail?.vaginal_delivery
                      ?.post_partum_condition?.abnormal
                  }
                />
                <TextBlue style={{ marginLeft: "25px" }}>
                  <FormattedMessage id="bplusClinicKey187" />
                </TextBlue>
                <Input
                  value={
                    obstreticChildDetail?.vaginal_delivery
                      ?.post_partum_condition?.estimated_blood_loss || ""
                  }
                  onChange={(e, v) => {
                    handleValueChange({
                      key: `${prefixBaby}.vaginal_delivery.post_partum_condition.estimated_blood_loss`,
                      value: v.value,
                    });
                  }}
                />
                <TextGray style={{ marginLeft: "10px" }}>
                  <FormattedMessage id="bplusClinicKey576" />
                </TextGray>
              </div>
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey348" />
              </TextBlue>
              <Input
                value={
                  obstreticChildDetail?.vaginal_delivery?.obstertric_name || ""
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.vaginal_delivery.obstertric_name`,
                    value: v.value,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey295" />
              </TextBlue>
              <Input
                value={obstreticChildDetail?.vaginal_delivery?.license || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.vaginal_delivery.license`,
                    value: v.value,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey152" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                style={{ marginRight: "15px" }}
                value={formateDate(
                  obstreticChildDetail?.vaginal_delivery?.date
                )}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.vaginal_delivery.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.vaginal_delivery?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.vaginal_delivery.time`,
                    value: time,
                  });
                }}
              />
            </div>

            <Divider
              style={{
                borderBottom: `2px solid ${COLOR_CODE.light_grey}`,
                margin: "35px 0 25px",
              }}
            />

            <HeaderCustom>
              <FormattedMessage id="bplusClinicKey334" />
            </HeaderCustom>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey155" />
              </TextBlue>
              <DateTextBox
                maxDate={formatDate(moment().format(DATE_FORMAT))}
                style={{ marginRight: "15px" }}
                value={formateDate(obstreticChildDetail?.newborn?.date)}
                onChange={(date: string) =>
                  handleValueChange({
                    key: `${prefixBaby}.newborn.date`,
                    value: moment(convertToADDate(date), DATE_FORMAT).format(
                      BE_FORMAT_DATE
                    ),
                  })
                }
              />
              <TextBlue>
                <FormattedMessage id="bplusClinicKey487" />
              </TextBlue>
              <TimeFreeTextBox24
                style={{
                  display: "flex",
                  alignItems: "left",
                }}
                className="time-custom-react-picker"
                autoFocus={false}
                checkTimeOverflow={true}
                value={obstreticChildDetail?.newborn?.time || ""}
                onChange={(time: string) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.time`,
                    value: time,
                  });
                }}
              />
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey450" />
              </TextBlue>
              <Dropdown
                selection
                options={[
                  { key: "M", text: "Male", value: "M" },
                  { key: "F", text: "Female", value: "F" },
                ]}
                fluid
                style={{ width: "10%", marginRight: "10px" }}
                value={obstreticChildDetail?.newborn?.sex || ""}
                onChange={(e: any, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.sex`,
                    value: v.value,
                  });
                }}
              />
              <TextBlue>
                <FormattedMessage id="weight.weight" />
              </TextBlue>
              <Input
                type="number"
                fluid
                style={{ width: "15%" }}
                value={obstreticChildDetail?.newborn?.weight ?? ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.weight`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextGray style={{ margin: "0 20px 0 10px" }}>
                <FormattedMessage id="bplusClinicKey568" />
              </TextGray>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey292" />
              </TextBlue>
              <Input
                type="number"
                value={obstreticChildDetail?.newborn?.length ?? ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.length`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextGray style={{ margin: "0 20px 0 10px" }}>
                <FormattedMessage id="bplusClinicKey559" />
              </TextGray>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey95" />
              </TextBlue>
              <Input
                type="number"
                value={obstreticChildDetail?.newborn?.body_temperature ?? ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.body_temperature`,
                    value: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
              <TextGray style={{ margin: "0 20px 0 10px" }}>
                <FormattedMessage id="bplusClinicKey98" />
              </TextGray>
            </div>

            <div style={styles.flexCenter}>
              <TextBlue>
                <FormattedMessage id="bplusClinicKey469" />
              </TextBlue>
              <Checkbox
                label="Alive"
                style={{ width: "130px" }}
                checked={obstreticChildDetail?.newborn?.status?.alive || false}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.status.alive`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Stillbirth"
                style={{ width: "130px" }}
                checked={
                  obstreticChildDetail?.newborn?.status?.stillbirth || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.status.stillbirth`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Fresh"
                style={{ width: "130px" }}
                checked={obstreticChildDetail?.newborn?.status?.fresh || false}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.status.fresh`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Marcerated"
                style={{ width: "130px" }}
                checked={
                  obstreticChildDetail?.newborn?.status?.marcerated || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.status.marcerated`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
            </div>

            <div style={{ ...styles.flexCenter, alignItems: "baseline" }}>
              <TextBlue style={{ width: "170px" }}>
                <FormattedMessage id="bplusClinicKey317" />
              </TextBlue>
              <Checkbox
                label="Terramycin eye ointment"
                style={{ width: "200px" }}
                checked={
                  obstreticChildDetail?.newborn?.medication_to_baby
                    ?.terramycin_eye_ointment || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.medication_to_baby.terramycin_eye_ointment`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Vit K1"
                style={{ width: "200px" }}
                checked={
                  obstreticChildDetail?.newborn?.medication_to_baby?.vit_k1 ||
                  false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.medication_to_baby.vit_k1`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Other"
                style={{ marginRight: "10px" }}
                checked={
                  obstreticChildDetail?.newborn?.medication_to_baby?.other ||
                  false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.medication_to_baby.other`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              {/* <Input /> */}
              <div style={{ display: "grid" }}>
                {(
                  obstreticChildDetail?.newborn?.medication_to_baby
                    ?.other_description || [""]
                )?.map((value: any, idx: number) => (
                  <div style={styles.flexCenter}>
                    <Input
                      value={value || ""}
                      onChange={(e, v) => {
                        handleValueChange({
                          key: `${prefixBaby}.newborn.medication_to_baby.other_description[${idx}]`,
                          value: v.value,
                        });
                      }}
                      readOnly={
                        !obstreticChildDetail?.newborn?.medication_to_baby
                          ?.other
                      }
                    />
                    <Button
                      circular
                      icon={
                        (
                          obstreticChildDetail?.newborn?.medication_to_baby
                            ?.other_description || [""]
                        )?.length -
                          1 ===
                        idx
                          ? "plus"
                          : "minus"
                      }
                      style={{
                        backgroundColor:
                          (
                            obstreticChildDetail?.newborn?.medication_to_baby
                              ?.other_description || [""]
                          )?.length -
                            1 ===
                          idx
                            ? COLOR_CODE.blue
                            : "red",
                        color: "white",
                        transform: "scale(0.6)",
                        marginLeft: "20px",
                      }}
                      onClick={() => {
                        props.onEvent({
                          message: "HandleClickButtonMedicationToBabyOther",
                          params: {
                            type:
                              (
                                obstreticChildDetail?.newborn
                                  ?.medication_to_baby?.other_description || [
                                  "",
                                ]
                              )?.length -
                                1 ===
                              idx
                                ? "add"
                                : "delete",
                            index: idx,
                          },
                        });
                      }}
                      disabled={
                        !obstreticChildDetail?.newborn?.medication_to_baby
                          ?.other
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <div style={{ ...styles.flexCenter, alignItems: "baseline" }}>
              <TextBlue style={{ width: "170px" }}>
                <FormattedMessage id="bplusClinicKey130" />
              </TextBlue>
              <Checkbox
                label="Blood group"
                style={{ width: "200px" }}
                checked={
                  obstreticChildDetail?.newborn?.cord_blood?.blood_group ||
                  false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.cord_blood.blood_group`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              <Checkbox
                label="Other"
                style={{ marginRight: "10px" }}
                checked={
                  obstreticChildDetail?.newborn?.cord_blood?.other || false
                }
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.cord_blood.other`,
                    value: v.checked,
                    label: v.label,
                  });
                }}
              />
              {/* <Input /> */}
              <div style={{ display: "grid" }}>
                {(
                  obstreticChildDetail?.newborn?.cord_blood
                    ?.other_description || [""]
                )?.map((value: any, idx: number) => (
                  <div style={styles.flexCenter}>
                    <Input
                      value={value || ""}
                      onChange={(e, v) => {
                        handleValueChange({
                          key: `${prefixBaby}.newborn.cord_blood.other_description[${idx}]`,
                          value: v.value,
                        });
                      }}
                      readOnly={
                        !obstreticChildDetail?.newborn?.cord_blood?.other
                      }
                    />
                    <Button
                      circular
                      icon={
                        (
                          obstreticChildDetail?.newborn?.cord_blood
                            ?.other_description || [""]
                        )?.length -
                          1 ===
                        idx
                          ? "plus"
                          : "minus"
                      }
                      style={{
                        backgroundColor:
                          (
                            obstreticChildDetail?.newborn?.cord_blood
                              ?.other_description || [""]
                          )?.length -
                            1 ===
                          idx
                            ? COLOR_CODE.blue
                            : "red",
                        color: "white",
                        transform: "scale(0.6)",
                        marginLeft: "20px",
                      }}
                      onClick={() => {
                        props.onEvent({
                          message: "HandleClickButtonCordBloodOther",
                          params: {
                            type:
                              (
                                obstreticChildDetail?.newborn?.cord_blood
                                  ?.other_description || [""]
                              )?.length -
                                1 ===
                              idx
                                ? "add"
                                : "delete",
                            index: idx,
                          },
                        });
                      }}
                      disabled={
                        !obstreticChildDetail?.newborn?.cord_blood?.other
                      }
                    />
                  </div>
                ))}
              </div>
            </div>

            <div>
              <ReactTable
                className="weight-table-custom web-mom"
                data={Object.entries(
                  obstreticChildDetail?.newborn?.apgar_score || {}
                )}
                columns={columnsRecord}
                pageSize={6}
                style={{ marginTop: "20px" }}
                showPagination={false}
                resizable={false}
                getTheadThProps={handleGetTheadThProps}
                getTdProps={handleGetTdProps}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "15px",
              }}
            >
              <TextBlue style={{ paddingRight: "8px" }}>
                <FormattedMessage id="bplusClinicKey418" />
              </TextBlue>
              <TextArea
                className={classes.textarea}
                fluid
                rows={4}
                color="twitter"
                value={obstreticChildDetail?.newborn?.remark || ""}
                onChange={(e, v) => {
                  handleValueChange({
                    key: `${prefixBaby}.newborn.remark`,
                    value: v.value,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

WebMomObstreticRecord.defaultProps = WebMomObstreticRecordInitial;

export default React.memo(WebMomObstreticRecord);
