import React, { Component, CSSProperties } from "react";
import SetProp from "../react-lib/frameworks/SetProp";
import { withRouter, Route, Switch } from "react-router-dom";
import Cookies from "js-cookie";
import CONFIG from "../config/config";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
// Controller
import MobInsuranceController from "./MobInsuranceController";
// Interface
import * as MobINSI from "./MobInsuranceInterface";
// UI
import ClaimList from "./insurance/ClaimList";
import ClaimDetail from "./insurance/ClaimDetail";
import InsuranceConsent from "./insurance/InsuranceConsent";
import InsurancePDFDetail from "./insurance/InsurancePDFDetail";

import { IntlProvider } from "react-intl";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "../react-lib/localization/bplusClinic/th.json";
import enMessage from "../react-lib/localization/bplusClinic/en.json";

import { Grid, Header, Icon, Image, Container } from "semantic-ui-react";
import Avatar from "@mui/material/Avatar";

// CSS
import "../css/Default.css";
import { Padding } from "@mui/icons-material";

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  flexcenter: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "10px 0px",
  } as CSSProperties,
  card: {
    width: "100%",
    height: "120px",
    borderRadius: "10px",
    padding: "15px",
  } as CSSProperties,
  card_2: {
    borderRadius: "10px",
    borderColor: "#CCDAED",
    padding: "15px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: "#ECF4FF",
  } as CSSProperties,
  title_text_blue: {
    color: COLOR.BlueBDMS,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "29px",
    margin: "1rem 0px 10px",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16_noellipsis: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
};


class MobInsurance extends Component<any, MobINSI.State> {
  controller: MobInsuranceController;

  constructor(props: any) {
    super(props);
    this.state = { ...MobINSI.StateInitial };
    this.controller = new MobInsuranceController(
      () => {
        return this.state;
      },
      (state: MobINSI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    if (!this.state.loggedin) {
      this.controller.handleEvent({ message: "GetLoginInfo", params: {} });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    // Get apiToken and userId from mobNative
    const userProfile = window.mobile?.getUser?.();
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      console.log("did update check......");
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });
    }
  }

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");
      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      userId = user?.profile?.userId || "";
      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });
      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  handleGoBack = () => {
    this.props.history.push("/?app=MobFeed");
  };

  render = () => {

    return (
      <IntlProvider
        locale="th-TH"
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div className="default-background" style={{ background: "white" }}>
          <Switch>
            <Route
              path="/insurance-pdf"
              render={(props) => {
                return (
                  <InsurancePDFDetail
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                  />
                );
              }}
            />
            <Route
              path="/insurance-consent"
              render={(props) => {
                return (
                  <InsuranceConsent
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                  />
                );
              }}
            />
            <Route
              path="/claim-list"
              render={(props) => {
                return (
                  <ClaimList
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    history={this.props.history}
                    // data
                    claimListData={this.state.claimListData}
                    // topServiceList={this.state.topServiceList}
                    loadingStatus={this.state.loadingStatus}
                    // centerGroupList={this.state.centerGroupList}
                  />
                );
              }}
            />
            <Route
              path="/claim-detail"
              render={(props) => {
                return (
                  <ClaimDetail
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    selectedClaim={this.state.selectedClaim}
                    // topServiceList={this.state.topServiceList}
                    loadingStatus={this.state.loadingStatus}
                    // centerGroupList={this.state.centerGroupList}
                  />
                );
              }}
            />
            <Route
              path="/excess-coverage"
              render={(props) => {
                return (
                  <div>
                    {/* implement excess coverage */}
                  </div>
                );
              }}
            />
            <Route
              path="/"
              render={(props) => {
                return (
                  <div className="main-screen" style={{ background: "white", padding: "20px 16px"}}>
                    <div>
                      <Icon
                        name="chevron left"
                        style={{ fontSize: "1.25rem", marginLeft: "-0.25rem" }}
                        onClick={() => this.handleGoBack()}
                      />
                    </div>
                    <Container textAlign="center" style={{ marginTop: '2em' }}>
                      <Header as="h2" textAlign="center" style={styles.title_text_blue}>
                        ตรวจสอบสิทธิประกัน
                      </Header>
                      <p style={{ ...styles.text_DarkGray_14, marginBottom: "40px" }}>กรุณาเลือกประเภทการตรวจสอบ</p>
                      <div
                        style={styles.card_2}
                        onClick={() => this.props.history.push('/claim-list/?app=MobInsurance')}
                      >
                        <Grid style={{ margin: "0px 0px 10px 0px" }}>
                          <Grid.Row width={2} style={{ padding: "0px 0px 10px 0px" }}>
                            <Image
                              style={{ width: "48px", height: "48px" }}
                              size='small'
                              rounded src='images/insurance/menu1.png' />
                          </Grid.Row>
                          <Grid.Row
                            width={16} style={{ padding: "0px" }}
                          >
                            <Grid.Column textAlign="left"
                              width={15} style={{ padding: "0px" }} >
                              <div
                                style={{
                                  ...styles.text_bluebdms_16_noellipsis,
                                  margin: "0px",
                                  padding: "0px",
                                  width: "100%"
                                }}
                              >
                                พิจารณาความคุ้มครองก่อนการผ่าตัด
                              </div>
                            </Grid.Column>
                            <Grid.Column textAlign="right"
                              width={1} style={{ padding: "0px" }} >
                              <Image circular bordered src='images/insurance/arrow.svg' />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                      <div
                        style={styles.card_2}
                        onClick={() => alert("2")}
                      >
                        <Grid style={{ margin: "0px 0px 10px 0px" }}>
                          <Grid.Row width={2} style={{ padding: "0px 0px 10px 0px" }}>
                            <Image
                              style={{ width: "48px", height: "48px" }}
                              size='small'
                              rounded src='images/insurance/menu2.png' />
                          </Grid.Row>
                          <Grid.Row
                            width={16} style={{ padding: "0px" }}
                          >
                            <Grid.Column textAlign="left"
                              width={15} style={{ padding: "0px" }} >
                              <div
                                style={{
                                  ...styles.text_bluebdms_16_noellipsis,
                                  margin: "0px",
                                  padding: "0px",
                                  width: "100%"
                                }}
                              >
                                ประเมินส่วนเกินก่อนการผ่าตัด
                              </div>
                            </Grid.Column>
                            <Grid.Column textAlign="right"
                              width={1} style={{ padding: "0px" }} >
                              <Image circular bordered src='images/insurance/arrow.svg' />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </Container>
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobInsurance);
