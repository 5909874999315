import React, { useState, CSSProperties, ChangeEvent, useEffect } from "react";
// CSS
import {
  Card,
  Modal,
  Icon,
  Dimmer,
  Loader,
  Button,
  Form,
} from "semantic-ui-react";
import { Button as MuiButton, makeStyles } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import TextField from "@material-ui/core/TextField";

// Library
import { browserName, browserVersion } from "react-device-detect";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

// Interface
import { Event as MobEvent } from "../MobCouponInterface";

// UI
import { ModInfo, ErrorMessage } from "react-lib/apps/common";
import MobHeadApp from "../appointment/MobHeadApp";

import CONFIG from "../../config/config";

type AddCouponTeleConsultProps = {
  onEvent: (e: MobEvent) => any;
  history: any;
  patientDetail?: any;
  userId?: string | number;
  loadingSearchCoupon?: boolean;
  openModCreateNewPatient?: {
    open: boolean;
    error: any;
  };
} & WrappedComponentProps;

export const COLOR = {
  blue: "#18a0fb",
  medium_blue: "#0b4ea6",
  dark_blue: "#0147A3",
  opacity: "rgba(133,133,133,0.025)",
};

const useStyles = makeStyles((theme) => ({
  underline: {
    "&:after": {
      borderColor: COLOR.dark_blue,
    },
  },
  button: {
    width: "75%",
    margin: "15px 0",
    fontSize: "18px",
    color: "white",
    backgroundColor: COLOR.dark_blue,
    "&:hover": {
      backgroundColor: COLOR.dark_blue,
    },
    borderRadius: "10px",
    padding: "10px 0",
  },
}));

const styles = {
  label: {
    color: COLOR.dark_blue,
    fontSize: "20px",
    marginBottom: "4px",
  } as CSSProperties,
  error_message: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    marginBottom: "-10px",
    color: "#f44336",
  },
};

const AddCouponTeleConsult: React.FunctionComponent<
  AddCouponTeleConsultProps
> = (props) => {
  const classes = useStyles();

  const [couponId, setCouponId] = useState<string>("");
  const [inputError, setInputError] = useState<any>({
    couponId: false,
  });
  const [messageError, setMessageError] = useState<string>("");

  useEffect(() => {
    if (!props.userId) {
      return;
    }
    if (!Object.keys(props?.patientDetail || {})?.[0]) {
      props.onEvent({
        message: "handleGetPatientDetail",
        params: { userId: props.userId },
      });
    }
  }, [props.userId]);

  const handleButtonClick = () => {
    if (!couponId) {
      setInputError({ couponId: !!!couponId });
      return;
    } else {
      setInputError({ orderNo: false, couponId: false });
    }
    setMessageError("");

    if (
      CONFIG.DUBAI_TELE_CONSULT_CODE?.toString()?.toLowerCase() ===
      couponId.toLowerCase()
    ) {
      props.onEvent({
        message: "MakeAppointmentDubaiTeleConsult",
        params: {
          productCode: couponId.toLowerCase(),
          history: props.history,
        },
      });
    } else {
      setMessageError("กรุณาระบุ รหัสคูปอง ให้ถูกต้อง");
    }
  };

  const handleCloseModInfo = () => {
    props.onEvent({ message: "HandleCloseModCreateNewPatient", params: {} });
  };

  return (
    <>
      {/* <div style={{ width: "100%", height: "80px", backgroundColor: "#18a0fb", position: "sticky", zIndex: 99, top: 0 }}></div> */}

      <Dimmer active={props.loadingSearchCoupon} inverted>
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <MobHeadApp
        title={"E-coupon"}
        leftIconClick={() => {
          globalThis.location.href = "/?app=MobFeed";
        }}
      />

      <div className="my-coupon" style={{ padding: "30px 15px 25px" }}>
        <div>
          <Card
            className="card-frame"
            style={{ padding: "15px 15px 20px", borderRadius: "15px" }}
          >
            <label style={{ ...styles.label, marginTop: "15px" }}>
              <FormattedMessage id="e_coupon.coupon_id" />
            </label>
            <TextField
              label=""
              error={inputError.couponId || messageError}
              placeholder=""
              // className={classes.underline}
              inputProps={{
                style: {
                  fontSize: 21,
                  color: "rgba(0,0,0,.87)",
                  padding: "10px 5px",
                },
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCouponId(e.target.value)
              }
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  handleButtonClick();
                }
              }}
              value={couponId}
              {...(browserName.includes("Safari") && { focused: true })}
            />
            {messageError && (
              <div style={styles.error_message}>{messageError}</div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px 0px 15px",
              }}
            >
              <MuiButton
                className={classes.button}
                variant="contained"
                onClick={handleButtonClick}
              >
                <FormattedMessage id="e_coupon.make_appointment" />
              </MuiButton>
            </div>
          </Card>
        </div>

        <ModInfo
          open={props.openModCreateNewPatient?.open}
          className="mod-error-custom"
          titleColor={"red"}
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          <ErrorMessage error={props.openModCreateNewPatient?.error} />
        </ModInfo>
      </div>
    </>
  );
};

AddCouponTeleConsult.defaultProps = {
  onEvent: () => {},
  history: {},
  patientDetail: {},
  openModCreateNewPatient: {
    open: false,
    error: null,
  },
} as Omit<AddCouponTeleConsultProps, "intl">;

export default React.memo(injectIntl(AddCouponTeleConsult));
