import React from "react";
import { Icon } from "semantic-ui-react";

import { useLocation } from "react-router-dom";

// UI
import MainRegister from "./MainRegister";
import VerifyOTP from "./VerifyOTP";
import CreatePassword from "./CreatePassword";

// Type
import { registerInfo } from "bplus-lib/register/TypeModal";

// Styles
import "./RegisterCSS.scss";

//type

type StepRegisterProps = {
  onEvent: (e: any) => any;
  registerInfo?: registerInfo;
  history: any;
  refCode?: string;
  stepRegister:
    | "confirm-otp"
    | "create-password"
    | "forgot-password";
  setProp: any;
  loading?: boolean;
  uiMode?: "LOGIN" | "NEW_USER" | "MATCH" | "NOT_MATCH" | "FORGOT_PASS";
  successMessage?: Record<string, any>;
  loadingStatus?: Record<string, any>;
};

const StepRegister: React.FunctionComponent<StepRegisterProps> = (props) => {
  const location = useLocation();
  const stepRegisterArray = ["confirm-otp","create-password","forgot-password"]
  const handleSetRef = () => {
    props.setProp("refCode", "");
    props.setProp("otpCode", "");
  };

  const handleGoBack = () => {
    const params = Object.fromEntries(new URLSearchParams(location.search));

    handleClearError("message");

    if (["forgot-password"].includes(props.stepRegister)) {
      props.setProp("username", "");
      props.setProp("valuecid", "");
      props.setProp("password", "");
    }

    if (props.stepRegister === "confirm-otp") {
      if (props.refCode && !params.sms) {
        handleSetRef();
      } else {
        props.history.goBack();
      }
    } else if (["create-password"].includes(props.stepRegister)) {
      handleSetRef();
      props.history.goBack();
    } else {
      // props.history.push("/");
      props.history.push("/?app=MobRegister");
    }
  };

  const handleClearError = (filed: string) => {
    let filedAll: any = props.registerInfo?.error || {};

    if (filedAll[filed]) {
      filedAll[filed] = "";
    }

    props.onEvent({
      message: "HandleSetValueRegisterInfo",
      params: { key: "error", value: { ...filedAll } },
    });
  };

  return (
    <div className="main-screen" style={{ background: "white" , ...(!stepRegisterArray.includes(props.stepRegister)&&{padding: "20px 16px"}) }}>
      <div>
        <Icon
          name="chevron left"
          style={{ fontSize: "1.25rem", marginLeft: "-0.25rem" }}
          onClick={() => handleGoBack()}
        />
      </div>
      <StepContent {...props} />
    </div>
  );
};

const StepContent = (props: StepRegisterProps) => {
  if (props.stepRegister === "confirm-otp") {
    return (
      <VerifyOTP
        onEvent={props.onEvent}
        setProp={props.setProp}
        history={props.history}
        registerInfo={props.registerInfo}
        refCode={props.refCode || ""}
        loading={props.loading}
        uiMode={props.uiMode}
      />
    );
  } else if (props.stepRegister === "create-password") {
    return (
      <CreatePassword
        onEvent={props.onEvent}
        history={props.history}
        registerInfo={props.registerInfo}
        loading={props.loading}
        successMessage={props.successMessage}
        loadingStatus={props.loadingStatus}
      />
    );
  } else if (props.stepRegister === "forgot-password") {
    return (
      <MainRegister
        onEvent={props.onEvent}
        history={props.history}
        registerInfo={props.registerInfo}
        loading={props.loading}
        forgotPass={true}
      />
    );
  } else {
    return (
      <MainRegister
        onEvent={props.onEvent}
        history={props.history}
        registerInfo={props.registerInfo}
        loading={props.loading}
      />
    );
  }
};

export default React.memo(StepRegister);
