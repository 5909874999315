import { Component } from "react";
import { withRouter, Switch, Route } from "react-router-dom";
// CSS

// Library
import Cookies from "js-cookie";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import Fingerprint2 from "fingerprintjs2";
import {
  FormattedMessage,
  IntlProvider,
  // createIntlCache,
  // createIntl,
} from "react-intl";

// UI
import CardKYC from "./register/CardKYC";
import CardKYCStep from "./register/CardKYCStep";
import StepRegister from "./register/StepRegister";
import MainLogin from "./register/MainLogin";
import MainLobby from "./register/MainLobby";
import SelectHospitalBindHN from "./register/SelectHospitalBindHN";
import SelectInitialHospital from "./appointment/SelectInitialHospital";

// Frameworks
import SetProp from "react-lib/frameworks/SetProp";
import "./register/RegisterCSS.scss";

// IsHealth
import { createNotificationSubscription } from "../react-lib/apps/IsHealth/Common/push-notifications";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "react-lib/localization/bplusClinic/th.json";
import enMessage from "react-lib/localization/bplusClinic/en.json";

// Controller
import MobSmartRegisterController from "./MobSmartRegisterController";

// Interface
import * as MobSmartRegisterI from "./MobSmartRegisterInterface";

// UI
import CONFIG from "../config/config";

// Style
import "../css/MobAppointment.scss";
// CSS
import SelectHospital from "./appointment/SelectHospital";

// Constant
const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

class MobSmartRegister extends Component<any, MobSmartRegisterI.State> {
  controller: MobSmartRegisterController;

  constructor(props: any) {
    super(props);
    this.state = { ...MobSmartRegisterI.StateInitial };
    this.controller = new MobSmartRegisterController(
      () => {
        return this.state;
      },
      (state: MobSmartRegisterI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );

    // @ts-ignore
    globalThis.setState = this.setState.bind(this);

    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });

    // Get login info from Mobile App
    if (!this.state.loggedin) {
      // globalThis.mobile.getLoginInfo();
      this.controller.handleEvent({
        message: "GetLoginInfo",
        params: {},
      });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    if (prefersDarkScheme.matches) {
      document.documentElement.setAttribute("color-scheme", "light");
    } else {
      document.documentElement.setAttribute("color-scheme", "light");
    }

    if (CONFIG.COMPANY === "BDMS") {
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
    } else {
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
    }

    document.querySelector("body")?.classList.add("body-mob-appointment");

    var userProfile = "{}";
    if (globalThis.mobile?.getUser) {
      userProfile = globalThis.mobile?.getUser?.() || "{}";
    }
    const user = JSON.parse(userProfile || "{}");

    if (user?.device_type === "android") {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.apiToken !== this.state.apiToken &&
      prevState.userId !== this.state.userId &&
      this.state.userId &&
      this.state.apiToken
    ) {
      Cookies.set("apiToken", this.state.apiToken || "", { path: "/" });
      Cookies.set("userId", this.state.userId.toString() || "", { path: "/" });

      this.controller.handleEvent({
        message: "GetMyProfileDetail",
        params: {},
      });
    }

    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      const location = this.props.location;
      const MOB_APP = "MobSmartAppointment";

      // เพื่อทำการกลับไปหน้านัดหมายของฉัน
      if (location.search.includes(MOB_APP)) {
        globalThis.history.go();
      }
    }
  }

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    let apiToken;
    let permit;
    let userId;

    if (window.mobile?.getUser) {
      let userProfile = window.mobile.getUser();
      const user = JSON.parse(userProfile || "{}");

      console.log("get apiToken from ", user?.device_type, user);

      apiToken = user?.token || "";
      permit = user?.permit || "";
      userId = user?.profile?.userId || "";

      Cookies.set("apiToken", apiToken, { path: "/" });
      Cookies.set("permit", permit, { path: "/" });
      Cookies.set("userId", userId, { path: "/" });

      // this.getPatientDetail({ token: user.token })
      this.setWebsocket({ apiToken: apiToken });
      this.setState({ apiToken: apiToken, userId: userId, permit });
    } else {
      console.log("get apiToken from browser (cookies)");
      if (props) {
        apiToken = Cookies.get("apiToken");
        permit = Cookies.get("permit");
        userId = Cookies.get("userId");

        this.setState({ apiToken: apiToken, userId: userId, permit });
        console.log(" apiToken", apiToken);
        console.log(" userId", userId);
      }
    }
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    vcMessenger.connect(
      "MSG",
      {
        token: apiToken,
      },
      CONFIG.WS_HOST
    );
  };

  handleGotoFeed = async (data?: any) => {
    const params = new URLSearchParams(window.location.search);
    const actionType = params.get("actiontype");

    await this.controller.handleEvent({
      message: "HandlerPostRetrieveProfile",
      params: {
        card: "SELECT_INIT_HOSPITAL",
        btnAction: "",
        ...data,
      },
    });

    if (actionType === "verify-identity") {
      this.props.history.push(
        "/kyc/?app=MobRegister&actiontype=verify-identity"
      );
    } else {
      if (globalThis.MobNative?.dismissWebView) {
        globalThis.MobNative.dismissWebView();
      } else if (globalThis.iosNative?.dismissWebView) {
        globalThis.iosNative.dismissWebView();
      } else {
        this.controller.handleEvent({
          message: "HandleCanceleKYC",
          params: { history: this.props.history },
        });
      }
    }
  };

  render = () => {
    console.log(this);
    return (
      <IntlProvider
        locale={this.state.language?.includes("TH") ? "th" : "en"}
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div style={{ minHeight: "100vh" }}>
          <Switch>
            <Route
              exact
              path="/kyc/"
              render={(props) => {
                const params = new URLSearchParams(window.location.search);
                const actionType = params.get("actiontype");

                return (
                  <CardKYC
                    onEvent={this.controller.handleEvent}
                    // data
                    actionType={actionType}
                    myProfileDetail={this.state.myProfileDetail}
                    history={this.props.history}
                    // Element
                    description={<FormattedMessage id="bplusClinicKey1100" />}
                  />
                );
              }}
            ></Route>
            <Route
              exact
              path="/kyc-step/:type/"
              render={(props) => {
                return (
                  <CardKYCStep
                    onEvent={this.controller.handleEvent}
                    setProp={this.controller.setProp}
                    // data
                    history={this.props.history}
                    rotateType={this.state.rotateType}
                    type={props.match.params.type as any}
                    registerInfo={this.state.registerInfo}
                    profileInfo={this.state.profileInfo}
                    consentStatus={this.state.consentStatus}
                    // CommonInterface
                    loading={this.state.loading}
                    loadingStatus={this.state.loadingStatus}
                    errorMessage={this.state.errorMessage}
                    // options
                    dropDownOption={this.state.dropDownOption}
                  />
                );
              }}
            ></Route>
            <Route
              path="/register/:step?/"
              render={(props) => {
                return (
                  <StepRegister
                    onEvent={this.controller.handleEvent}
                    history={this.props.history}
                    registerInfo={this.state.registerInfo}
                    refCode={this.state.refCode || ""}
                    stepRegister={props.match.params.step as any}
                    setProp={this.controller.setProp}
                    uiMode={this.state.uiMode}
                    // CommonInterface
                    loading={this.state.loading}
                    successMessage={this.state.successMessage}
                    loadingStatus={this.state.loadingStatus}
                  />
                );
              }}
            />
            <Route
              path="/login/:type?/"
              render={(props) => {
                return (
                  <MainLogin
                    onEvent={this.controller.handleEvent}
                    history={this.props.history}
                    loading={this.state.loading}
                    stepType={props.match.params.type as any}
                    registerInfo={this.state.registerInfo}
                    setProp={this.controller.setProp}
                    lastLogin={this.state.lastLogin}
                  />
                );
              }}
            />
            <Route
              path="/select-hospital"
              render={(props) => {
                return (
                  <div>
                    <SelectInitialHospital
                      onEvent={this.controller.handleEvent}
                      hospitalList={this.state.hospitalList}
                      onClose={this.handleGotoFeed}
                      firstLoadingHospital={!this.state.loadingHospital}
                      history={this.props.history}
                      selectedHospital={this.state.selectedHospital}
                      buttonLoading={
                        this.state.loadingStatus?.["SELECT_INIT_HOSPITAL"]
                      }
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/register-hn"
              render={(props) => {
                return (
                  <div>
                    <SelectHospitalBindHN
                      onEvent={this.controller.handleEvent}
                      setProp={this.controller.setProp}
                      history={this.props.history}
                      organizationList={this.state.organizationList}
                      // CommonInterface
                      errorMessage={this.state.errorMessage}
                      loadingStatus={this.state.loadingStatus}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/"
              render={(props) => {
                return (
                  props.location.search.includes("MobRegister") && (
                    <MainLobby
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      devMode={this.state.devMode}
                      setProp={this.controller.setProp}
                      registerInfo={this.state.registerInfo}
                    />
                  )
                );
              }}
            />
          </Switch>
        </div>
      </IntlProvider>
    );
  };
}

export default withRouter(MobSmartRegister);
