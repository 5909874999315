import React, {
  CSSProperties,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from "react";
// CSS
import {
  Grid,
  Icon,
  Input,
  Button,
  Tab,
  Form,
  Dimmer,
  Loader,
  Dropdown,
  Radio,
} from "semantic-ui-react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { FormattedMessage, useIntl } from "react-intl";
import ReactTable from "react-table-6";

// Interface
import { Event, State } from "../../ManageInterface";

// Common
import { ModConfirm } from "react-lib/apps/common";

// UI
import SnackMessage from "../../clinic/SnackMessage";

type VaccineProps = {
  onEvent: (e: Event) => any;
  hideTabAgeRange?: boolean;
  patientType: "DM" | "CHILD";
} & Pick<
  State,
  | "vaccineUMDetail"
  | "vaccineUMList"
  | "vaccineSeriesChoice"
  | "orderItemVaccineUM"
  | "loadingStatus"
  | "ageRangeChoice"
  | "ageRangeDetail"
  | "ageRangeList"
  | "errorMessage"
  | "successMessage"
>;

type AgeRangeProps = {
  onEvent: (e: Event) => any;
  patientType: "VACCINE_CHILD" | "DEVELOPMENT";
} & Pick<
  State,
  | "loadingStatus"
  | "ageRangeChoice"
  | "ageRangeDetail"
  | "ageRangeList"
  | "errorMessage"
  | "successMessage"
>;
type CustomListProps = {
  // callback
  onToggle: (value: number, type: "left" | "right") => any;
  onSearch?: (value: any) => any;
  // data
  items: number[];
  checked: number[];
  type: "left" | "right";
  // config
  hideInputSearch?: boolean;
};

const styles = {
  TextAgeHeader: {
    fontWeight: "bold",
    marginTop: "8px",
  } as CSSProperties,

  ButtonAgeSave: {
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  ButtonAgeClear: {
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,

  InputDescription: {
    border: "#C4C4C4",
    width: "180px",
    padding: "0px",
  } as CSSProperties,
};

const useStyles = makeStyles(() => ({
  text_label: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    padding: "0 15px",
  },
  form_dropdown: {
    width: "auto",
    "& .field>.selection.dropdown": {
      minWidth: "10rem",
    },
  },
}));

const CustomList: React.FunctionComponent<CustomListProps> = (props) => {
  return (
    <div
      style={{
        border: "1px solid #C4C4C4",
        width: "240px",
        height: "240px",
        padding: "0px",
      }}
    >
      <List dense component="div" role="list" style={{ padding: "0px" }}>
        {!props.hideInputSearch && (
          <div style={{ paddingBottom: "5px" }}>
            <Input
              icon="search"
              type="text"
              placeholder="Search"
              style={{ width: "100%" }}
              onChange={(e, v) => props.onSearch?.(v.value)}
            />
          </div>
        )}
        <div
          style={{
            overflow: "scroll",
            height: props.hideInputSearch ? "240px" : "195px",
          }}
        >
          {props.items.map((item: any, index: number) => {
            const labelId = `transfer-list-item-${index}-label`;

            return (
              <ListItem
                key={"list-" + index}
                role="listitem"
                button
                onClick={props.onToggle(index, props.type)}
                style={{ padding: "0px" }}
              >
                <ListItemIcon style={{ marginRight: "-15px" }}>
                  <Checkbox
                    checked={props.checked.includes(index)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${item.item_code} ${item.type_of_vaccine}`}
                />
              </ListItem>
            );
          })}
        </div>
      </List>
    </div>
  );
};

/* ------------------------ Main ------------------------ */
export const Vaccine: React.FC<VaccineProps> = (props) => {
  return (
    <div className="main-layout web-dm-um">
      <Tab
        panes={[
          {
            menuItem: "Age range",
            render: () => (
              <Tab.Pane>
                <TabAgeRange {...props} patientType="VACCINE_CHILD" />
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Vaccine",
            render: () => (
              <Tab.Pane>
                <TabVaccine {...props} />
              </Tab.Pane>
            ),
          },
        ].filter(
          (item) => !(props.hideTabAgeRange && item.menuItem === "Age range")
        )}
      />
    </div>
  );
};

export const TabAgeRange: React.FC<AgeRangeProps> = (props) => {
  const intl = useIntl();
  const [openModClearInput, setOpenModClearInput] = useState<boolean>(false);
  const [openModDelete, setOpenModDelete] = useState<number | null>(null);

  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      const getChoiceAgeRange = props.onEvent({
        message: "GetChoiceAgeRange",
        params: {},
      });

      const getListAgeRange = props.onEvent({
        message: "GetListAgeRange",
        params: { patientType: props.patientType },
      });
      await Promise.all([getChoiceAgeRange, getListAgeRange]);
    };
    fetchData();
  }, []);

  const columnsAgeRangeData = useMemo(() => {
    return [
      {
        Header: "No.",
        accessor: "no",
        width: 40,
        style: {
          textAlign: "center",
        },
        Cell: (row: any = {}) => <div>{row.index}</div>,
      },
      {
        Header: "Age range",
        accessor: "name",
        style: {
          textAlign: "left",
          paddingLeft: "30px",
        },
      },
      {
        Header: "From",
        accessor: "from",
        style: {
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => (
          <div>{`${original.from_year} yr ${original.from_month} mth ${original.from_day} day`}</div>
        ),
      },
      {
        Header: "To",
        accessor: "to",
        style: {
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => (
          <div>{`${original.to_year ?? 0} yr ${original.to_month ?? 0} mth ${
            original.to_day ?? 0
          } day`}</div>
        ),
      },
      {
        Header: "Sequence",
        accessor: "display_seq",
        style: {
          textAlign: "center",
        },
      },
      {
        Header: "Edit",
        accessor: "_icon",
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onEvent({
                  message: "HandleSelectedAgeRange",
                  params: { data: original },
                });
              }}
            >
              <Icon name="edit outline" color="green" />
            </div>
          );
        },
      },
      {
        Header: "Delete",
        accessor: "_icon",
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModDelete(original?.id)}
            >
              <Icon name="minus circle" color="red" />
            </div>
          );
        },
      },
    ];
  }, []);

  // const ageRangeOptions = useMemo(() => {
  //   return CreateOptions(props.ageRangeChoice || []);
  // }, [props.ageRangeChoice]);

  const handleChange = (params: VaccineProps["ageRangeDetail"]) => {
    props.onEvent({
      message: "HandleChangeAgeRangeDetail",
      params,
    });
  };

  const handleGetTheadThProps = () => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleSave = () => {
    props.onEvent({
      message: "HandleSaveAgeRange",
      params: { patientType: props.patientType },
    });
  };

  const handleClearInput = () => {
    handleChange({
      ...(props.ageRangeDetail || {}),
      name: "",
      start: {},
      to: {},
      sequence: "",
    });
    setOpenModClearInput(false);
  };

  const handleDelete = () => {
    props.onEvent({
      message: "HandleDeleteAgeRange",
      params: { id: openModDelete, patientType: props.patientType },
    });
    setOpenModDelete(null);
  };

  const handleGetTrProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original &&
          rowInfo?.original?.id === props.ageRangeDetail?.id
            ? "rgb(214, 236, 243)"
            : "",
      },
    };
  };

  return (
    <div style={{ padding: "50px 30px 30px 30px" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={styles.TextAgeHeader}>
            <FormattedMessage id="bplusClinicKey61" />
          </div>
          <Input
            value={props.ageRangeDetail?.name || ""}
            style={{ border: "#C4C4C4", width: "25%", padding: "0px 10px" }}
            onChange={(e, v: any) => {
              handleChange({
                name: v.value,
              });
            }}
          />
          <div style={styles.TextAgeHeader}>
            <FormattedMessage id="bplusClinicKey449" />
          </div>
          <Input
            value={props.ageRangeDetail?.sequence || ""}
            type="number"
            style={{ border: "#C4C4C4", width: "140px", padding: "0px 10px" }}
            onChange={(e, v: any) => {
              handleChange({
                sequence: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
              });
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ padding: "0px 10px" }}>
            <Button
              style={styles.ButtonAgeClear}
              color="yellow"
              onClick={() => setOpenModClearInput(true)}
            >
              <FormattedMessage id="bplusClinicKey113" />
            </Button>
          </div>
          <div style={{ padding: "0px 10px" }}>
            <Button
              style={styles.ButtonAgeSave}
              color="blue"
              onClick={handleSave}
            >
              <FormattedMessage id="bplusClinicKey431" />
            </Button>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", marginTop: "15px" }}>
        <label className={classes.text_label}>From:</label>
        <Form className={classes.form_dropdown}>
          <Form.Group>
            {(["year", "month", "day"] as ["year", "month", "day"]).map(
              (item, index) => (
                <Form.Field
                  key={"form-start" + index}
                  value={props.ageRangeDetail?.start?.[item] || ""}
                  control={Dropdown}
                  selection
                  options={props.ageRangeDetail?.startOptions?.[item]}
                  label={item}
                  clearable
                  onChange={(e: any, v: any) => {
                    handleChange({
                      start: {
                        ...(props.ageRangeDetail?.start || {}),
                        [item]: v.value,
                      },
                    });
                  }}
                />
              )
            )}
          </Form.Group>
        </Form>
        <label className={classes.text_label} style={{ paddingLeft: "10px" }}>
          {intl.formatMessage({ id: "bplusClinicKey489" })}
        </label>
        <Form className={classes.form_dropdown}>
          <Form.Group>
            {(["year", "month", "day"] as ["year", "month", "day"]).map(
              (item, index) => (
                <Form.Field
                  key={"form-to" + index}
                  value={props.ageRangeDetail?.to?.[item] || ""}
                  selection
                  control={Dropdown}
                  options={props.ageRangeDetail?.toOptions?.[item]}
                  label={item}
                  clearable
                  onChange={(e: any, v: any) => {
                    handleChange({
                      to: {
                        ...(props.ageRangeDetail?.to || {}),
                        [item]: v.value,
                      },
                    });
                  }}
                />
              )
            )}
          </Form.Group>
        </Form>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ paddingTop: "20px", width: "90%" }}>
          <ReactTable
            className="weight-table-custom web-mom"
            data={props.ageRangeList}
            columns={columnsAgeRangeData}
            style={{ height: "350px" }}
            showPagination={false}
            pageSize={
              (props.ageRangeList?.length || 0) > 10
                ? props.ageRangeList?.length
                : 10
            }
            getTheadThProps={handleGetTheadThProps}
            getTrProps={handleGetTrProps}
          />
        </div>
      </div>

      <ModConfirm
        titleColor="yellow"
        openModal={openModClearInput}
        onDeny={() => setOpenModClearInput(false)}
        onApprove={handleClearInput}
        onCloseWithDimmeClick={() => setOpenModClearInput(false)}
        content={
          <div style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "bplusClinicKey813" })}
          </div>
        }
      />

      <ModConfirm
        titleColor="red"
        openModal={!!openModDelete}
        onDeny={() => setOpenModDelete(null)}
        onApprove={handleDelete}
        onCloseWithDimmeClick={() => setOpenModDelete(null)}
        content={
          <div style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "bplusClinicKey812" })}
          </div>
        }
      />
    </div>
  );
};

export const TabVaccine: React.FC<VaccineProps> = (props) => {
  const intl = useIntl();
  const [openModClearInput, setOpenModClearInput] = useState<boolean>(false);
  const [openModDelete, setOpenModDelete] = useState<number | null>(null);
  const [openModInfo, setOpenModInfo] = useState<any>(null);

  useEffect(() => {
    props.onEvent({
      message: "GetVaccineUMList",
      params: { patientType: props.patientType },
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const getChoiceVaccineSeries = props.onEvent({
        message: "GetChoiceVaccineSeries",
        params: {},
      });

      const getListAgeRange = props.onEvent({
        message: "GetListAgeRange",
        params: { patientType: "VACCINE_CHILD" },
      });
      await Promise.all([getChoiceVaccineSeries, getListAgeRange]);
    };
    if (props.patientType === "CHILD") {
      fetchData();
    }
  }, []);

  const columnsVaccineItem = useMemo(
    () => [
      {
        Header: "Vaccine name",
        accessor: "name",
        width: 150,
      },
      {
        Header: "Edit",
        accessor: "_icon",
        width: 40,
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onEvent({
                  message: "HandleSelectedVaccineUM",
                  params: { data: original },
                });
              }}
            >
              <Icon name="edit outline" color="green" />
            </div>
          );
        },
      },
      {
        Header: "Delete",
        accessor: "_icon",
        width: 40,
        style: {
          alignContent: "center",
          textAlign: "center",
        },
        Cell: ({ original }: any = {}) => {
          return (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenModDelete(original?.id)}
            >
              <Icon name="minus circle" color="red" />
            </div>
          );
        },
      },
      {
        Header: "Abbreviation",
        width: 120,
        accessor: "",
        Cell: ({ original }: any = {}) => {
          return (
            <>
              {original.vaccine_type === "OTHERS" ? (
                <div className="child-column" style={{ textAlign: "center" }}>
                  {original.abbreviation}
                </div>
              ) : (
                new Array(original.qty)
                  ?.fill("")
                  ?.map((item: any, index: number) => (
                    <div
                      key={"abbreviation-" + index}
                      className="child-column"
                      style={{ textAlign: "center" }}
                    >
                      {original.abbreviation}
                      {original.qty > 1 ? index + 1 : ""}
                    </div>
                  ))
              )}
            </>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ original }: any = {}) => {
          return (
            <>
              {original.vaccine_type === "OTHERS" ? (
                <div className="child-column">
                  {original.description}
                  {original.qty ? ` (จำนวน ${original.qty} เข็ม)` : ""}
                </div>
              ) : (
                new Array(original.qty)
                  ?.fill("")
                  ?.map((item: any, index: number) => (
                    <div key={"description-" + index} className="child-column">
                      {original.description}
                      {original.qty > 1 ? ` เข็มที่ ${index + 1}` : ""}
                    </div>
                  ))
              )}
            </>
          );
        },
      },
      {
        Header: "Age range",
        accessor: "agr_range",
        width: 140,
        show: props.patientType === "CHILD",
        Cell: ({ original }: any = {}) => {
          const findAgeRange = (value: number) => {
            return (
              props.ageRangeList?.find((item) => item.id === value)?.name ||
              "\u00a0"
            );
          };

          return (
            <>
              {original.vaccine_type === "OTHERS" ? (
                <div className="child-column">
                  {original.age_range
                    .map((id: number) => findAgeRange(id))
                    .join(", ")}
                </div>
              ) : (
                new Array(original.qty)
                  ?.fill("")
                  ?.map((item: any, index: number) => (
                    <div key={"agr_range-" + index} className="child-column">
                      {findAgeRange(original.age_range?.[index])}
                    </div>
                  ))
              )}
            </>
          );
        },
      },
      {
        Header: "Type",
        accessor: "vaccine_type",
        width: 120,
        show: props.patientType === "CHILD",
        Cell: ({ original }: any = {}) => {
          const findLabel = (type: any) => {
            return (
              props.vaccineSeriesChoice?.find((item: any) => item?.id === type)
                ?.label || ""
            );
          };

          return (
            <div style={{ textAlign: "center" }}>
              {original.vaccine_type === "OTHERS" ? (
                <div className="child-column">
                  {findLabel(original?.vaccine_type)}
                </div>
              ) : (
                new Array(original.qty)
                  ?.fill("")
                  ?.map((item: any, index: number) => (
                    <div key={"vaccine_type-" + index} className="child-column">
                      {findLabel(original?.vaccine_type)}
                    </div>
                  ))
              )}
            </div>
          );
        },
      },
    ],
    [props.vaccineSeriesChoice, props.ageRangeList]
  );

  const CreateOptions = (items: any[]) => {
    const mapOption = (item: any, index: number) => ({
      key: index,
      text: item.label || item.name,
      value: item.id,
    });
    return items.map(mapOption);
  };

  const ageRangeOptions = useCallback(
    (index: number, value: any) => {
      {
        // const cloneArray = [...(props.vaccineUMDetail?.age_range || [])];
        // const filter = props.ageRangeList?.filter(
        //   (item) => !cloneArray.includes(item.id) || item.id === value
        // );

        return CreateOptions(props.ageRangeList || []);
      }
    },
    [props.ageRangeList, props.vaccineUMDetail?.age_range]
  );

  const ageList = useMemo(() => {
    let list: any[] = [];

    if (props.vaccineUMDetail?.vaccine_type === "OTHERS") {
      list = new Array(props.vaccineUMDetail.dose || 0)
        .fill("")
        .map(
          (item, index) => props.vaccineUMDetail?.age_range?.[index] || null
        );
    } else {
      list =
        typeof props.vaccineUMDetail?.age_range?.[0] !== "undefined"
          ? props.vaccineUMDetail?.age_range
          : [null];
    }
    return list;
  }, [
    props.vaccineUMDetail?.age_range,
    props.vaccineUMDetail?.vaccine_type,
    props.vaccineUMDetail?.dose,
  ]);

  /* ----------------------- Handle ----------------------- */
  const handleSetVaccineDMDetail = (params: State["vaccineUMDetail"]) => {
    props.onEvent({
      message: "HandleSetVaccineDMDetail",
      params,
    });
  };
  const handleToggle = (value: number, type: "left" | "right") => () => {
    const { checked, items } = props.vaccineUMDetail?.[type] || {};
    const currentIndex = (checked || []).indexOf(value);
    const newChecked = [...(checked || [])];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    handleSetVaccineDMDetail({
      [type]: {
        items,
        checked: newChecked,
      },
    });
  };

  const getTransferList = (
    target: "left" | "right",
    next: "left" | "right"
  ) => {
    const detail = props.vaccineUMDetail || {};

    return {
      // filter item ที่เลือกไว้
      [target]: {
        items: detail[target]?.items?.filter(
          (item: any, index) => !detail[target]?.checked?.includes(index)
        ),
        checked: [],
      },
      // push item ที่เลือกไว้
      [next]: {
        ...detail[next],
        items: [
          ...(detail[next]?.items || []),
          ...(detail[target]?.checked?.map(
            (value) => detail[target]?.items?.[value]
          ) || []),
        ],
      },
    };
  };

  const handleCheckedRight = () => {
    handleSetVaccineDMDetail(getTransferList("left", "right"));
  };

  const handleCheckedLeft = () => {
    handleSetVaccineDMDetail(getTransferList("right", "left"));
  };

  const handleGetTheadThProps = () => {
    return {
      style: {
        padding: "10px 0",
        borderRight: "1px solid rgba(0,0,0,0.02)",
      },
    };
  };

  const handleSearch = (value: string) => {
    props.onEvent({
      message: "HandleSearchOrderItemVaccineUM",
      params: { search: value },
    });
  };

  const handleClearInput = () => {
    props.onEvent({ message: "HandleClearInputVaccineUM", params: {} });
    setOpenModClearInput(false);
  };

  const handleSave = () => {
    if (!props.vaccineUMDetail?.name) {
      setOpenModInfo([""]);
      return;
    }
    handleCloseModInfo();
    props.onEvent({
      message: "HandleSaveVaccineUM",
      params: { patientType: props.patientType },
    });
  };

  const handleCloseModInfo = () => {
    setOpenModInfo(null);
  };

  const handleGetTrProps = (state: any, rowInfo: any) => {
    return {
      style: {
        backgroundColor:
          rowInfo?.original &&
          rowInfo?.original?.id === props.vaccineUMDetail?.id
            ? "rgb(214, 236, 243)"
            : "",
      },
    };
  };

  const handleDelete = () => {
    props.onEvent({
      message: "HandleDeleteVaccineUM",
      params: { id: openModDelete, patientType: props.patientType },
    });
    setOpenModDelete(null);
  };

  const handleAddDescription = (type: "add" | "remove", index: number) => {
    let ageRange: (number | null)[] = [];

    if (type === "add") {
      ageRange = [...(ageList || []), null];
    } else {
      ageRange = (ageList || []).filter((_: any, idx) => idx !== index);
    }

    handleSetVaccineDMDetail({ age_range: ageRange });
  };

  console.log(props);
  return (
    <div style={{ padding: "50px 30px 30px 30px" }}>
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Form style={{ display: "flex", alignItems: "baseline" }}>
          <Form.Field style={{ display: "flex" }} error={!!openModInfo}>
            <div style={styles.TextAgeHeader}>
              <FormattedMessage id="bplusClinicKey516" />
            </div>
            <div style={{ color: "red" }}>*</div>
            <Input
              value={props.vaccineUMDetail?.name || ""}
              type="text"
              style={{ border: "#C4C4C4", width: "280px", padding: "0px 10px" }}
              onChange={(e: any, v: any) => {
                handleSetVaccineDMDetail({ name: v.value });
              }}
            />
          </Form.Field>
          <Form.Field>
            {props.vaccineSeriesChoice?.[0] && (
              <div
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: "15px" }}>
                  {intl.formatMessage({ id: "bplusClinicKey498" })}
                </div>
                {props.vaccineSeriesChoice?.map(
                  (item, index) =>
                    item.label && (
                      <Radio
                        key={"radio-" + index}
                        checked={
                          props.vaccineUMDetail?.vaccine_type === item.id
                        }
                        label={item.label}
                        style={{ marginRight: "25px" }}
                        onClick={() => {
                          handleSetVaccineDMDetail({
                            vaccine_type: item.id,
                          });
                        }}
                      />
                    )
                )}
              </div>
            )}
          </Form.Field>
        </Form>

        <div>
          <Button
            style={{ ...styles.ButtonAgeClear, margin: "0px 10px" }}
            color="yellow"
            onClick={() => setOpenModClearInput(true)}
          >
            <FormattedMessage id="bplusClinicKey113" />
          </Button>
          <Button
            style={{ ...styles.ButtonAgeSave, margin: "0px 10px" }}
            color="blue"
            onClick={handleSave}
          >
            <FormattedMessage id="bplusClinicKey431" />
          </Button>
        </div>
      </div>
      <div style={{ display: "flex", paddingTop: "10px" }}>
        <div style={styles.TextAgeHeader}>
          <FormattedMessage id="bplusClinicKey357" />
        </div>
        <div>
          <Grid
            style={{ padding: "30px", display: "flex" }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              {
                <CustomList
                  items={props.vaccineUMDetail?.left?.items || []}
                  onToggle={handleToggle}
                  checked={props.vaccineUMDetail?.left?.checked || []}
                  type="left"
                  onSearch={handleSearch}
                />
              }
            </Grid>
            <Grid item style={{ marginTop: "0px" }}>
              <div style={{ marginTop: "150%" }}>
                <div>
                  <Icon
                    name="arrow circle right"
                    color="blue"
                    size="large"
                    onClick={handleCheckedRight}
                    disabled={!props.vaccineUMDetail?.left?.checked?.length}
                  />
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <Icon
                    name="arrow circle left"
                    color="blue"
                    size="large"
                    onClick={handleCheckedLeft}
                    disabled={!props.vaccineUMDetail?.right?.checked?.length}
                  />
                </div>
              </div>
            </Grid>
            <Grid item style={{ marginTop: "0px" }}>
              <CustomList
                items={props.vaccineUMDetail?.right?.items || []}
                onToggle={handleToggle}
                checked={props.vaccineUMDetail?.right?.checked || []}
                hideInputSearch={true}
                type="right"
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Form style={{ marginLeft: "10%" }}>
        <Form.Group inline style={{ alignItems: "baseline" }}>
          <Form.Input
            value={props.vaccineUMDetail?.abbreviation || ""}
            fluid
            style={styles.InputDescription}
            label={<FormattedMessage id="bplusClinicKey36" />}
            onChange={(e: any, v: any) => {
              handleSetVaccineDMDetail({ abbreviation: v.value });
            }}
          />
          <Form.Input
            value={props.vaccineUMDetail?.description || ""}
            fluid
            style={styles.InputDescription}
            label={<FormattedMessage id="bplusClinicKey156" />}
            onChange={(e: any, v: any) => {
              handleSetVaccineDMDetail({ description: v.value });
            }}
          />
          {props.patientType === "DM" && (
            <Form.Input
              value={props.vaccineUMDetail?.dose || ""}
              fluid
              style={styles.InputDescription}
              label={<>Dose</>}
              type="number"
              onChange={(e: any, v: any) => {
                handleSetVaccineDMDetail({
                  dose: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                });
              }}
            />
          )}

          {props.patientType === "CHILD" &&
            props.vaccineUMDetail?.vaccine_type === "OTHERS" && (
              <Form.Input
                value={props.vaccineUMDetail?.dose || ""}
                fluid
                style={{ ...styles.InputDescription, width: "90px" }}
                label={<>Dose</>}
                type="number"
                onChange={(e: any, v: any) => {
                  handleSetVaccineDMDetail({
                    dose: v.value === "" ? "" : +v.value < 0 ? 0 : +v.value,
                  });
                }}
              />
            )}

          {props.patientType === "CHILD" && (
            <Form.Field style={{ display: "grid" }}>
              {!!ageList.length && (
                <label style={{ fontWeight: "normal" }}>Age range</label>
              )}
              {ageList.map((item, index) => (
                <div key={"age-list-" + index} style={{ display: "flex" }}>
                  <Dropdown
                    value={props.vaccineUMDetail?.age_range?.[index] as any}
                    style={{ marginBottom: "10px" }}
                    selection
                    options={ageRangeOptions(index, item)}
                    label={<>Age range</>}
                    clearable
                    onChange={(e, v: any) => {
                      handleSetVaccineDMDetail({
                        age_range: ageList.map((value, idx) =>
                          idx === index ? v.value : value
                        ),
                      });
                    }}
                  />
                  {props.vaccineUMDetail?.vaccine_type !== "OTHERS" && (
                    <Icon
                      name={
                        index === ageList.length - 1 ? "plus circle" : "trash"
                      }
                      color={index === ageList.length - 1 ? "blue" : "red"}
                      style={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => {
                        handleAddDescription(
                          index === ageList.length - 1 ? "add" : "remove",
                          index
                        );
                      }}
                    />
                  )}
                </div>
              ))}
            </Form.Field>
          )}
        </Form.Group>
      </Form>

      {(props.patientType === "DM" ||
        props.vaccineUMDetail?.vaccine_type === "OTHERS") && (
        <div style={{ marginLeft: "10%" }}>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Can Add manually"
            checked={props.vaccineUMDetail?.add_manually || false}
            onClick={() => {
              handleSetVaccineDMDetail({
                add_manually: !props.vaccineUMDetail?.add_manually,
              });
            }}
          />
        </div>
      )}

      <div style={{ textAlign: "right", padding: "30px 5% 0 0" }}>
        <Input
          icon="search"
          type="text"
          placeholder="Search..."
          style={{ border: "#C4C4C4", width: "300px" }}
          onChange={(e, v) => {
            props.onEvent({
              message: "HandleSearchVaccineUM",
              params: { search: v.value },
            });
          }}
        />
      </div>
      <div
        style={{
          paddingTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ReactTable
          className="weight-table-custom web-mom vaccine"
          data={props.vaccineUMList}
          columns={columnsVaccineItem}
          showPagination={false}
          pageSize={props.vaccineUMList?.length}
          style={{ height: "350px", width: "90%" }}
          getTheadThProps={handleGetTheadThProps}
          getTrProps={handleGetTrProps}
        />
      </div>

      <ModConfirm
        titleColor="yellow"
        openModal={openModClearInput}
        onDeny={() => setOpenModClearInput(false)}
        onApprove={handleClearInput}
        onCloseWithDimmeClick={() => setOpenModClearInput(false)}
        content={
          <div style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "bplusClinicKey813" })}
          </div>
        }
      />

      <ModConfirm
        titleColor="red"
        openModal={!!openModDelete}
        onDeny={() => setOpenModDelete(null)}
        onApprove={handleDelete}
        onCloseWithDimmeClick={() => setOpenModDelete(null)}
        content={
          <div style={{ textAlign: "center" }}>
            {intl.formatMessage({ id: "bplusClinicKey812" })}
          </div>
        }
      />

      {/* <ModInfo
        open={!!openModInfo}
        className="mod-error-custom"
        titleColor={"red"}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        <ErrorMessage error={openModInfo} />
      </ModInfo> */}
    </div>
  );
};

Vaccine.defaultProps = {} as VaccineProps;

export default React.memo(Vaccine);
