import React, { useState, useEffect, useMemo, useRef } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// Icon
import CloseIcon from "@mui/icons-material/Close";

import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";

// UX
import FilterSearchDoctor from "./FilterSearchDoctor";
import { SCLocationType } from "./SelectCenter";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// appointment
import SearchBox from "../appointment/SearchBox";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type SearchDoctorProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
} & Pick<
  State,
  | "loadingSearch"
  | "loadingStatus"
  | "doctorSearchList"
  | "textSearch"
  | "hospitalList"
  | "availableDoctorSearch"
  | "allAvailableDoctorList"
  | "centerGroupList"
  | "appointmentCenterList"
>;

export type SDLocationType = {
  center?: Record<string, any>;
  storedState?: {
    filterMode?: boolean;
    textSearch?: string;
  };
} & SCLocationType;

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  shadow: "0px 3px 15px 1px rgba(0, 102, 255, 0.08)",
  border: "rgba(204, 218, 237, 1)",
  grey: "rgba(57, 57, 57, 1)",
  very_light_blue: "rgba(1, 71, 163, 0.05);",
};

const boxSearchStyles = {
  "& .location-input-search": {
    zIndex: 4,
  },
  "& .ui.input>input": {
    borderColor: COLORS.border,
    backgroundColor: "white !important",
    "&:focus": {
      borderColor: COLORS.primary,
      "& ~ i": {
        color: COLORS.primary,
        opacity: "1 !important",
      },
    },
  },
  "& .close.circular": {
    right: "11px !important",
    top: "13px !important",
    zIndex: 4,
  },
  "& .see-all": {
    display: "none !important",
  },
  "& div[class*=search_doctor]": {
    boxShadow: COLORS.shadow,
    paddingTop: "40px",
    marginTop: "-25px",
    "& ul": {
      maxHeight: "82.5vh",
    },
  },
};

const chipStyles = {
  width: "fit-content",
  backgroundColor: COLORS.very_light_blue,
  borderRadius: "8px",
  padding: ".5rem .75rem",
  display: "flex",
  alignItems: "center",
  color: COLORS.primary,
  margin: "7px 7px 0 0",
  "& svg": {
    fontSize: "1rem",
    color: COLORS.primary,
    marginLeft: "0.75rem",
    marginTop: "1px",
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    background: "white",
    height: "calc(100vh - 50px)",
    width: "100%",
    position: "relative",
    padding: "10px 16px 0",
  },
  box_search: boxSearchStyles,
  title: {
    fontWeight: "bold",
    color: COLORS.grey,
    padding: "16px 0 4px",
  },
  box_chip: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0",
  },
  chip: chipStyles,
}));

export const formattedDoctor = (centerGroupItems: any[], data: any) => {
  const centers = centerGroupItems.flatMap((item) => item.items);

  return {
    ...data,
    centers: data.centers.map((item: any) => {
      const center = centers.find((acc) => acc.id === item.center_id) || {};

      return { ...item, name: center.name, id: item.center_id };
    }),
  };
};

const SearchDoctor = (props: SearchDoctorProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<SDLocationType>();
  const intl = useIntl();

  const [recentlySearchWords, setRecentlySearchWords] = useState<string[]>([]);
  const [filterMode, setFilterMode] = useState<boolean>(false);
  const [waitText, setWaitText] = useState<boolean>(false);

  const searchBoxRef = useRef<any>(null);

  // Memo Effect
  const doctorItems = useMemo(() => {
    return (props.doctorSearchList?.items || []).map((item) => ({
      ...item,
      is_doctor: true,
    }));
  }, [props.doctorSearchList]);

  // Memo Effect
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  // Effect
  useEffect(() => {
    let lists = JSON.parse(localStorage.getItem("recentlySearchWords") || "[]");

    setRecentlySearchWords(lists);
  }, []);

  useEffect(() => {
    if (location.state?.center) {
      handleSubmit();
    }
  }, [location.state]);

  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      setFilterMode(storedState.filterMode || false);

      if (!storedState.filterMode) {
        searchBoxRef.current.focus();
      }

      if (!doctorItems.length) {
        handleSearch(storedState.textSearch || "");
      }
    }
  }, [locState.storedState]);

  useEffect(() => {
    if (!props.centerGroupList?.length) {
      props.onEvent({
        message: "GetListCenterGroup",
        params: { lang: intl.locale },
      });
    }
  }, []);

  useEffect(() => {
    if (!props.appointmentCenterList?.length) {
      props.onEvent({
        message: "GetListAppointmentCenter",
        params: { lang: intl.locale },
      });
    }
  }, []);

  useEffect(()=>{
    if (waitText) {
      setWaitText(false)
      handleUpdateSearch()
    }
  },[props.textSearch])

  // Memo Callback
  const centerGroupItems = useMemo(() => {
    const filterDoctorItems = props.allAvailableDoctorList?.items || [];
    const searchDoctorItems = doctorItems;

    const centers = (
      filterMode ? filterDoctorItems : searchDoctorItems
    ).flatMap((item) => item.centers);

    let groups = centers.reduce((result, item) => {
      const key = item.center_group_id;
      const data = {
        id: item.center_id,
        slug: item.center_slug,
      };

      if (!result[key]) {
        const group = props.centerGroupList?.find(
          (acc) => acc.id === item.center_group_id
        );

        result[key] = {
          id: key,
          slug: item.center_group_slug,
          name: group?.name,
          image: group?.icon_url,
          items: [data],
        };
      } else {
        result[key].items.push(data);
      }

      return result;
    }, {} as any);

    groups = Object.values(groups).map((item: any) => {
      let unique = Array.from(
        new Map(item.items.map((acc: any) => [acc["id"], acc])).values()
      );

      unique = unique.map((acc: any) => {
        const center = props.appointmentCenterList?.find(
          (o) => o.id === acc.id
        );

        return { ...acc, name: center?.name, image: center?.image_url };
      });

      return {
        ...item,
        items: unique,
      };
    });

    return groups as any[];
  }, [
    props.allAvailableDoctorList,
    props.centerGroupList,
    props.appointmentCenterList,
    doctorItems,
    filterMode,
  ]);

  // Handler
  const handleSearch = (value: string) => {
    props.onEvent({
      message: "HandleSearchListDoctor",
      params: { name: value, emptyClear: true ,hospitalCode: locState.hospital?.code},
    });

    const filterModeState = !!locState.storedState?.filterMode;
    const isFilterMode = filterModeState || filterMode;
    const clearState = filterModeState && !!value;

    if (isFilterMode && value) {
      setFilterMode(false);

      props.setProp("availableDoctorSearch", null);
    }

    if (clearState) {
      props.onEvent({
        message: "HandleHistoryPushState",
        params: {
          history,
          state: {},
        },
      });
    }
  };

  const handleUpdateSearch = () => {
    if (props.textSearch) {
      const lists = Array.from(
        new Set([...recentlySearchWords, props.textSearch])
      );

      localStorage.setItem("recentlySearchWords", JSON.stringify(lists));

      setRecentlySearchWords(lists);
    }
  };

  const handleSelect = (data: any) => {
    handleUpdateSearch();

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        pathname: URLS.DOCTOR_PROFILE,
        history,
        state: {
          doctor: formattedDoctor(centerGroupItems, data),
        },
        storedState: { filterMode, textSearch: props.textSearch },
      },
    });
  };

  const handleSubmit = () => {
    handleUpdateSearch();

    if (!props.textSearch) {
      setWaitText(true)
    }
    setFilterMode(true);
  };

  const handleDelete = (textSelected:string) => {
    console.log("!!!delete");
    const recentlySearchWordsFilter = recentlySearchWords.filter((text)=> text !== textSelected)

    localStorage.setItem("recentlySearchWords", JSON.stringify(recentlySearchWordsFilter));

    setRecentlySearchWords(recentlySearchWordsFilter);

  };

  const handleSelectWord = (text: string) => {
    handleSearch(text);

    searchBoxRef.current.focus();
  };

  const handleClearSearch = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        storedState: null,
      },
    });

    setFilterMode(false);

    if (!location.state?.center) {
      setTimeout(() => {
        props.setProp("availableDoctorSearch", null);
      }, 500);
    }

  };

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="ค้นหาแพทย์"
        whiteTheme={true}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      <div className={classes.screen}>
        <SearchBox
          ref={searchBoxRef}
          className={classes.box_search}
          placeholder="ค้นหาแพทย์"
          hideButtonFilter={true}
          hideSearchList={props.loadingSearch}
          hideNotFound={true}
          textSearch={props.textSearch}
          isLoadingSearch={props.loadingSearch}
          list={filterMode ? [] : doctorItems}
          subTitleField="hospital_name"
          // config
          showOnBlur={true}
          // callback
          onSearch={handleSearch}
          onSelect={handleSelect}
          onInputSubmit={handleSubmit}
          onClear={handleClearSearch}
        />

        {!filterMode ? (
          <>
            <div className={classes.title}>คำที่ท่านค้นหาล่าสุด</div>
            <div className={classes.box_chip}>
              {recentlySearchWords.map((text) => (
                <div key={text} className={classes.chip}>
                  <label
                    aria-hidden="true"
                    onClick={() => handleSelectWord(text)}
                  >
                    {text}
                  </label>

                  <CloseIcon onClick={() => handleDelete(text)} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <FilterSearchDoctor
            onEvent={props.onEvent}
            setProp={props.setProp}
            // data
            hospitalList={props.hospitalList}
            textSearch={props.textSearch}
            availableDoctorSearch={props.availableDoctorSearch}
            allAvailableDoctorList={props.allAvailableDoctorList}
            centerGroupList={props.centerGroupList}
            appointmentCenterList={props.appointmentCenterList}
            centerGroupItems={centerGroupItems}
            // CommonInterface
            loadingStatus={props.loadingStatus}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(SearchDoctor);
