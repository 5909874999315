import WasmController from "../react-lib/frameworks/WasmController";

// Interface
import * as MobInsI from "./MobInsuranceInterface";

console.log(" MobInsuranceController !!! init ");

export default class MobInsuranceController extends WasmController<
  MobInsI.State,
  MobInsI.Event,
  MobInsI.Data
> {
  constructor(
    getState: () => MobInsI.State,
    setState: (state: MobInsI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobInsI.DataInitial);
  }

  handleEvent = (e: MobInsI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      case "HandleSetOpenBurger":
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative":
        globalThis.mobile.setBackToNative();
        return;

      // Setup
      case "DidMount":
        MobInsI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobInsI.DidUpdate(this, e.params);
        return;

      case "HandleGetClaimList":
        MobInsI.HandleGetClaimList(this, e.params);
        return;

      case "SelectedClaim":
        MobInsI.SelectedClaim(this, e.params);
        return;  

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
