import React, { useState } from "react";

const TagSet = (props: any) => {

  const [mode, setMode] = useState("normal");
  const [parent, setParent] = useState<string|null>(null);
  const [hover, setHover] = useState<number|null>(null);

  return(
    <div 
      style={{ 
        display: "flex", 
        border: "solid #eeeeee 1px",
        position: "relative",
        top: 0,
        left: 0
      }}>

      {Object.values(props.tagSet).length > 0 &&
      <div style={{ marginRight: "5px"}}>
        {Object.values(props.tagSet).map((item: any, index: number) => 
          <span
            onMouseEnter={(e: any) => setHover(index)}
            onMouseLeave={(e: any) => setHover(null)}
            onClick={(e: any) => {
              if (mode === "normal") {
                props.onEvent({
                  message: "RemoveTagFromTagSet",
                  params: {
                    tagIndex: props.tagIndex,
                    tag: item,
                    dataType: props.dataType
                  }
                });
              }
            }}
            style={{ 
              cursor: "pointer",
              textDecoration: mode === "normal" && hover === index ? "line-through" : "none" 
            }}>
            {`${index > 0 ? ", " : ""}${item.name}`}
          </span>
        )}
      </div>}

      <div 
        onClick={(e: any) => {
          setHover(null);
          setMode(mode === "normal" ? "select" : "normal");
        }}
        style={{ 
          cursor: "pointer",
          backgroundColor: "cyan", 
          borderRadius: "1rem", 
          padding: "0 0.3rem 0 0.3rem" 
        }}>
        +
      </div>
      
      {mode === "select" &&
      <div 
        style={{ 
          position: "absolute", 
          top: "1.5rem", 
          left: 0, 
          backgroundColor: "white",
          border: "solid #eeeeee 1px",
          zIndex: 1000,
          display: "grid",
        }}>
        {parent !== null &&
        
        <div
          style={{ cursor: "pointer" }}
          onClick={(e: any) => {
            let tags = props.tagList.filter((tag: any) => tag.id === parent);
            if (tags.length > 0) {
              setParent(tags[0].parent);
            }
          }}>
          &lt; ย้อน
        </div>}
        
        {props.tagList.filter((tag: any) => tag.parent === parent)
        .map((tag: any, index: number) => (
        <div 
          key={index}
          style={{ cursor: "pointer", display: "flex" }}>
          
          <div 
            style={{ width: "150px", marginLeft: "10px" }}
            onClick={(e: any) => {
              if (tag.parent !== null) {
                props.onEvent({
                  message: "AddTagToTagSet",
                  params: { 
                    tag: tag, 
                    tagIndex: props.tagIndex,
                    dataType: props.dataType
                  }
                });
                setParent(null);
              }
            }}>
            {tag.name}
          </div>
          
          {props.tagList.filter((t: any) => t.parent === tag.id).length > 0 &&
          <div
            onClick={(e: any) => {
              setParent(tag.id);
            }}>
            &gt;
          </div>}
        
        </div>))}
      </div>}
      
    </div>
  )
}

export default TagSet