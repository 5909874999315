import React, { useMemo, useState, useEffect, useCallback } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import {
  Label, Header, Icon, Image,
  Message, MessageContent, MessageHeader,
  Container, HeaderSubheader, HeaderContent
} from "semantic-ui-react";


import PulltoRefresh from "../appointment/PulltoRefresh";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";

// Interface
import { Event, State } from "../MobInsuranceInterface";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const styles = {
  card: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    margin: "10px",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 3px #c4c4c4",
    marginBottom: "1rem",
  } as CSSProperties,
  cardContent: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    width: "100%"
  } as CSSProperties,
  title_text_blue: {
    color: COLOR.BlueBDMS,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "29px",
    margin: "1rem 0px 10px",
  } as CSSProperties,
  title_text_white: {
    color: COLOR.font_white,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "29px",
    margin: "1rem 0px 10px",
  } as CSSProperties,
  desc_text_white: {
    color: COLOR.font_white,
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "normal",
    margin: "0.5rem 0px 10px",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_Black_16: {
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.font_black,
    margin: "24px 0px 0px 0px"
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16_noellipsis: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
  statusText: {
    color: "green",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  } as CSSProperties,
};

// Types
type ClaimListProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  history: any;
  loadingStatus: Record<string, any>;
  // data
  claimListData?: any[];
};

// Styles
const COLORS = {
  btn_gradient: "linear-gradient(90deg, #0147A3 0%, #0B87CD 100%)",
  bg_gradient: "linear-gradient(147.46deg, #0669EB 0%, #00357A 71.53%)",
  bg_opacity: "rgba(255, 255, 255, 0.12)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    background: "white",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
  },
  // header_inner: {
  //   position: "relative",
  //   padding: "20px",
  //   textAlign: "center",
  //   background: `linear-gradient(147.46deg, #0669EB 0%, #00357A 145%)`,
  //   borderBottomLeftRadius: "30px",
  //   borderBottomRightRadius: "30px",
  //   overflow: "hidden",
  // },
  header_inner: {
    position: "relative",
    padding: "15px 15px 20px",
    background: COLORS.btn_gradient,
    marginTop: "-50px",
    paddingTop: "50px",
  },
  backgroundImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "100%",
    width: "auto",
    zIndex: 1001,
    paddingTop: "-50px",
  },
  foregroundContent: {
    position: "relative",
    zIndex: 1,
  },
  content: {
    position: "absolute",
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    marginTop: "-16px",
    background: "white",
    width: "100%",
    height: "100%",
    padding: "22px 16px",
    zIndex: 1001,
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: "bold",
    margin: "1rem 0px 0.25rem 0px",
  },
  pull_to_refresh: {
    "& .ptr__pull-down": {
      top: "1rem",
    },
    "& .ptr__children": {
      paddingBottom: "20px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  },
  emptyState: {
    textAlign: "center",
    padding: "2rem 1rem",
  },
  emptyImage: {
    width: "150px",
    marginBottom: "1rem",
  },
  emptyText: {
    fontSize: "16px",
    color: COLOR.DarkGray_Line,
    marginBottom: "1rem",
  },
}));

const mockClaimListData = [
  {
    "datetime": "2024-05-06T11:47:00.000Z",
    "ref_id": "AA28628A2C25897071348C5799E51CF891EE7AED62CFEEF4498C03C946BD77D2",
    "event_type": "claim_status",
    "attach_doc_list": [
      {
        "document_name": "Document.pdf",
        "document_ref_id": "9A59A07EF27E789823B49B91CAFD40C5"
      }
    ],
    "claim_status_desc": "อนุมัติการเรียกร้องสินไหม",
    "claim_status": "Approve",
    "patient": {
      "national_id": "0480000004240",
      "policy_no": "0000101932",
      "first_name": "C883FEE1A1E69D641A0F42A8188A0B87FB122A0D3E7841E8076B5019CC1C68E2",
      "last_name": "A5992728EF0955B1FB2617165B6F2754854AE168C08DD443AB66049E10FD6A46",
      "policy_type": "CS"
    }
  }
]

const ClaimList = (props: ClaimListProps) => {
  const classes = useStyles();

  // Use mock data if claimListData is not provided
  // let claimListData = mockClaimListData;

  const handleGetClaimList = useCallback(() => {
    props.onEvent({ message: "HandleGetClaimList", params: { card: "ClaimList", } });
  }, []);

  useEffect(() => {
    handleGetClaimList();
  }, []);

  const handleReload = () => {
    handleGetClaimList();
  }

  const handleSelectedItem = (item: any) => {
    console.log(item)
    props.onEvent({ message: "SelectedClaim", 
      params: { card: "ClaimList", item: item } });
    props.history.push('/claim-detail/?app=MobInsurance')
  }

  const renderClaimCard = (claim: any) => {
    let lastStatus = claim.history[claim.history.length - 1];
    const isCovered = lastStatus.claim_status === "Approve";
    const policyTypeText = claim.patient.policy_type === "CS" ? "ประกันกลุ่ม" : "ประกันรายบุคคล";

    return (
      <div style={{ paddingBottom: "1rem" }} key={claim.ref_id}>
        <div
          style={styles.card}
          onClick={() => {
            handleSelectedItem(claim)
          }}
        >
          <div style={styles.cardContent}>
            <div style={{ paddingRight: "10px" }}>
              <Image
                src={`/images/insurance/icon_aia.png`}
                size="tiny"
                rounded
              />
            </div>
            <div style={{ alignItems: "center" }}>
              <p style={styles.text_Black_16}>{`ประกัน AIA`}</p>
              <p style={{ color: COLOR.DarkGray_Line }}>
                {policyTypeText}
              </p>
            </div>
            <Image
              src="/images/insurance/arrow_right_fill.svg"
              style={{ marginLeft: "auto" }}
            />
          </div>
          <Message
            icon
            positive={isCovered}
            negative={!isCovered}
          >
            <Message.Content>
              <Icon
                name="circle"
                size="mini"
                color={isCovered ? "green" : "red"}
              />
              {lastStatus.claim_status_desc}
              {/* {isCovered
                ? "ประกันคุ้มครอง"
                : "ประกันไม่คุ้มครอง"} */}
            </Message.Content>
            <Image
              src={
                isCovered
                  ? "/images/insurance/approve.png"
                  : "/images/insurance/reject.png"
              }
              style={{ width: "24px", height: "24px" }}
            />
          </Message>
        </div>
      </div>
    );
  };

  console.log("ClaimList render !!", props);

  return (
    <div
      className="remember-scroll"
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <HeaderBar
        setTitle=""
        headerSx={{
          background: `linear-gradient(90deg, #0147A3 0%, #0B87CD 100%) !important`,
        }}
        titleStyle={{ fontWeight: "bold", fontSize: "1.1rem" }}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />
      <div className={classes.screen}>
        <div className={classes.header_inner}>
          <Container className={classes.backgroundImage}>
            <Image
              src='/images/insurance/bg1.png'
            />
          </Container>
          <div className={classes.foregroundContent}>
            <Header as="h2" style={styles.title_text_white}>
              <Image src='/images/insurance/policy_icon_frame.png' />
              <HeaderContent>
                สถานะการตรวจสอบสิทธิ์
                <HeaderSubheader style={styles.desc_text_white}>ผลการตรวจสอบคุ้มครองก่อนเข้ารับการรักษา</HeaderSubheader>
              </HeaderContent>
            </Header>
          </div>
        </div>

        <div className={classes.content}>
          <div className={classes.title}>ประกันของคุณ</div>
          <PulltoRefresh
            className={classes.pull_to_refresh}
            onRefresh={() => handleReload()}
            isPullable={!props.loadingStatus['ClaimList']}
          >
            {props.claimListData?.length === 0 ? (
              <div className={classes.emptyState}>
                <Image
                  src="/images/insurance/empty.png"
                  className={classes.emptyImage}
                  centered
                />
                <div className={classes.emptyText}>
                  คุณไม่มีรายการประกันในขณะนี้
                </div>
              </div>
            ) : (
              props.claimListData?.map(renderClaimCard)
            )}

            {/* {claimListData.map((claim, index) => (
              <div key={index} style={{ paddingBottom: "1rem" }}>
                <div style={styles.card}>
                  <div style={styles.cardContent}>
                    <div style={{ paddingRight: "10px" }}>
                      <Image src={`/images/insurance/icon_${claim.insuranceCompany.toLowerCase()}.png`} size="tiny" rounded />
                    </div>
                    <div style={{ alignItems: "center" }}>
                      <p style={styles.text_Black_16}>{`ประกัน ${claim.insuranceCompany}`}</p>
                      <p style={{ color: COLOR.DarkGray_Line }}>{claim.insuranceType}</p>
                    </div>
                    <Image src="/images/insurance/arrow_right_fill.svg" style={{ marginLeft: "auto" }} />
                  </div>
                  <Message icon positive={claim.isCovered} negative={!claim.isCovered}>
                    <Message.Content>
                      <Icon name="circle" size="mini" color={claim.isCovered ? "green" : "red"} />
                      {claim.isCovered ? "ประกันคุ้มครอง" : "ประกันไม่คุ้มครอง"}
                    </Message.Content>
                    <Image
                      src={claim.isCovered ? "/images/insurance/approve.png" : "/images/insurance/reject.png"}
                      style={{ width: "24px", height: "24px" }}
                    />
                  </Message>
                </div>
              </div>
            ))} */}

          </PulltoRefresh>

        </div>
      </div>
    </div>
  );
};

export default React.memo(ClaimList);
