import React, { useMemo, useState, useEffect, useCallback } from "react";
import { CSSProperties } from "@mui/styles";
import {
  Container, Header, HeaderContent,
  Icon, Image, Divider
} from "semantic-ui-react";
import makeStyles from "@mui/styles/makeStyles";

import HeaderBar from "bplus-lib/medication/HeaderBar";
import ClaimStatusTimeline from "./ClaimStatusTimeline"
import "./ClaimDetailCSS.scss";

const COLORS = {
  btn_gradient: "linear-gradient(90deg, #0147A3 0%, #0B87CD 100%)",
  bg_gradient: "linear-gradient(147.46deg, #0669EB 0%, #00357A 71.53%)",
  bg_opacity: "rgba(255, 255, 255, 0.12)",
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
};

const useStyles = makeStyles(() => ({
  screen: {
    backgroundColor: "#F3F5F9",
    minHeight: "100vh",
    paddingBottom: "2rem",
  },
  header_inner: {
    position: "relative",
    padding: "15px 15px 20px",
    background: COLORS.btn_gradient,
    marginTop: "-50px",
    paddingTop: "50px",
    height: "260px",
  },
  backgroundImage: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "100%",
    width: "auto",
    zIndex: 1001,
    paddingTop: "-50px",
  },
  foregroundContent: {
    position: "relative",
    zIndex: 1,
  },
  card: {
    position: "absolute",
    marginTop: "-150px",
    marginLeft: "auto",
    marginRight: "auto",
    left: "0",
    right: "0",
    width: "85%",
    height: "80%",
    padding: "1rem",
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: "0px 2px 3px #c4c4c4",
    marginBottom: "1rem",
  },
  companySection: {
    margin: "1rem",
    padding: "1rem",
    backgroundColor: "#F8F8F8",
    borderRadius: "12px",
  },
  statusSection: {
    margin: "1rem",
    padding: "1rem",
    backgroundColor: "#F8F8F8",
    borderRadius: "12px",
  },
  statusItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  statusIcon: {
    fontSize: "1.5rem",
    marginRight: "1rem",
  },
  divider: {
    margin: "2rem 0",
  },
}));

// Types
type ClaimDetailProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  history: any;
  loadingStatus: Record<string, any>;
  // data
  selectedClaim?: {};
};

const ClaimDetail = (props: ClaimDetailProps) => {
  const classes = useStyles();
  const [policyTypeText, setPolicyTypeText] = useState("");
  const [patientName, setPatientName] = useState("");

  useEffect(() => {
    if (props.selectedClaim) {
      let policyTypeText = props.selectedClaim?.patient?.policy_type === "CS" ? "ประกันกลุ่ม" : "ประกันรายบุคคล";
      setPolicyTypeText(policyTypeText)

      let patientName = `${props.selectedClaim?.patient?.first_name} ${props.selectedClaim?.patient?.last_name}`
      setPatientName(patientName)
    }
  }, [props.selectedClaim]);

  // Mock data for display purposes
  const claimDetail = {
    companyLogo: "/images/insurance/icon_aia.png",
    policyHolderName: "สุขภาพ แข็งแรง",
    policyNumber: "0123456789",
    policyType: "ประกันรายบุคคล",
    statusTimeline: [
      { label: "โรงพยาบาลส่งข้อมูลให้กับประกัน", date: "28 พ.ย. 2566 10:00", icon: "paper plane" },
      { label: "บริษัทประกันกำลังพิจารณา", date: "30 พ.ย. 2566 11:00", icon: "clock outline" },
      { label: "บริษัทประกันขอข้อมูลเพิ่มเติม", date: "", icon: "info circle" },
      { label: "ยืนยันให้ข้อมูลแล้ว", date: "", icon: "check circle" },
    ],
  };

  return (
    <div
      className="claim-detail"
      style={{ height: "100vh", overflowY: "auto" }}
    >
      {/* Header Section */}
      <HeaderBar
        setTitle=""
        headerSx={{
          background: `linear-gradient(90deg, #0147A3 0%, #0B87CD 100%) !important`,
        }}
        titleStyle={{ fontWeight: "bold", fontSize: "1.1rem" }}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />
      <div className={classes.screen}>
        <div className={classes.header_inner}>
          <Container className={classes.backgroundImage}>
            <Image
              src='/images/insurance/bg2.png'
            />
          </Container>
          <div className={classes.foregroundContent}>
            <p className="title-text-white">
              รายละเอียดการตรวจสอบสิทธิ์
            </p>
          </div>
        </div>


        {/* Insurance Card */}
        <div className={classes.card}>
          <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column", }}>
            {/* Company name section */}
            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
              <Image src={claimDetail.companyLogo} size="tiny" rounded />
              <p className="text-black-20">{`ประกัน AIA`}</p>
            </div>
            <Divider />
            <div className="insurance-details">
              <div className="detail-item">
                <p className="label">ชื่อผู้เอาประกัน</p>
                <p className="value">{patientName}</p>
              </div>
              <div className="detail-item">
                <p className="label">ประเภทประกัน</p>
                <p className="value">{policyTypeText}</p>
              </div>
              <div className="detail-item">
                <p className="label">เลขที่กรมธรรม์</p>
                <p className="value">{props.selectedClaim?.patient?.policy_no}</p>
              </div>
            </div>
            <Divider />
            {/* Status Section */}
            <div style={{ width: "100%" }}>
              <p className="label">สถานะ</p>
              <ClaimStatusTimeline />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimDetail;
