import React from "react";
import { Icon, ListIcon, ListContent, List } from "semantic-ui-react";
import "./ClaimStatusTimeline.css";

const ClaimStatusTimeline = () => {
  const timelineData = [
    {
      status: "โรงพยาบาลส่งข้อมูลให้กับประกัน",
      date: "28 พ.ย. 2566 10:00",
      icon: "paper plane",
      subStatuses: [],
      active: true,
    },
    {
      status: "บริษัทประกันกำลังพิจารณา",
      date: "30 พ.ย. 2566 11:00",
      icon: "clock outline",
      subStatuses: [
        "บริษัทประกันขอข้อมูลเพิ่มเติม",
        "ยืนยันให้ข้อมูลแล้ว",
      ],
      active: true,
    },
    {
      status: "ผลการตรวจสอบ",
      date: "",
      icon: "shield",
      subStatuses: [],
      active: false,
    },
  ];

  return (
    <div className="timeline-container">
      {timelineData.map((item, index) => (
        <div className="timeline-item" key={index}>
          {/* Timeline Icon */}
          {/* <Icon circular size='large' color="blue" name={item.icon} /> */}
          <div className={`timeline-icon ${item.active ? "active" : ""}`}>
            <Icon circular color={`${item.active ? "blue" : "grey"}`} name={item.icon} />
          </div>

          {/* Connector Line */}
          {index < timelineData.length - 1 && (
            <div className="timeline-connector">
              <div className="connector-line" />
            </div>
          )}

          {/* Status Content */}
          <div className="timeline-content">
            <div className="timeline-status">
              <p className={item.active ? "active-status" : "inactive-status"}>
                {item.status}
              </p>
              {item.date && <p className="timeline-date">{item.date}</p>}
            </div>

            {/* Sub-statuses */}
            {item.subStatuses.length > 0 && (
              <List className="sub-status-list">
                {item.subStatuses.map((sub, subIndex) => (
                  <List.Item key={subIndex} className="sub-status-item">
                    <ListIcon name="circle" size="small" color="orange" className="sub-status-icon" />
                    <ListContent className="sub-status-text">
                      {sub}
                    </ListContent>
                  </List.Item>
                ))}
              </List>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClaimStatusTimeline;
