import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import {
  Button,
  Dimmer,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import { formatUTCtoMoment } from "../../../react-lib/utils/dateUtils";
import PureReactTable from "../../../react-lib/component-js/common/PureReactTable";
import SnackMessage from "../../clinic/SnackMessage";
import { ModConfirm } from "react-lib/apps/common";
// Interface
import { CLINIC, DM_RESULT, MOM_RESULT } from "../../ManageInterface";

const styles = {
  BGheader: {
    background: "#FFFFFF",
    padding: "30px 45px",
  } as CSSProperties,
  Textheader: {
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "20px",
    textDecorationLine: "underline",
  } as CSSProperties,
  DivInput: {
    display: "flex",
    padding: "30px 50px 0px 50px",
    justifyContent: "space-between",
  } as CSSProperties,
  ButtonAdd: {
    background: "#219653",
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  ButtonAddPatient: {
    background: "#2399E5",
    borderRadius: "4px",
    color: "#FFFFFF",
  } as CSSProperties,
  TextPatientHeader: {
    padding: "30px 50px 0px 50px",
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "16px",
  } as CSSProperties,
  TextAddHeader: {
    color: "#0072BC",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    paddingBottom: "30px",
  } as CSSProperties,
};

// Modal
type ModalPatientSelectionProps = {
  // function
  onEvent?: any;
  handleCloseModal?: any;
  // data
  loadingStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;
  dmMomPatientList?: any;
};

const ModalPatientSelection: React.FC<ModalPatientSelectionProps> = (props) => {
  const [keyword, setKeyword] = useState<string>("");
  const [patientOptions, setPatientOptions] = useState<any[]>([]);
  const [addPatientSelected, setAddPatientSelected] = useState<any[]>([]);
  // Pagination
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const columnsAdd = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 200 },
      { Header: "Phone No.", accessor: "phone_no", width: 200 },
      { Header: "Patient’s name", accessor: "patient_name", width: 350 },
      {
        Header: "Birthdate",
        accessor: "birthdate",
        width: 200,
        Cell: ({ row }: any = {}) => {
          if (row?.birthdate) {
            return (
              <>{formatUTCtoMoment(row?.birthdate).format("DD MMM YYYY")}</>
            );
          }
        },
      },
    ],
    []
  );

  useEffect(() => {
    handleGetProxyPatientList();
  }, [page, limit]);

  const handleSearchPatient = (event: any) => {
    if (event.key === "Enter") {
      handleGetProxyPatientList();
    }
  };

  const handleGetProxyPatientList = () => {
    props.onEvent({
      message: "handleGetProxyPatientList",
      params: {
        keyword: keyword,
        exclude_empty_hn: true,
        limit: limit,
        offset: page * limit,
      },
    });
  };

  const handleSelectedPatient = (row?: any) => {
    setPatientOptions([
      ...patientOptions,
      { ...row, text: row?.patient_name, value: row.id },
    ]);
    setAddPatientSelected([...addPatientSelected, row.id]);
  };

  const handleUnSelectedPatient = (_event: any, data: any) => {
    let options: any[] = [];
    patientOptions?.forEach((item: any) => {
      if (data.value.includes(item.id)) {
        options.push(item);
      }
    });

    setPatientOptions(options);
    setAddPatientSelected([...data.value]);
  };

  const handleCreatePatientSegment = () => {
    props.onEvent({
      message: "handleCreatePatientSegment",
      params: patientOptions,
    });
    setPatientOptions([]);
    setAddPatientSelected([]);
    setKeyword("");
    props.handleCloseModal();
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    setPage(pageNo);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setLimit(pageSize);
  };

  return (
    <Modal
      closeIcon
      size="large"
      open={true}
      closeOnDimmerClick={true}
      onClose={props.handleCloseModal}
    >
      <Modal.Header>
        <FormattedMessage id="bplusClinicKey41" />
      </Modal.Header>
      <Modal.Content scrolling>
        <Dimmer active={props.loadingStatus} inverted>
          <Loader inverted>
            <FormattedMessage id="bplusClinicKey297" />
          </Loader>
        </Dimmer>

        <SnackMessage
          onEvent={props.onEvent}
          error={props.errorMessage}
          success={props.successMessage}
        />

        <Grid verticalAlign="middle" style={{ marginBottom: "0px" }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Input
                fluid
                icon="search"
                value={keyword}
                placeholder="HN/ชื่อ/นามสกุล/เบอร์โทรศัพท์"
                onChange={(event: any) => {
                  setKeyword(event.target.value);
                }}
                onKeyPress={handleSearchPatient}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Column width={1}>
            <FormattedMessage id="bplusClinicKey445" />
          </Grid.Column>
          <Grid.Column width={13}>
            <Dropdown
              fluid
              multiple
              selection
              options={patientOptions}
              value={addPatientSelected}
              onChange={handleUnSelectedPatient}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              fluid
              style={styles.ButtonAddPatient}
              onClick={handleCreatePatientSegment}
            >
              <FormattedMessage id="bplusClinicKey39" />
            </Button>
          </Grid.Column>
        </Grid>

        <PureReactTable
          // @ts-ignore
          className="weight-table-custom web-mom"
          onSelect={handleSelectedPatient}
          data={props.dmMomPatientList?.items}
          columns={columnsAdd}
          showPagination={true}
          pageSize={limit}
          pages={Math.ceil(props.dmMomPatientList?.total / limit)}
          page={page}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          manual
        />
      </Modal.Content>
    </Modal>
  );
};

// WebUMPatientSelection
type WebUMPatientSelectionProps = {
  // function
  onEvent?: any;
  // data
  loadingStatus?: boolean;
  loadingPatientSelection?: boolean;
  errorMessage?: any;
  successMessage?: any;
  selectedDivision?: any;
  clinic?: string;
  filterKeyword?: string;
  dmMomSegmentList?: any;
  dmMomPatientList?: any;
};

const WebUMPatientSelectionInitial: WebUMPatientSelectionProps = {
  // function
  onEvent: () => null,
  // data
  loadingStatus: false,
  loadingPatientSelection: false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  clinic: "",
  filterKeyword: "",
  dmMomSegmentList: {},
  dmMomPatientList: {},
};

export const WebUMPatientSelection: React.FC<WebUMPatientSelectionProps> = (
  props
) => {
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [selectedPatient, setSelectedPatient] = useState<any>({});
  const [openAddPatient, setOpenAddPatient] = useState<boolean>(false);
  const [openModDelete, setOpenModDelete] = useState<any>({
    open: false,
    row: {},
  });

  useEffect(() => {
    handleGetDmMomSegmentList();
  }, [limit, page, props.selectedDivision]);

  const columnsMomPatientData = useMemo(
    () => [
      { Header: "HN", accessor: "hn", width: 150 },
      { Header: "Patient’s name", accessor: "fullname", width: 500 },
      {
        Header: "Birthdate",
        accessor: "birthdate",
        width: 150,
        Cell: ({ row }: any = {}) => {
          if (row?.birthdate) {
            return (
              <>{formatUTCtoMoment(row?.birthdate).format("DD-MM-YYYY")}</>
            );
          }
        },
      },
      {
        Header: "Delete",
        accessor: "_remove",
        width: 80,
        Cell: (row: any) => {
          return (
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Icon
                color="red"
                name="minus circle"
                onClick={() => {
                  handleDeletePatientSegment(row.original);
                }}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const handleGetDmMomSegmentList = () => {
    if (props.selectedDivision?.division_code !== undefined) {
      if (props.clinic === CLINIC.DM) {
        props.onEvent({
          message: "handleGetDmMomSegmentList",
          params: {
            result_contain: DM_RESULT.DM,
            limit: limit,
            offset: page * limit,
          },
        });
      } else if (props.clinic === CLINIC.MOM) {
        props.onEvent({
          message: "handleGetDmMomSegmentList",
          params: {
            result_contain: MOM_RESULT,
            limit: limit,
            offset: page * limit,
          },
        });
      }
    }
  };

  const handleKeyEnter = (event: any) => {
    if (event.key === "Enter") {
      handleGetDmMomSegmentList();
    }
  };

  const handleSelectedPatientSegment = (row?: any) => {
    setSelectedPatient(row);
  };

  // Pagination
  const handlePageChange = (pageNo: number) => {
    setPage(pageNo);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setLimit(pageSize);
  };

  // Modal
  const handleOpenModal = () => {
    setOpenAddPatient(true);
  };

  const handleCloseModal = () => {
    setOpenAddPatient(false);
  };

  // Modal Delete
  const handleDeletePatientSegment = (row: any) => {
    setOpenModDelete({ open: true, row: row });
  };

  const handleConfirmDeletePatientSegment = () => {
    props.onEvent({
      message: "handleDeletePatientSegment",
      params: openModDelete?.row,
    });
    handleCloseModDelete();
  };

  const handleCloseModDelete = () => {
    setOpenModDelete({ open: false, row: {} });
  };

  return (
    <div className="main-layout web-dm-um">
      <Dimmer active={props.loadingStatus} inverted>
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>

      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage}
        success={props.successMessage}
      />

      <div style={{ background: "white", padding: "30px 15px" }}>
        <Header
          as="h2"
          style={{
            color: "var(--text-dark-blue)",
            textDecorationLine: "underline",
          }}
        >
          {props.clinic === CLINIC.MOM ? (
            <FormattedMessage id="bplusClinicKey326" />
          ) : (
            <>DM center’s Patient list</>
          )}
        </Header>
        <Grid style={{ paddingBottom: "20px" }}>
          <Grid.Column width={8}>
            <Input
              fluid
              icon="search"
              placeholder="Search HN. / ชื่อ-นามสกุล / เบอร์โทรศัพท์"
              value={props.filterKeyword}
              onChange={(event: any) => {
                props.onEvent({
                  message: "handleChangeFilterKeyword",
                  params: { keyword: event.target.value },
                });
              }}
              onKeyPress={handleKeyEnter}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <div style={{ display: "flex" }}>
              <Button style={styles.ButtonAdd} onClick={handleOpenModal}>
                <FormattedMessage id="bplusClinicKey41" />
              </Button>
            </div>
          </Grid.Column>
        </Grid>
        <PureReactTable
          // @ts-ignore
          className="weight-table-custom web-mom"
          selectedRow={selectedPatient}
          data={props.dmMomSegmentList?.items}
          columns={columnsMomPatientData}
          showPagination={true}
          pageSize={limit}
          pages={Math.ceil(props.dmMomSegmentList?.total / limit)}
          page={page}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          onSelect={handleSelectedPatientSegment}
          loading={props.loadingPatientSelection}
          manual
        />
      </div>

      {openAddPatient && (
        <ModalPatientSelection
          // function
          onEvent={props.onEvent}
          handleCloseModal={handleCloseModal}
          // data
          dmMomPatientList={props.dmMomPatientList}
        />
      )}

      <ModConfirm
        titleColor={"yellow"}
        openModal={openModDelete.open}
        onDeny={handleCloseModDelete}
        onApprove={handleConfirmDeletePatientSegment}
        onCloseWithDimmeClick={handleCloseModDelete}
        titleName={`ต้องการลบ [${openModDelete?.row?.hn}] ${openModDelete?.row?.fullname} หรือไม่`}
      />
    </div>
  );
};

WebUMPatientSelection.defaultProps = WebUMPatientSelectionInitial;

export default React.memo(WebUMPatientSelection);
