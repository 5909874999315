import WasmController from "react-lib/frameworks/WasmController";

import { History } from "history";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import FastAPIRequestView from "issara-sdk/apis/FastAPIRequestViewM";

import * as MobInsuranceI from "./MobInsuranceInterface";

// Utils

// Config

// Types
export type State = Partial<{
  loggedin: boolean;
  platform: string;
  theme: string;
  projectName: string;
  device_id: string | number;
  subscription: any;
  apiToken: string;
  userId: string | number;
  permit: string;
  language: "en-US" | "th-TH";
  channelId: string | number;

  // Common
  patientDetail: any;

  // message
  loadingStatus: Record<string, any>;
  errorMessage: Record<string, any>;
  successMessage: Record<string, any>;

  claimListData: any[];
  selectedClaim: any;
}>

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  loadingStatus: {},
  errorMessage: {},
  successMessage: {},
  patientDetail: [],
  claimListData: [],
  selectedClaim: {},
};

export type Event =
  // Native
  | { message: "GetLoginInfo"; params: {} }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "HandleGetClaimList"; params: {} }
  | { message: "SelectedClaim"; params: {} }


export type Data = {};

export const DataInitial = {};

const searchParams = new URLSearchParams(window.location.search);
const app = searchParams.get("app");

const MOB_APP = `app=${app}`;

const backURL = encodeURIComponent(
  `${
    window.location.origin
  }/payment-complete/${"appointment"}/?app=MobPayment&deliveryflag=false`
);

export const SHIPPING_COMPANY_CODE = {
  express: "Lala",
  normal: "EMS",
  "": "",
};

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, Data>,
  params: P
) => R;

type Params<A extends Event["message"]> = Extract<
  Event,
  { message: A }
>["params"];

export const DidMount: Handler = async (controller, params) => {
  console.log("MobPayment DidMount: ");

  let userId = controller.cookies.get("userId");
  let apiToken = controller.cookies.get("apiToken");

  if (!controller.apiToken && apiToken) {
    controller.apiToken = apiToken;
  }

  if (!controller.user && userId) {
    controller.user = userId;
  }
};

export const DidUpdate: Handler = async (controller, params) => {
  //
};

export const HandleGetClaimList: Handler = async (
  controller,
  params
) => {
  let state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [result] = await FastAPIRequestView.get({
    bearer: controller.cookies.get("bearer"),
    endpoint: "/insurance/list_claim_status/",
  });

  controller.setState({
    claimListData: result || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const SelectedClaim: Handler = async (
  controller,
  params
) => {
  controller.setState({
    selectedClaim: params.item || {},
  });
};
