import React, { useMemo, useEffect, useState, CSSProperties } from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

import makeStyles from "@mui/styles/makeStyles";

import moment from "moment";

// UX
import BoxTitle from "./BoxTitle";
// telephar
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";

// Types
type CardAppointmentProps = {
  type: "doctor" | "check_up";
  data: AppointmentDataType;
  invoice: any;
  isRefundRequest: boolean;
  // callback
  onClickMore?: (data: AppointmentDataType) => any;
  onClickNote?: (note: string) => any;
};

type AppointmentDataType = {
  [key: string]: any;
  status?: StatusType;
  is_telemed: boolean;
};

type StatusType = "CANCELED" | "REQUESTED";

// Images
const IMAGES = {
  pin: "/images/payment/pin-location.png",
  doctor: "/images/Feed/doctor.png",
  note: "/images/Appointment/note-outline.png",
  neurosurgery: "/images/Appointment/neurosurgery.png",
  video: "/images/Appointment/video-call-outline-grey.png",
  hospital: "/images/Appointment/hospital-outline-grey.png",
  warning: "/images/Appointment/warning-outline.png",
};

// Styles
const COLORS = {
  bg_shadow: "linear-gradient(317.15deg, #F9F8F8 -14.59%, #EFEFEF 87.87%)",
  light_grey: "rgba(121, 120, 120, 1)",
  shadow: "1px 8px 18px 0px rgba(0, 102, 255, 0.1)",
  grey: "rgba(57, 57, 57, 1)",
  light_yellow: "rgba(255, 244, 201, 1)",
  primary: "var(--blue-bdms-color)",
  very_light_blue: "rgba(236, 244, 255, 1)",
  border: "rgba(233, 233, 233, 1)",
  yellow: "rgba(243, 156, 18, 1)",
  red: "rgba(218, 0, 0, 1)",
};

const styles = {
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
  } as CSSProperties,
};

const boxPatientStyles = {
  display: "flex",
  alignItems: "baseline",
  marginTop: "0.45rem",
  justifyContent: "space-between",
  "& label": {
    color: COLORS.light_grey,
  },
  "& span": {
    color: COLORS.grey,
    fontWeight: "bold",
    marginLeft: "0.25rem",
  },
  "& .note": {
    backgroundColor: COLORS.light_yellow,
    borderRadius: "8px",
    padding: "5.35px 5.5px",
    display: "flex",
    "& img": {
      width: "17px",
      margin: "auto",
    },
  },
};

const bottomStyles = {
  display: "flex",
  justifyContent: "space-between",
  color: COLORS.primary,
  marginTop: "1rem",
  "& > div:nth-child(2)": {
    display: "flex",
    alignItems: "center",
    "& .box-dot": {
      backgroundColor: COLORS.very_light_blue,
      color: COLORS.primary,
      fontSize: "1.5rem",
      borderRadius: "500rem",
      width: "22px",
      height: "22px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "0.5rem",
      "& .dot": {
        width: "3.19px",
        height: "3.19px",
        borderRadius: "500rem",
        backgroundColor: COLORS.primary,
        margin: "0 1px",
      },
    },
    "& .box-cancel": {
      display: "grid",
      color: COLORS.light_grey,
      fontSize: ".9375rem",
      textAlign: "right",
      marginTop: "-0.225rem",
      "& > div:nth-child(1)": {
        color: COLORS.red,
        fontSize: "1.0625rem",
        fontWeight: "bold",
        marginBottom: "0.5rem",
      },
    },
  },
};

const cardStyles = {
  "&.MuiPaper-root ": {
    boxShadow: COLORS.shadow,
    borderRadius: "8px",
  },
  "& .MuiCardContent-root": {
    width: "100%",
    padding: "16px 14px",
    "& .doctor-profile-detail.check_up > div:nth-child(1)": {
      width: "100px",
      borderRadius: "8px",
      border: "none",
    },
  },
  marginTop: "12px",
};

const useStyles = makeStyles(() => ({
  card: cardStyles,
  title: {
    fontWeight: "bold",
    marginBottom: "0.55rem",
  },
  box_patient: boxPatientStyles,
  bottom: bottomStyles,
  warning: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: COLORS.yellow,
    fontWeight: "bold",
    fontSize: ".875rem",
    marginTop: "0.75rem",
    "& img": {
      width: "18px",
      marginLeft: "0.5rem",
    },
  },
}));

// Const
const IMAGE_PLACEHOLDER = {
  doctor: IMAGES.doctor,
  check_up: IMAGES.neurosurgery,
};

// Const
const APPOINTMENT_TITLE = {
  online: { title: "พบแพทย์ออนไลน์", icon: IMAGES.video, style: {} },
  "on-site": {
    title: "ใช้บริการที่โรงพยาบาล ",
    icon: IMAGES.hospital,
    style: { marginTop: "-0.25rem" },
  },
};

const CardAppointment = (props: CardAppointmentProps) => {
  const classes = useStyles();

  // Memo
  const appointmentDatetime = useMemo(() => {
    const splitTime = (time: string) => {
      return time?.split(":")?.slice(0, 2).join(":") || "";
    };
    // 07:00:00
    const startTime = splitTime(props.data.start_time);
    let endTime = splitTime(props.data.end_time);

    endTime = endTime ? `-${endTime}` : "";

    return props.data.date
      ? `${moment(props.data.date).format(
          "DD MMM YYYY"
        )}|${startTime}${endTime}`
      : "";
  }, [props.data]);

  const appLoc = useMemo(() => {
    return APPOINTMENT_TITLE[props.data.is_telemed ? "online" : "on-site"];
  }, [props.data.is_telemed]);

  // Handler
  const handleOpenModMore = () => {
    props.onClickMore?.(props.data);
  };

  return (
    <Card
      className={`${classes.card}`}
      // onClick={handleClick}
    >
      <CardActionArea disableRipple={true}>
        <CardContent>
          <div className={`doctor-profile-detail ${props.type}`}>
            <div>
              <img
                src={
                  props.data.doctor_details?.image ||
                  IMAGE_PLACEHOLDER[props.type]
                }
                onError={(e: any) => {
                  e.target.src = IMAGE_PLACEHOLDER[props.type];
                }}
              />
            </div>
            <div>
              <label className={classes.title}>
                {props.data.doctor_details?.full_name}
              </label>
              <BoxTitle type="label">
                <img className="icon" src={IMAGES.pin}></img>
                <label style={{ color: COLORS.light_grey, padding:"5px 2px", fontSize: "16px" }}>
                  {props.data.hospital_name}
                </label>
              </BoxTitle>
            </div>
          </div>
          <div className={classes.box_patient}>
            <div>
              <label>ชื่อคนไข้</label> : <span>{props.data.patient_name}</span>
            </div>

            <div
              style={
                ["REQUESTED", "CANCELED"].includes(props.data.status as any)
                  ? { opacity: 0, pointerEvents: "none" }
                  : {}
              }
            >
              <div className="note" onClick={() => props.onClickNote?.(props.data.note_to_patient)}>
                <img src={IMAGES.note} />
              </div>
            </div>
          </div>

          <BoxTitle
            type="datetime"
            datetime={appointmentDatetime}
            style={{ minHeight: "45px", marginTop: "0.75rem" }}
          />

          <BoxTitle style={{ minHeight: "45px", marginTop: "0.75rem" }}>
            {props.type === "doctor" ? (
              <div style={styles.ellipsis}>{props.data.division_name}</div>
            ) : (
              <div>
                <div className="normal" style={{ marginBottom: "0.4rem" }}>
                  ประเภทการตรวจสุขภาพ
                </div>
                <div>ตรวจสุขภาพประจำปี</div>
              </div>
            )}
          </BoxTitle>

          {!["REQUESTED", "CANCELED"].includes(props.data.status as any) && (
            <AlmostTime
              datetime={`${props.data.date}`}
            />
          )}

          {props.data.status === "REQUESTED" && (
            <PaymentAlertMessage
              type="info-yellow"
              style={{ margin: "1rem 0 1.5rem" }}
            >
              กรุณารอการยืนยันนัดหมายนัดจาก โรงพยาบาล ภายใน 24 ชั่วโมง
            </PaymentAlertMessage>
          )}

          <div className={classes.bottom}>
            {props.type === "doctor" ? (
              <BoxTitle type="label" style={{ alignItems: "baseline" }}>
                <img
                  className="icon"
                  src={appLoc.icon}
                  style={appLoc.style}
                ></img>
                <label style={{lineHeight: "1.5rem",padding:"5px 2px"}}>{appLoc.title}</label>
              </BoxTitle>
            ) : (
              <div></div>
            )}

            <StatusContent
              status={props.data.status}
              start_time={props.data.start_time}
              onOpenModMore={handleOpenModMore}
              df={props?.invoice?.receipt?.price || 0}
              data={props.data}
              invoice={props?.invoice}
              waitingRefund={props.isRefundRequest}
              isBeforeRefund={props.data.is_refund_before_expired}
              isTelemed={props.data.is_telemed}
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

/* ------------------------------------------------------ */

/*                       AlmostTime                       */

/* ------------------------------------------------------ */
// datetime YYYY-MM-DD HH:mm
const AlmostTime = (props: any) => {
  const classes = useStyles();

  const [isDisplay, setIsDisplay] = useState(false);

  useEffect(() => {
    const date = moment(props.datetime);
    
    if (date.isValid()) {
      let interval = setInterval(() => {
        const diff = moment().diff(moment(date), "minutes");

        const isAlmostIme = diff > -30;

        if (isAlmostIme) {
          setIsDisplay(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, [props.datetime]);

  return (
    <>
      {isDisplay && (
        <div className={classes.warning}>
          ใกล้ถึงเวลานัดหมาย <img src={IMAGES.warning} />
        </div>
      )}
    </>
  );
};

/* ------------------------------------------------------ */

/*                     StatusContent;                     */

/* ------------------------------------------------------ */
type StatusContentProps = {
  status?: StatusType;
  waitingRefund?: boolean;
  start_time? : string;
  data?: any;
  invoice?:any;
  df?: number;
  isBeforeRefund?: boolean;
  isTelemed?: boolean;
  // callback
  onOpenModMore?: () => any;
};

const StatusContent = (props: StatusContentProps) => {
console.log(" ~ StatusContent props:", props)

  const checkRefund = () => {
    if (props.isTelemed) {
      if (props.isBeforeRefund) {
        return `ยอดเงินคืน ${props.df || "-"} ฿`
      } else if (props.waitingRefund) {
        return "ส่งคำขอคืนเงินแล้ว"
      }  else {
        return `ไม่สามารถคืนเงินได้`
      }
    } 
    return;
  }

  if (props.status === "CANCELED") {
    return (
      <div>
        <div className="box-cancel">
          <div>ถูกยกเลิก</div>
          <div>{checkRefund()}</div>
        </div>
      </div>
    );
  } else if (props.status === "REQUESTED") {
    return null;
  } else {
    return (
      <div aria-hidden="true" onClick={props.onOpenModMore}>
        <div className="box-dot">
          <div className="dot"></div> <div className="dot"></div>
          <div className="dot"></div>
        </div>
        เพิ่มเติม
      </div>
    );
  }
};

export default React.memo(CardAppointment);
