import React, { ReactElement, useState, useMemo } from "react";
// MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import { FormattedMessage, useIntl } from "react-intl";

// Icon
import CloseIcon from "@mui/icons-material/Close";

// UX
import DialogReadMore from "./DialogReadMore";

// Interface
import { Event } from "../MobSmartRegisterInterface";

// CSS
import "../../css/MobFeed.scss";
import { POLICY } from "./MainLobby";

// Types
type CardKYCProps = {
  onEvent: (e: Event) => any;
  // data
  actionType: string | null;
  description: ReactElement | string;
  myProfileDetail?: Record<string, any>;
  history: History<{
    appointment?: {
      pathname: string;
      search: string;
      state: string;
    };
  }>;
};

// Images
const IMAGES = {
  document: "/images/register/service-document.png",
  face: "/images/register/service-face.png",
  id_card: "/images/register/service-id-card.png",
  passport: "/images/register/service-passport.png",
  bg: "/images/register/bg-gradient.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  grey: "#2c2c2c",
  very_light_blue: "#d6e1f1",
  light_blue: "rgb(204 218 237)",
  light_grey: "rgba(121, 120, 120, 1)",
  opacity_blue: "rgba(1, 71, 163, 0.4)",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex_end: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

export const cardStyles = {
  borderRadius: "8px !important",
  boxShadow: "none !important",
  border: `1px solid ${COLORS.very_light_blue} !important`,
  backgroundColor: "rgba(236, 241, 248, 0.35) !important",
  width: "100%",
  marginBottom: "1rem",
  "& .MuiCardContent-root": {
    padding: "15px 15px !important",
  },
  "& .MuiCardActionArea-root": {
    display: "initial",
    alignItems: "center",
    height: "100%",
    "& .number": {
      color: COLORS.blue,
      padding: "10px",
      borderRadius: "500rem",
      backgroundColor: COLORS.light_blue,
      ...styles.center,
      fontSize: "1.0625rem",
      height: "2.1875rem",
      width: "2.1875rem",
      fontWeight: "bold",
    },
    "& .title": {
      fontSize: "1.1875rem",
      fontWeight: "bold",
      padding: "0.2rem 0 0.25rem",
    },
    "& .content": {
      lineHeight: 1.35,
    },
  },
};

export const cardModStyles = {
  borderRadius: "8px",
  backgroundColor: "rgba(243, 246, 250, 0.45)",
  width: "100%",
  height: "100%",
  padding: "16px",
  border: `1px solid ${COLORS.very_light_blue} !important`,
  margin: "1.15rem 0 2rem",
  lineHeight: 1.5,
  "& .center": {
    textAlign: "center",
  },
  "& .--bold": {
    fontWeight: "bold",
  },
  "& .--large": {
    fontSize: "1.125rem",
  },
  "& .--small": {
    fontSize: ".875rem",
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "16px 16px 16px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: COLORS.blue,
    padding: "1rem 0 1rem",
  },
  card: cardStyles,
}));

const CardKYC = (props: CardKYCProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const [open, setOpen] = useState<boolean>(false);

  const docType = useMemo(() => {
    return props.myProfileDetail?.cid?.length !== 13 ? "passport" : "id-card";
  }, [props.myProfileDetail]);

  const menus = useMemo(() => {
    return [
      docType === "id-card"
        ? {
            title: intl.formatMessage({ id: "bplusClinicKey1026" }),
            content: intl.formatMessage({ id: "bplusClinicKey1027" }),
            icon: IMAGES.id_card,
          }
        : {
            title: intl.formatMessage({ id: "bplusClinicKey1036" }),
            content: intl.formatMessage({ id: "bplusClinicKey1119" }),
            icon: IMAGES.passport,
          },
      {
        title: intl.formatMessage({ id: "bplusClinicKey1028" }),
        content: intl.formatMessage({ id: "bplusClinicKey1029" }),
        icon: IMAGES.face,
      },
      {
        title: intl.formatMessage({ id: "bplusClinicKey1030" }),
        content: intl.formatMessage({ id: "bplusClinicKey1031" }),
        icon: IMAGES.document,
      },
    ];
  }, [docType]);

  const titleMessageID = useMemo(() => {
    let messageID = "bplusClinicKey1024";

    if (props.actionType === "edit-profile") {
      messageID = "bplusClinicKey1023";
    }

    return messageID;
  }, [props.actionType]);

  const handleKYC = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history: props.history,
        pathname: `/kyc-step/${docType}/`,
        replace: false,
      },
    });
  };

  const handleClose = () => {
    props.onEvent({
      message: "HandleCanceleKYC",
      params: { history: props.history },
    });
  };

  const handleOpenSheet = (state: boolean) => {
    setOpen(state);
  };

  return (
    <div
      className={`${classes.screen} bg-gradient-container`}
      style={{ display: props.myProfileDetail?.patient ? "" : "none" }}
    >
      <div style={{ flexGrow: 1 }}>
        <div className="close-icon-right">
          <MuiButton color="primary" variant="text">
            <CloseIcon onClick={handleClose} />
          </MuiButton>
        </div>
        <div className={classes.header}>
          {<FormattedMessage id={titleMessageID} />}
        </div>
        {/* <div></div> */}
        <div style={{ marginBottom: "1rem" }}>
          {typeof props.description === "string" ? (
            <div>{props.description}</div>
          ) : (
            props.description
          )}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateRows: "repeat(3, 1fr)",
          }}
        >
          {menus.map((item, index) => (
            <CardItem key={`CardKYC-${item.title}`} data={item} index={index} />
          ))}
        </div>
      </div>

      <div>
        <div style={{ textAlign: "center", margin: "0.5rem 0 1rem" }}>
          <div style={{ whiteSpace: "pre-line" }}>
            <FormattedMessage id="bplusClinicKey1032" />
            <span
              aria-hidden="true"
              style={{ color: COLORS.blue }}
              onClick={() => handleOpenSheet(true)}
            >
              <FormattedMessage id="bplusClinicKey1033" />
            </span>
          </div>
        </div>
        <div className="button-submit-bottom">
          <MuiButton variant="contained" color="primary" onClick={handleKYC}>
            <FormattedMessage id="bplusClinicKey1024" />
          </MuiButton>
        </div>
      </div>

      <DialogReadMore open={open} onChange={handleOpenSheet}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {POLICY.map((item) => (
            <>
              {item.title && (
                <div
                  className="text-black-bold"
                  style={{
                    width: "100%",
                    textAlign: "center",
                    margin: "15px 0 0 0",
                  }}
                >
                  {item.title}
                </div>
              )}
              <div
                className="text-black-bold"
                style={{ fontSize: "16px", margin: "10px 0 0 0" }}
              >
                {item.header}
              </div>
              <div
                className="text-gray"
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {item.dtailList}
              </div>
            </>
          ))}
        </div>
      </DialogReadMore>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                        CardItem                        */

/* ------------------------------------------------------ */

// Types
type CardItemType = {
  data: {
    title: any;
    content: any;
    icon: string;
  };
  index: number;
};

const CardItem = (props: CardItemType) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea disableRipple={true}>
        <CardContent>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "45px 1fr 70px",
            }}
          >
            <div>
              <div className="number">{props.index + 1}</div>
            </div>
            <div>
              <div className="title">{props.data.title}</div>
              <div className="content">
                <div>
                  {typeof props.data.content === "string"
                    ? props.data.content
                        .split("\n")
                        .map((text: any) => <div key={text}>{text}</div>)
                    : props.data.content}
                </div>
              </div>
            </div>
            <div style={styles.flex_end}>
              <img
                src={props.data.icon}
                alt={`${props.data.icon}.icon`}
                style={{ width: "48px" }}
              />
            </div>
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(CardKYC);
