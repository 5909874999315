import React, { memo, useEffect, useState } from "react";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { Grid, Icon, Image, Divider } from "semantic-ui-react";

const COLOR = {
  darkgrayHos: "#797878",
  superdarkgray: "#393939",
  superligtblue: "#CCDAED",
  superligt: "#E9E9E9",
  blueBDMS: "#0147A3",
};

const styles = {
  division: {
    margin: "5px 0",
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
    background: COLOR.superligtblue,
    borderRadius: "5px",
  } as CSSProperties,
  division_detail: {
    padding: "3px",
    display: "flex",
    justifyContent: "space-between",
  } as CSSProperties,
  text_superdarkgray_14: {
    fontSize: "14px",
    color: COLOR.superdarkgray,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  } as CSSProperties,
  text_DarkGray_14: {
    fontSize: "14px",
    color: COLOR.darkgrayHos,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  } as CSSProperties,
  text_buleBDMS_14: {
    fontSize: "14px",
    color: COLOR.blueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
  text_DarkGray_14_line3: {
    fontSize: "14px",
    color: COLOR.darkgrayHos,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  } as CSSProperties,
  oneyearlab: {
    fontSize: "14px",
    color: COLOR.superdarkgray,
    textOverflow: "ellipsis",
    background: COLOR.superligt,
    borderRadius: "5px",
    padding: "1px 10px",
  } as CSSProperties,
};

type SeeMoreLabProps = {
  labOderItems?: any;
  started?: any;
};

type labOrders = {
  division_name: string | null;
  timelab: string | null;
  items: labOderData[];
};

type labOderData = {
  name: string | null;
  value: string | null;
  raf_value: string | null;
  unit: string | null;
  labStatus: string | null;
};

const SeeMoreLab = (props : SeeMoreLabProps) => {
  const [labOderItem, setLabOderItem] = useState<labOrders[]>([]);
  const [oneYearLab, setOneYearLab] = useState<boolean>(false);

  useEffect(() => {
    if (props.labOderItems !== null && props.labOderItems?.length > 0) {
      const oneYear = moment().subtract(1, "years").format("YYYY-MM-DD");
      if (moment(props.started).isAfter(oneYear)) {
        let division = props.labOderItems[0].name;
        let labOrder: labOrders[] = [];
        let labdata: labOderData[] = [];
        
        props.labOderItems.map((item: any, index: any) => {
          let raf: string = "";
          let value: string = "";
          let status: string = "normal";
          let timeDivision = props.started
          if (item.history_results === null) {
            // check parentlab is change
            // labOrder is group parentlab
            if (division !== item.name) {
              labOrder.push({
                division_name: division,
                timelab: timeDivision,
                items: [...labdata],
              });
              // console.log("saika ;) division push", {...labOrder});
              labdata.length = 0;
              division = item.name;
            }
          } else {
            // setdata childlab
            const lastArray =
              item.history_results[item.history_results.length - 1];
            value = lastArray.value;
            timeDivision = lastArray.reported_datetime;
            if (lastArray !== null && !isNaN(+lastArray.value)) {
              raf = lastArray.ref_value_txt;
              let tmpMin: number = 0;
              let tmpMax: number = 0;
              let tmpRef: string = lastArray.ref_value_txt;
              tmpRef = tmpRef.replace("(", "");
              tmpRef = tmpRef.replace(")", "");
              tmpRef = tmpRef.replace("=", "");
              if (tmpRef.search("<") > -1) {
                tmpMin = lastArray.value;
                tmpMax = parseFloat(tmpRef.replace("<", ""));
                if (tmpMin >= tmpMax) {
                  status = "high";
                }
              } else if (tmpRef.search(">") > -1) {
                tmpMin = parseFloat(tmpRef.replace(">", ""));
                tmpMax = lastArray.value;
                if (tmpMin >= tmpMax) {
                  status = "low";
                }
              } else if (tmpRef.search("-") > -1) {
                const tmpArr = tmpRef.split("-");
                tmpMin = parseFloat(tmpArr[0]);
                tmpMax = parseFloat(tmpArr[1]);
                if (
                  tmpMin <= parseFloat(value) &&
                  parseFloat(value) <= tmpMax
                ) {
                  status = "normal";
                } else if (tmpMin > parseFloat(value)) {
                  status = "low";
                } else if (parseFloat(value) > tmpMax) {
                  status = "high";
                }
              }
            } else {
              if (value === "DETECTED") {
                status = "DETECTED";
              } else {
                status = "normal";
              }
            }

            let criticalFlag = lastArray.critical_flag;
            console.log("saika ;) checl criticalFlag ",criticalFlag)
            if (criticalFlag !== "") {
              switch(criticalFlag) {
                case "H":
                  status = "high";
                  break;
                case "L":
                  status = "low";
                  break;
                default:
                  break;
              }
            }

            var filteredUnit = item.unit
            if (item.lab_code.startsWith("M438")) {
              filteredUnit = ""
            }

            // push childlab
            // labdata is group childlab
            // console.log("saika ;) childlab name", item.name);
            labdata.push({
              name: item.name,
              value: lastArray.value,
              raf_value: raf,
              unit: filteredUnit,
              labStatus: status,
            });
            // console.log("saika ;) labdata childlab ", {...labdata});
          }
          // check last array push all data
          // labOrder is group parentlab
          if (props.labOderItems.length - 1 === index) {
            labOrder.push({
              division_name: division,
              timelab: timeDivision,
              items: labdata,
            });
            // console.log("saika ;) labOrder last array", {...labOrder});
          }
        });
        // console.log("saika ;) labOrder finish", labOrder);
        setLabOderItem(labOrder);
      } else {
        setOneYearLab(true)
      }
    }
  }, [props.labOderItems]);

  return (
    <div>
      {oneYearLab ? (
        <>
          <div style={styles.oneyearlab}>
            <FormattedMessage id={"bplusClinicKey939"} />
          </div>
        </>
      ) : (
        <>
          {labOderItem
            ? labOderItem.map((item: any, index: any) => (
                <div key={index}>
                  {/* Division header */}
                  <div style={styles.division}>
                    <div
                      style={{
                        ...styles.text_superdarkgray_14,
                        paddingLeft: "5px",
                      }}
                    >
                      {item.division_name}
                    </div>
                    <div
                      style={{
                        ...styles.text_DarkGray_14,
                        paddingRight: "10px",
                      }}
                    >
                      {item.timelab? `[${moment(item.timelab).format("HH:mm")}]`: ""}
                    </div>
                  </div>
                  {item.items
                    ? item.items.map((item: any, index: any) => (
                        <div>
                          {/* Lab details */}
                          <div style={styles.division_detail}>
                            <div style={{ width: "40%" }}>
                              {item.name ? item.name : "-"}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "60%",
                              }}
                            >
                              <div>
                                {item.labStatus === "high" ? (
                                  <Icon name="arrow up" color="red" />
                                ) : item.labStatus === "low" ? (
                                  <Icon name="arrow down" color="red" />
                                ) : item.labStatus === "normal" ? (
                                  ""
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                style={{
                                  ...styles.text_buleBDMS_14,
                                  color:
                                    item.labStatus === "normal"
                                      ? COLOR.blueBDMS
                                      : "red",
                                }}
                              >
                                {item.value}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              ...styles.division_detail,
                              color: COLOR.darkgrayHos,
                            }}
                          >
                            <div>{item.raf_value || ""}</div>
                            <div>{item.unit || ""}</div>
                          </div>
                          <Divider style={{margin:"10px 0"}}/>
                        </div>
                      ))
                    : ""}
                </div>
              ))
            : "-"}
        </>
      )}
    </div>
  );
};


export default React.memo(SeeMoreLab);
