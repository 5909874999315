import React, { useState, useEffect, useMemo } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Radio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

// Icon
import CloseIcon from "@mui/icons-material/Close";

import { History } from "history";
import { FormattedMessage, useIntl } from "react-intl";

// UX
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// CSS
import "../../css/MobFeed.scss";

// Types
type SelectHospitalBindHNProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  history: History;
  title?: string;
  organizationList?: any[];
  // CommonInterface
  errorMessage?: Record<string, any>;
  loadingStatus?: Record<string, any>;
};

// Images
const IMAGES = {
  dismiss: "/images/register/dismiss.png",
  cancel: "/images/register/cancel-hn.png",
  loading: "/images/register/loading.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  light_blue: "rgba(236, 244, 255, 1)",
  half_grey: "rgba(196, 196, 196, 0.5)",
  very_light_blue: "rgba(204, 218, 237, 1)",
  very_light_grey: "#f4f7fb",
  light_grey: "rgba(196, 196, 196, 1)",
  bg: "rgb(241 245 250)",
};

const cardStyles = {
  boxShadow: "none !important",
  borderBottom: `1px solid ${COLORS.half_grey}`,
  margin: "0 -20px",
  padding: "2px 0px",
  backgroundColor: "transparent !important",
  "& .MuiCardContent-root": {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    fontWeight: "bold",
  },
  "&.--grey": {
    // backgroundColor: COLORS.very_light_grey,
    "& .MuiCardContent-root": {
      color: COLORS.light_grey,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "20px",
  },
  header: {
    fontSize: "1.75rem",
    fontWeight: "bold",
    color: COLORS.blue,
    padding: "2rem 0 1.25rem",
  },
  section: {
    backgroundColor: COLORS.light_blue,
    margin: "0 -20px",
    padding: "10px 18px",
    fontWeight: "bold",
    fontSize: "1.125rem",
  },
  card: cardStyles,
  bottom: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: COLORS.bg,
    padding: "1.25rem 0 1rem",
  },
  box_card: { paddingBottom: "9rem" },
}));

const BUTTON_ACTIONS = {
  confirm: "_CONFIRM",
  hospital: "_HOSPITAL",
};

const SELECT_HOSPITAL_BIND_HN = "SelectHospitalBindHN";

const ACTION_CONFIRM = `${SELECT_HOSPITAL_BIND_HN}${BUTTON_ACTIONS.confirm}`;
const ACTION_HOSPITAL = `${SELECT_HOSPITAL_BIND_HN}${BUTTON_ACTIONS.hospital}`;

const SelectHospitalBindHN = (props: SelectHospitalBindHNProps) => {
  const classes = useStyles();
  const intl = useIntl();

  const [selectedHospital, setSelectedHospital] = useState<any>(null);
  const [openModConfirmCancel, setOpenModConfirmCancel] =
    useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "HandleGetListOrganizations", params: {} });
  }, []);

  const organizations = useMemo(() => {
    const [available, unavailable] = (props.organizationList || []).reduce(
      (result, item) => {
        result[item.can_register_hn ? 0 : 1].push({
          ...item,
          name: intl.locale === "th" ? item.name : item.name_en,
        });
        return result;
      },
      [[], []]
    );

    return { available, unavailable } as {
      available: any[];
      unavailable: any[];
    };
  }, [props.organizationList]);

  const handleOpenModConfirmCancel = () => {
    setOpenModConfirmCancel(true);
  };

  const handleCloseModConfirmCancel = () => {
    setOpenModConfirmCancel(false);
  };

  const handleGotoFeed = async () => {
    if (globalThis.mobile?.goToMainScreen) {
      globalThis.mobile?.goToMainScreen();
    } else {
      globalThis.window.location.href = "/?app=MobFeed";
    }
  };

  const handleRegisterHN = () => {
    props.onEvent({
      message: "HandleRegisterHN",
      params: {
        code: selectedHospital.code,
        card: ACTION_CONFIRM,
        history: props.history,
        cardHospital: ACTION_HOSPITAL,
      },
    });
  };

  const handleCloseError = () => {
    const { [ACTION_HOSPITAL]: key, ...error } = props.errorMessage || {};

    props.setProp(`errorMessage`, { ...error });
  };

  return (
    <div className={`${classes.screen} bg-gradient-container`}>
      <div className="close-icon-right">
        <MuiButton
          color="primary"
          variant="text"
          onClick={handleOpenModConfirmCancel}
        >
          <CloseIcon />
        </MuiButton>
      </div>
      <div className={classes.header}>
        {props.title || <FormattedMessage id="bplusClinicKey1023" />}
      </div>
      <div className={classes.box_card}>
        <div style={{ marginBottom: "1.25rem" }}>
          <FormattedMessage id="bplusClinicKey1084" />
        </div>
        <div className={classes.section}>
          <FormattedMessage id="bplusClinicKey1083" />
        </div>
        {organizations.available.map((item) => (
          <CardItem
            key={item.id}
            data={item}
            checked={selectedHospital?.id === item.id}
            // callback
            onSelect={setSelectedHospital}
          />
        ))}
        <div className={classes.section}>
          <FormattedMessage id="bplusClinicKey1105" />
        </div>

        {organizations.unavailable.map((item) => (
          <CardItem key={item.id} data={item} disabled={true} />
        ))}
      </div>

      <div className={classes.bottom}>
        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            disabled={
              !selectedHospital || props.loadingStatus?.[ACTION_CONFIRM]
            }
            onClick={handleRegisterHN}
          >
            <FormattedMessage id="common.confirm" />
            {props.loadingStatus?.[ACTION_CONFIRM] && (
              <CircularProgress
                style={{
                  marginLeft: "0.5rem",
                }}
                size={25}
              />
            )}
          </MuiButton>
        </div>

        <div
          style={{
            textAlign: "center",
            color: COLORS.blue,
            marginBottom: "1rem",
          }}
          // onClick={props.onDeny}
        >
          <FormattedMessage id="common.cancel" />
        </div>
      </div>

      <DialogConfirm
        open={openModConfirmCancel}
        title={<FormattedMessage id="bplusClinicKey1089" />}
        description={
          <div style={{ whiteSpace: "pre-line", marginTop: "0.5rem" }}>
            <FormattedMessage id="bplusClinicKey1091" />
          </div>
        }
        subTitle={<FormattedMessage id="bplusClinicKey1090" />}
        img={IMAGES.cancel}
        imgSize="150px"
        denyBasic={true}
        // callback
        onApprove={handleGotoFeed}
        onDeny={handleCloseModConfirmCancel}
      />

      <DialogConfirm
        open={props.errorMessage?.[ACTION_CONFIRM]}
        title={<FormattedMessage id="bplusClinicKey1085" />}
        description={<FormattedMessage id="bplusClinicKey1086" />}
        img={IMAGES.loading}
        imgStyle={{ width: "150px", marginTop: 0 }}
        hideDeny={true}
        // element
        approveText={<FormattedMessage id="bplusClinicKey1078" />}
        // callback
        onApprove={handleGotoFeed}
      />

      <DialogConfirm
        open={props.errorMessage?.[ACTION_HOSPITAL]}
        title={<FormattedMessage id="bplusClinicKey1116" />}
        description={props.errorMessage?.[ACTION_HOSPITAL]}
        img={IMAGES.dismiss}
        imgStyle={{ width: "4rem", margin: "-1rem 0px 1.5rem" }}
        hideDeny={true}
        // element
        approveText={<FormattedMessage id="common.ok" />}
        // callback
        onApprove={handleCloseError}
      />
    </div>
  );
};

/* ------------------------------------------------------ */

/*                        CardItem                        */

/* ------------------------------------------------------ */

// Types
type CardItemType = {
  data: any;
  disabled?: boolean;
  checked?: boolean;
  // callback
  onSelect?: (data: any) => any;
};

const CardItem = (props: CardItemType) => {
  const classes = useStyles();

  const handleClick = () => {
    props.onSelect?.(props.data);
  };

  return (
    <Card
      className={`${classes.card}${props.disabled ? " --grey" : ""}`}
      onClick={handleClick}
    >
      <CardActionArea disabled={props.disabled}>
        <CardContent>
          <div>
            <Radio
              className={`smart-radio-button${props.disabled ? " --grey" : ""}`}
              checked={props.checked}
              name="radio-buttons"
            />
          </div>
          <div style={{ margin: "0.15rem 0 0 0.25rem" }}>{props.data.name}</div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(SelectHospitalBindHN);
