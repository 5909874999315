import React, { useState, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";

// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory, useLocation } from "react-router";

// UX
import BottomSheetSelectBox from "./BottomSheetSelectBox";
import { titleStyles, dropdownStyles, callServiceNumber } from "./CancelAppointment";

// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// telephar
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Const.
import { CANCEL_REASON_OPTIONS } from "./Constants";
import ModBookingUnSuccess from "bplus-lib/appointment/ModBookingUnSuccess";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";
import moment, { ISO_8601 } from "moment";
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";

// Images
const IMAGES = {
  check: "/images/register/check-green.png",
  request_app: "/images/Appointment/request-appointment.png",
};

// Styles
const COLORS = {
  normal: "rgba(121, 120, 120, 1)",
  grey: "rgba(57, 57, 57, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
};

type SRRLocationType = {
  cancelAppointment?: Record<string, any>;
  invoice?: Record<string, any>;
  hospital?: Record<string, any>;
  requestType?: "REALTIME" | "CRM";
  storedState?: {
    cancelReason?: Record<string, any>;
  };
};

type SubmitRequestRefundProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
} & Pick<
  State,
  | "allPaidInvoiceList"
  | "loadingStatus"
  | "successMessage"
  | "errorMessage"
  | "myProfileDetail"
>;

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "24px 16px 16px",
    display: "flex",
    flexDirection: "column",
    color: COLORS.normal,
    minHeight: "calc(100vh - 50px)",
  },
  dropdown: dropdownStyles,
  title: titleStyles,
}));

const REQUEST_REFUND = "RequestRefund";
const ACTION_REQUEST = `${REQUEST_REFUND}_REQUEST`;

const params = new URLSearchParams(window.location.search);
const app = params.get("app");
const MOB_APP = `app=${app}`;

const SubmitRequestRefund = (props: SubmitRequestRefundProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<SRRLocationType>();

  const [selectedReason, setSelectedReason] = useState<any>(null);
  // open mod
  const [openModRefundReason, setOpenModRefundReason] =
    useState<boolean>(false);
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);

  // Memo
  const reasonOptions = useMemo(() => {
    return CANCEL_REASON_OPTIONS.map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, []);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const errorMessage = useMemo(() => {
    return props.errorMessage?.[ACTION_REQUEST];
  }, [props.errorMessage]);

  // Handler
  const handleOpenModCancelReason = () => {
    setOpenModRefundReason(true);
  };

  const handleGoback = () => {
    history.goBack();
  };

  const handleSelectReason = (selected: Record<string, any>) => {
    setSelectedReason(selected);
    setOpenModRefundReason(false);
  };

  const handleConfirm = () => {
    const appointment = locState.cancelAppointment || {};

    props.onEvent({
      message: "CancelAppointment",
      params: {
        card: ACTION_REQUEST,
        appointment_id: appointment.id,
        doctor_code: appointment.doctor_details?.code,
        reason: selectedReason.value,
        date: moment(appointment.date,ISO_8601).format("YYYY-MM-DD"),
        time: appointment.time,
        hospital_code: locState.hospital?.code,
        location_code: appointment.division_code,
        patient: appointment.patient,
        total: locState?.invoice?.receipt?.price,
        invoice_ref: locState?.invoice?.receipt?.code,
        type: locState.requestType === "REALTIME" ? "REALTIME" : "CRM"
      },
    });
  };

  // const handleCloseModSuccess = () => {
  //   setOpenModSuccess(false);

  //   // -history.go(-2);
  // };

  const handleClearErrorMessage = async () => {
    const { [ACTION_REQUEST]: ar, ...error } = props.errorMessage || {};
    await props.setProp(`errorMessage`, { ...error });
  };

  const handleCallingService = () => {
    if (globalThis.mobile?.getCallingService) {
      globalThis.mobile?.getCallingService(callServiceNumber);
    }
  };

  const handleCloseModCancelSuccess = async () => {
    setOpenModRefundReason(false);
    const { [ACTION_REQUEST]: c, ...success } = props.successMessage || {};

    await props.setProp(`successMessage`, { ...success });
    await props.setProp(`myAppointmentList`, null);
    await props.setProp(`cancelAppointmentList`, null);

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: "/",
        search: `?${MOB_APP}&tab=cancel`,
      },
    });
  };

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="ส่งคำร้องเพื่อติดต่อเจ้าหน้าที่"
        whiteTheme={true}
        // config
        hiddenLeft={true}
        // callback
        rightIconClick={handleGoback}
      />

      <div className={classes.screen}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>คำขอคืนเงิน</div>

          <div>
            คำขอคืนเงินจะถูกพิจารณาจากทางโรงพยาบาลถ้าอนุมัติ
            นัดหมายของท่านจะถูกยกเลิกอัตโนมัติ ระบบจะคืนเงิน ให้ท่านเต็มจำนวน
            ถ้าปฏิเสธนัดหมายของท่านถูกยกเลิก อัตโนมัติและไม่สามารถคืนเงินได้
          </div>

          <div className={classes.dropdown}>
            <label className="required">เหตุผลในการขอคืนเงิน</label>

            <div aria-hidden="true" onClick={handleOpenModCancelReason}>
              <Dropdown
                selection={true}
                fluid={true}
                icon="chevron down"
                placeholder="ระบุเหตุผล"
                value={selectedReason?.id || ""}
                options={reasonOptions}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!selectedReason}
              onClick={handleConfirm}
            >
              <>
                ส่งคำขอคืนเงิน
                {props.loadingStatus?.[ACTION_REQUEST] && (
                  <CircularProgress
                    style={{
                      marginLeft: "0.5rem",
                    }}
                    size={20}
                  />
                )}
              </>
            </MuiButton>
          </div>
        </div>
      </div>

      <BottomSheetSelectBox
        open={openModRefundReason}
        title="เหตุผลในการขอคืนเงิน"
        options={CANCEL_REASON_OPTIONS}
        selected={selectedReason}
        // config
        confirmSelect={true}
        // callback
        onChange={setOpenModRefundReason}
        onSelect={handleSelectReason}
      />

      <DialogConfirm
        open={props.successMessage?.[ACTION_REQUEST]}
        title={"ยกเลิกการนัดหมายสำเร็จ"}
        description={"ท่านทำการส่งคำขอคืนเงิน\nเสร็จเรียบร้อยแล้ว"}
        approveText="เสร็จสิ้น"
        img={IMAGES.check}
        imgStyle={{ width: "4rem", margin: "-0.75rem 0 1.25rem" }}
        // config
        hideDeny={true}
        onApprove={handleCloseModCancelSuccess}
      >
        <>
          <div style={{ whiteSpace: "pre-line", color: "#797878" }}>
            {
              "โรงพยาบาลจะดำเนินการคืนเงินตามช่องทาง \nที่ท่านชำระเงิน ระยะเวลาในการคืนเงินภายใน \n7-45 วันทำการ \nหากคุณมีข้อสงสัยเกี่ยวกับการทำรายการนี้ \n"
            }
          </div>
          <div
            style={{
              display: "flex",
              color: "#797878",
              whiteSpace: "nowrap",
              justifyContent: "center",
            }}
          >
            {
              <span
                className="highlight"
                onClick={() => handleCallingService()}
                style={{
                  textDecorationLine: "underline",
                }}
              >
                {"โปรดติดต่อ"}
              </span>
            }
            {"ฝ่ายการเงินโรงพยาบาลกรุงเทพ"}
          </div>
        </>
      </DialogConfirm>

      {/* <ModBookingUnSuccess
        open={!!errorMessage}
        onClick={handleClearErrorMessage}
      /> */}

      <DialogConfirm
        open={!!errorMessage}
        title={"ไม่สามารถยกเลิกนัดหมายได้"}
        approveText="ปิด"
        img={IMAGES.request_app}
        imgSize="12.5rem"
        // config
        hideDeny={true}
        onApprove={handleClearErrorMessage}
      >
        <>
        {"ไม่สามารถยกเลิกนัดหมายได้"}
        </>
        {/* <>
          <PaymentAlertMessage
            type="info-red"
            style={{ marginTop: "1rem", padding: "13px 16px" }}
          >
            <>{props.errorMessage?.[ACTION_REQUEST]}</>
          </PaymentAlertMessage>
        </> */}
      </DialogConfirm>
    </>
  );
};

export default React.memo(SubmitRequestRefund);
