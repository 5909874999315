import React, {
  useEffect,
  useMemo,
  ChangeEvent,
  useRef,
  MutableRefObject,
} from "react";
import {
  Button as MuiButton,
  makeStyles,
  TextField,
  // InputLabel,
  Select,
  AppBar,
} from "@material-ui/core";
import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { browserName, browserVersion } from "react-device-detect";
import {
  Form,
  Dropdown,
  Dimmer,
  Loader,
  DropdownProps,
} from "semantic-ui-react";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { ModInfo, ErrorMessage } from "react-lib/apps/common";
import MobHeadApp from "../appointment/MobHeadApp";
import TopbarModal from "../appointment/TopbarModal";

import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

// Event
import { Event as MobEvent } from "../MobCouponInterface";

// CSS
import "./MyCoupon.scss";
import "./CreateNewPatient.scss";

type CreateNewPatientProps = {
  onEvent: (e: MobEvent) => any;
  prenameList?: any[];
  nationalityList?: any[];
  loadingNewPatient?: boolean;
  createPatientInfo?: any;
  history: any;
  language?: "th-TH" | "en-US";
  openModCreateNewPatient?: {
    open: boolean;
    error: any;
  };
  showTopbar?: boolean;
} & WrappedComponentProps;

export const COLOR = {
  blue: "var(--blue-bdms-color)",
  dark_grey: "#9F9F9F",
  light_grey: "#d7d7d7",
  red: "rgb(244, 67, 54)",
};

const IMAGE = {
  bg: "/images/Appointment/bg-coupon-profile.png",
};

const UNDERLINE = {
  "& .MuiInput-underline:before": {
    borderBottom: `1px solid ${COLOR.light_grey}`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: browserName.includes("Safari")
      ? `1px solid ${COLOR.light_grey}`
      : "",
  },
};

const useStyles = makeStyles((theme) => ({
  topbar: {
    color: COLOR.blue,
    fontSize: "24px",
    display: "flex",
    flexDirection: "column",
    fontWeight: "bold",
    alignItems: "center",
    marginTop: "2.5vh",
    "@media (max-width: 345px)": {
      fontSize: "22px",
    },
  },
  topbar_content: {
    textAlign: "center",
    padding: "8px",
    lineHeight: "24px",
  },
  label: {
    color: COLOR.dark_grey,
    fontSize: "16px",
    marginBottom: "4px",
    marginTop: "10px",
  },
  underline: UNDERLINE,
  box_form: {
    marginTop: "40px",
    "& label::after": {
      content: "*",
      color: "red",
    },
    "& .error": {
      "& label, .MuiSelect-icon": {
        color: COLOR.red,
      },
      "& .MuiInput-underline:before,.MuiInput-underline:after": {
        borderBottom: `1px solid ${COLOR.red}`,
      },
    },
  },
  button: {
    width: "215px",
    margin: "15px 0",
    fontSize: "16px",
    fontWeight: "bold",
    color: "white",
    height: "50px",
    textTransform: "unset",
    backgroundColor: COLOR.blue,
    "&:hover": {
      backgroundColor: COLOR.blue,
    },
    borderRadius: "50rem",
    padding: "10px 0",
    "&.btn-outline": {
      border: `2px solid ${COLOR.blue}`,
      color: COLOR.blue,
      backgroundColor: "transparent",
      "&:hover": {
        border: `2px solid ${COLOR.blue}`,
        backgroundColor: "transparent",
      },
      marginTop: 0,
    },
  },
  dropdown: {
    marginTop: "2px",
    "& .MuiInput-root": {
      marginTop: "2px",
    },
    "& .MuiInputBase-input:focus": {
      background: "transparent",
    },
    "& .MuiSelect-select.MuiSelect-select": {
      fontSize: "16px",
    },
    ...UNDERLINE,
  },
  datepicker: {
    "&.placeholder": {
      "& input": {
        color: "rgba(0, 0, 0, 0.4)",
      },
    },
    "& input": {
      fontSize: "16px",
    },
    ...UNDERLINE,
  },
  box_create_patient: {
    padding: "12px 15px",
    height: "100%",
    backgroundImage: `url(${IMAGE.bg})`,
    backgroundPosition: "50% 0",
    backgroundSize: "cover",
    backgroundColor: "white",
  },
  text_header: {
    fontSize: "24px",
    "@media (max-width: 345px)": {
      fontSize: "20px",
    },
  },
}));

const CreateNewPatient: React.FunctionComponent<CreateNewPatientProps> = (
  props
) => {
  const classes = useStyles();

  const screenRef = useRef() as MutableRefObject<HTMLDivElement>;
  const appBarRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    props.onEvent({ message: "GetMasterDataNewPatient", params: {} });
  }, []);

  useEffect(() => {
    const bodyRef = screenRef.current;

    if (!bodyRef) {
      return;
    }

    bodyRef.onscroll = async () => {
      // set topbar boxShadow on scroll
      if (appBarRef.current && props.showTopbar) {
        appBarRef.current.style.boxShadow =
          bodyRef.scrollTop > 0 ? "0 1px 4px 0 rgb(0,0,0,0.12)" : "none";
      }
    };
  }, []);

  const prenameOptions = useMemo(() => {
    return props.prenameList?.map((item: any, index: number) => ({
      key: index,
      value: item.id,
      text: item.name,
    }));
  }, [props.prenameList]);

  const nationalityOptions = useMemo(() => {
    return props.nationalityList?.map((item: any, index: number) => ({
      key: index,
      value: item.name,
      text: item.name,
    }));
  }, [props.nationalityList]);

  const handleInputChange = (key: string, value: any) => {
    props.onEvent({
      message: "HandleSetValueCreatePatient",
      params: { key, value },
    });
  };

  const handleCloseModInfo = () => {
    props.onEvent({ message: "HandleCloseModCreateNewPatient", params: {} });
  };

  return (
    <div>
      <Dimmer
        active={props.loadingNewPatient}
        inverted
        style={{ position: "fixed" }}
      >
        <Loader inverted><FormattedMessage id="bplusClinicKey297" /></Loader>
      </Dimmer>

      <div
        ref={screenRef}
        className={classes.box_create_patient}
        style={{
          height: props.showTopbar
            ? `calc(100vh - ${appBarRef.current?.offsetHeight || 0}px)`
            : "100vh",
          overflow: "auto",
          minHeight: props.showTopbar ? "" : "100vh",
        }}
      >
        {props.showTopbar && (
          <TopbarModal
            headerText={props.intl.formatMessage({
              id: "e_coupon.vaccine_recipent_profile",
            })}
            showUnderline={true}
            headerClassName={classes.text_header}
            screenElement={screenRef.current}
            showBackIcon={true}
            hideCloseIcon={true}
            leftIconClick={() => props.history.goBack()}
          />
        )}

        {!props.showTopbar && (
          <div
            className={classes.topbar}
            style={{ marginTop: props.showTopbar ? "0.5vh" : "2.5vh" }}
          >
            <div className={classes.topbar_content}>
              <FormattedMessage id="e_coupon.vaccine_recipent_profile" />
            </div>
            <span
              style={{ borderBottom: "3px solid red", width: "217px" }}
            ></span>
          </div>
        )}
        <form className={classes.box_form}>
          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.title_name" />
            </label>
            <FormControl variant="standard" className={classes.dropdown}>
              <InputLabel></InputLabel>
              <Select
                value={props.createPatientInfo.pre_name}
                onChange={(e: any) =>
                  handleInputChange("pre_name", e.target.value)
                }
              >
                {prenameOptions?.map((item: any) => (
                  <MenuItem color="primary" value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.firstname" />
            </label>
            <TextField
              // error={inputError.orderNo}
              placeholder=""
              label=""
              className={classes.underline}
              inputProps={{ style: { fontSize: 16, color: "rgba(0,0,0,.87)" } }}
              value={props.createPatientInfo.first_name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("first_name", e.target.value)
              }
              // focused={browserName.includes("Safari")}
            />
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.lastname" />
            </label>
            <TextField
              // error={inputError.orderNo}
              placeholder=""
              label=""
              className={classes.underline}
              inputProps={{ style: { fontSize: 16, color: "rgba(0,0,0,.87)" } }}
              value={props.createPatientInfo.last_name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("last_name", e.target.value)
              }
              // focused={browserName.includes("Safari")}
            />
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.date_of_birth" />
            </label>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={props.language === "th-TH" ? "th" : "en"}
            >
              <DatePicker
                className={`${classes.datepicker}${
                  props.createPatientInfo.birthdate ? "" : " placeholder"
                }`}
                format="DD MMM yyyy"
                emptyLabel={
                  props.language === "th-TH"
                    ? "กรุณาระบุวันเกิด"
                    : "Please specify date of birth"
                }
                maxDate={moment()}
                value={props.createPatientInfo.birthdate || null}
                onChange={(mo) => {
                  handleInputChange("birthdate", mo?.toISOString());
                }}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.gender" />
            </label>
            <FormControl variant="standard" className={classes.dropdown}>
              <InputLabel></InputLabel>
              <Select
                value={props.createPatientInfo.gender}
                onChange={(e: any) =>
                  handleInputChange("gender", e.target.value)
                }
              >
                {[
                  {
                    key: "M",
                    value: "M",
                    text: props.intl?.formatMessage({
                      id: "common.male",
                    }),
                  },
                  {
                    key: "F",
                    value: "F",
                    text: props.intl?.formatMessage({
                      id: "common.female",
                    }),
                  },
                ]?.map((item: any) => (
                  <MenuItem value={item.value}>{item.text}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.phone_no" />
            </label>
            <TextField
              // error={inputError.orderNo}
              placeholder=""
              label=""
              className={classes.underline}
              inputProps={{ style: { fontSize: 16, color: "rgba(0,0,0,.87)" } }}
              type="number"
              value={props.createPatientInfo.phone_no}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("phone_no", e.target.value)
              }
              // focused={browserName.includes("Safari")}
            />
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.thainationalid_passportno" />
            </label>
            <TextField
              // error={inputError.orderNo}
              placeholder=""
              label=""
              className={classes.underline}
              inputProps={{ style: { fontSize: 16, color: "rgba(0,0,0,.87)" } }}
              value={props.createPatientInfo.cid}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChange("cid", e.target.value)
              }
              // focused={browserName.includes("Safari")}
            />
          </div>

          <div style={{ display: "grid" }}>
            <label className={`${classes.label} label-required`}>
              <FormattedMessage id="profile.nationality" />
            </label>
            <FormControl variant="standard" className={classes.dropdown}>
              <InputLabel></InputLabel>
              <Select
                value={props.createPatientInfo.nationality}
                onChange={(e: any) =>
                  handleInputChange("nationality", e.target.value)
                }
              >
                {nationalityOptions?.map((item: any) => (
                  <MenuItem value={item.value}>{item.text}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2.5vh",
          }}
        >
          <MuiButton
            className={classes.button}
            variant="contained"
            onClick={() =>
              props.onEvent({
                message: "HandleCreateNewPatient",
                params: {
                  data: props.createPatientInfo,
                  history: props.history,
                },
              })
            }
          >
            <FormattedMessage id="common.confirm" />
          </MuiButton>
          <MuiButton
            className={`${classes.button} btn-outline`}
            variant="contained"
            onClick={() => props.history.goBack()}
          >
            <FormattedMessage id="common.cancel" />
          </MuiButton>
        </div>
        <ModInfo
          open={props.openModCreateNewPatient?.open}
          titleColor={"red"}
          className="mod-error-custom"
          onApprove={handleCloseModInfo}
          onClose={handleCloseModInfo}
        >
          <ErrorMessage error={props.openModCreateNewPatient?.error} />
        </ModInfo>
      </div>
    </div>
  );
};

CreateNewPatient.defaultProps = {
  onEvent: (e: MobEvent) => {},
  prenameList: [],
  nationalityList: [],
  loadingNewPatient: false,
  createPatientInfo: {},
  history: {},
  openModCreateNewPatient: {
    open: false,
    error: null,
  },
  showTopbar: false,
} as Omit<CreateNewPatientProps, "intl">;

export default React.memo(injectIntl(CreateNewPatient));
