import React, { useState, useEffect, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import { CSSProperties } from "@mui/styles";

import { useHistory, useLocation } from "react-router";
import moment, { ISO_8601 } from "moment";
import ModBookingUnSuccess from "bplus-lib/appointment/ModBookingUnSuccess";

// UX
import BottomSheetSelectBox from "./BottomSheetSelectBox";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";
// telephar
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";
import PaymentAlertMessage from "bplus-lib/telephar/PaymentAlertMessage";

// Interface
import { State, Event } from "../MobSmartAppointmentInterface";

// Const.
import { CANCEL_REASON_OPTIONS, URLS } from "./Constants";

// Types
type CancelAppointmentProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
} & Pick<
  State,
  "myProfileDetail" | "successMessage" | "loadingStatus" | "errorMessage"
>;

type CancelType =
  // | "refundable"
  | "hospital-visit-none-refundable"
  | "hospital-visit-refundable"
  | "teleconsult-refundable"
  | "teleconsult-none-refundable"
  // | "teleconsult-hospital-visit"
  | "";

type CALocationType = {
  appointmentLocation: "online" | "on-site";
  requestType?: "REALTIME" | "CRM";
  cancelAppointment?: Record<string, any>;
  hospital?: Record<string, any>;
  isLessTime?: boolean;
  invoice?: Record<string, any>;
  storedState?: {
    cancelReason?: Record<string, any>;
  };
};

// Images
const IMAGES = {
  check: "/images/register/check-green.png",
  request_app: "/images/Appointment/request-appointment.png",
  refund: "/images/Appointment/refund-outline.png",
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  primary: "var(--blue-bdms-color)",
  very_light_grey: "rgba(233, 233, 233, 1)",
  icon: "rgba(159, 159, 159, 1)",
  normal: "rgba(121, 120, 120, 1)",
  light_grey: "rgba(196, 196, 196, 1)",
  red: "rgba(218, 0, 0, 1)",
};

const summaryStyles = {
  color: COLORS.normal,
  marginTop: "3rem",
  "& .title": {
    color: COLORS.grey,
    fontSize: "1rem",
    fontWeight: "bold",
  },
  "& .total": {
    color: COLORS.normal,
  },
  "& .bold": {
    fontWeight: "bold",
    color: COLORS.grey,
  },
  "& .price": {
    fontWeight: "bold",
    color: COLORS.primary,
    fontSize: "1.125rem",
  },
  "& > div": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export const dropdownStyles = {
  marginTop: "2.5rem",
  "& > label": {
    color: COLORS.grey,
  },
  "& .required:after": {
    content: "' * '",
    color: "red",
  },
  "& .ui.selection.dropdown": {
    padding: "14px 16px 15px 15px",
    borderRadius: "8px",
    // color: COLORS.light_grey,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "0.75rem",
    opacity: 1,
    pointerEvents: "none",
    width: "100%",
    "& i": {
      color: COLORS.icon,
    },
  },
};

export const titleStyles = {
  fontSize: "1.25rem",
  color: COLORS.grey,
  fontWeight: "bold",
  marginBottom: "1rem",
} as CSSProperties;

const submitRequestStyles = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  color: COLORS.primary,
  textDecorationLine: "underline",
  fontSize: "1.125rem",
  marginTop: "1.5rem",
  "& img": {
    width: "24px",
    marginRight: "0.5rem",
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "24px 16px 16px",
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 50px)",
  },
  title: titleStyles,
  underline_title: {
    fontSize: "1.125rem",
    color: COLORS.primary,
    textDecorationLine: "underline",
    marginBottom: "1rem",
  },
  summary: summaryStyles,
  divider: {
    borderBottom: `1px solid ${COLORS.very_light_grey}`,
    margin: "1rem 0",
  },
  dropdown: dropdownStyles,
  submit_request: submitRequestStyles,
}));

// Const
const BUTTON_ACTIONS = {
  waiting: "_WAITING",
  realTime: "_REALTIME",
};

const params = new URLSearchParams(window.location.search);
const app = params.get("app");
const MOB_APP = `app=${app}`;
export const callServiceNumber = "02-310000"

const CANCEL_APPOINTMENT = "CancelAppointment";
const ACTION_REALTIME = `${CANCEL_APPOINTMENT}${BUTTON_ACTIONS.realTime}`;
const ACTION_WAITING = `${CANCEL_APPOINTMENT}${BUTTON_ACTIONS.waiting}`;

const CancelAppointment = (props: CancelAppointmentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<CALocationType>();

  // open mod
  const [openModCancelReason, setOpenModCancelReason] =
    useState<boolean>(false);
  const [openModReadMore, setOpenModReadMore] = useState<boolean>(false);

  const [cancelType, setCancelType] = useState<CancelType>("");
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [isLessTime, setIsLessTime] = useState(false);
  const [openModSuccessCancel, setOpenModSuccessCancel] =
    useState<boolean>(false);

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  useEffect(() => {
    // const date = moment(locState.cancelAppointment?.date);
    // let isAlmostIme = false;

    // if (date.isValid()) {
    //   const diff = moment().diff(moment(date), "minutes");
    //   isAlmostIme = diff > -30;
    // }

    const conditions = {
      "true-true": "teleconsult-none-refundable",
      "true-false": "teleconsult",
      "false-true": "hospital-visit-none-refundable",
      "false-false": "hospital-visit",
    } as any;

    const conditionKey = `${locState.appointmentLocation === "online"}-${
      locState.isLessTime
    }`;

    setIsLessTime(locState.isLessTime || false);
    setCancelType(conditions[conditionKey]);
  }, [
    locState.appointmentLocation,
    locState.cancelAppointment,
    locState.isLessTime,
  ]);

  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      setSelectedReason(storedState.cancelReason);
    }
  }, [locState.storedState]);

  // Memo
  const reasonOptions = useMemo(() => {
    return CANCEL_REASON_OPTIONS.map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, []);

  // Handler
  const handleOpenModCancelReason = () => {
    setOpenModCancelReason(true);
  };

  const handleCloseModReadMore = () => {
    setOpenModReadMore(false);
  };

  const handleOpenModReadMore = () => {
    setOpenModReadMore(true);
  };

  const handleGoback = () => {
    history.goBack();
  };

  const handleSelectReason = (selected: Record<string, any>) => {
    setSelectedReason(selected);
    setOpenModCancelReason(false);
  };

  const handleClearErrorMessage = async () => {
    const { [locState.requestType !== "REALTIME"
    ? ACTION_WAITING
    : ACTION_REALTIME]: ar, ...error } = props.errorMessage || {};
    await props.setProp(`errorMessage`, { ...error });
  };

  const errorMessage = useMemo(() => {
    return props.errorMessage?.[locState.requestType !== "REALTIME"
    ? ACTION_WAITING
    : ACTION_REALTIME];
  }, [props.errorMessage]);

  const isRealtime = useMemo(()=>{
    return locState.requestType === "REALTIME"
  },[locState])

  const handleConfirmCancel = () => {

    const appointment = locState.cancelAppointment || {};

    props.onEvent({
      message: "CancelAppointment",
      params: {
        card: isRealtime ? ACTION_REALTIME : ACTION_WAITING,
        appointment_id: appointment.id,
        doctor_code: appointment.doctor_details?.code,
        reason: selectedReason.value,
        date: moment(appointment.date,ISO_8601).format("YYYY-MM-DD"),
        time: appointment.time,
        hospital_code: locState.hospital?.code,
        location_code: appointment.division_code,
        patient: appointment.patient,
        type: isRealtime ? "REALTIME" : "CRM"
      },
    });


    // if (locState.requestType === "REALTIME") {
    //   const appointment = locState.cancelAppointment || {};

    //   props.onEvent({
    //     message: "CancelAppointment",
    //     params: {
    //       card: ACTION_REALTIME,
    //       appointment_id: appointment.id,
    //       doctor_code: appointment.doctor_details?.code,
    //       reason: selectedReason.value,
    //       date: moment().format("YYYY-MM-DD"),
    //       time: appointment.time,
    //       hospital_code: locState.hospital?.code,
    //       location_code: appointment.division_code,
    //       patient: appointment.patient,
    //       type: "REALTIME"
    //     },
    //   });
    // } else {
    //   const appointment = locState.cancelAppointment || {};
    //   props.onEvent({
    //     message: "CancelAppointment",
    //     params: {
    //       card: ACTION_WAITING,
    //       appointment_id: appointment.id,
    //       doctor_code: appointment.doctor_details?.code,
    //       reason: selectedReason.value,
    //       date: moment().format("YYYY-MM-DD"),
    //       time: appointment.time,
    //       hospital_code: locState.hospital?.code,
    //       location_code: appointment.division_code,
    //       patient: appointment.patient,
    //       type: "CRM"
    //     },
    //   });
    // }
  };

  const handleCloseModCancelSuccess = async () => {
    setOpenModSuccessCancel(false);
    const { [ACTION_REALTIME]: c, ...success } = props.successMessage || {};

    await props.setProp(`successMessage`, { ...success });
    await props.setProp(`myAppointmentList`, null);
    await props.setProp(`cancelAppointmentList`, null);

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: "/",
        search: `?${MOB_APP}&tab=cancel`,
      },
    });

    // history.goBack();
  };

  const handleCloseModWaitingSuccess = async () => {
    const { [ACTION_WAITING]: c, ...success } = props.successMessage || {};

    await props.setProp(`successMessage`, { ...success });
    await props.setProp(`myAppointmentList`, null);
    await props.setProp(`cancelAppointmentList`, null);

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: "/",
        search: `?${MOB_APP}&tab=cancel`,
      },
    });

    // history.goBack();
  };

  const handleSubmitRefund = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: URLS.SUBMIT_REQUEST,
        state: {},
      },
    });
  };

  const handleCallingService = () => {
    if (globalThis.mobile?.getCallingService) {
      globalThis.mobile?.getCallingService(callServiceNumber);
    }
  };

  console.log(cancelType);

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        setTitle="ยกเลิกนัดหมายแพทย์"
        whiteTheme={true}
        // config
        hiddenLeft={true}
        // callback
        rightIconClick={handleGoback}
      />

      <div className={classes.screen}>
        <div style={{ flexGrow: 1 }}>
          <div
            className={classes.title}
            style={{
              color: cancelType.includes("none-refundable") ? COLORS.red : "",
            }}
          >
            {cancelType.includes("none-refundable") &&
            isLessTime &&
            cancelType.includes("teleconsult")
              ? "การยกเลิกไม่สามารถคืนเงินได้"
              : cancelType.includes("hospital-visit")
              ? "ยกเลิกนัดหมาย"
              : "ยกเลิกนัดหมายแพทย์"}
          </div>
          <div
            aria-hidden="true"
            className={classes.underline_title}
            onClick={handleOpenModReadMore}
          >
            รายละเอียดนโยบายการยกเลิก
          </div>

          {cancelType.includes("none-refundable") &&
            !cancelType.includes("hospital-visit") && (
              <PaymentAlertMessage
                type="info-blue"
                style={{ marginBottom: "-1.5rem" }}
              >
                ถ้าท่านไม่มาใช้บริการตามนัดหมายหรือยกเลิก นัดหมาย
                จะไม่สามารถคืนเงินได้
              </PaymentAlertMessage>
            )}

          {!cancelType.includes("hospital-visit") && (
            <div className={classes.summary}>
              <div className="title">ข้อมูลการคืนเงิน</div>
              <div className={classes.divider}></div>

              <div>
                <div>ค่าพบแพทย์</div>{" "}
                <div className="bold">{`${
                  locState.invoice?.receipt?.price || "-"
                } บาท`}</div>
              </div>
              <div className={classes.divider}></div>

              <div className="total">
                <div>รวมยอดเงินคืน</div>
                <div
                  className="price"
                  style={{
                    color: cancelType.includes("none-refundable")
                      ? COLORS.red
                      : "",
                  }}
                >
                  {cancelType.includes("none-refundable")
                    ? "ไม่สามารถคืนเงินได้"
                    : `${locState.invoice?.receipt?.price || "-"} บาท`}
                </div>
              </div>
            </div>
          )}

          <div className={classes.dropdown}>
            <label>เหตุผลในการยกเลิก</label>

            <div aria-hidden="true" onClick={handleOpenModCancelReason}>
              <Dropdown
                selection={true}
                fluid={true}
                icon="chevron down"
                placeholder="ระบุเหตุผล"
                value={selectedReason?.id || ""}
                options={reasonOptions}
              />
            </div>
          </div>

          {cancelType.includes("none-refundable") &&
            !cancelType.includes("hospital-visit") && (
              <div
                aria-hidden="true"
                className={classes.submit_request}
                onClick={handleSubmitRefund}
              >
                <img src={IMAGES.refund} />
                <label>ส่งคำร้องเพื่อติดต่อเจ้าหน้าที่</label>
              </div>
            )}

          {locState.requestType !== "REALTIME" && (
            <PaymentAlertMessage
              type="info-yellow"
              style={{ marginTop: "1rem" }}
            >
              การขอยกเลิกนัดหมายต้องรอการยกเลิกนัดหมายจากโรงพยาบาล
            </PaymentAlertMessage>
          )}
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={
                props.loadingStatus?.[
                  locState.requestType !== "REALTIME"
                    ? ACTION_WAITING
                    : ACTION_REALTIME
                ] || !selectedReason
              }
              onClick={handleConfirmCancel}
            >
              {locState.requestType !== "REALTIME"
                ? "ส่งคำขอยกเลิก"
                : "ดำเนินการยกเลิก"}
              {props.loadingStatus?.[
                locState.requestType !== "REALTIME"
                  ? ACTION_WAITING
                  : ACTION_REALTIME
              ] && (
                <CircularProgress
                  style={{
                    marginLeft: "0.5rem",
                  }}
                  size={20}
                />
              )}
            </MuiButton>
          </div>
        </div>
      </div>

      <BottomSheetSelectBox
        open={openModCancelReason}
        title="เหตุผลในการยกเลิก"
        options={CANCEL_REASON_OPTIONS}
        selected={selectedReason}
        // config
        confirmSelect={true}
        // callback
        onChange={setOpenModCancelReason}
        onSelect={handleSelectReason}
      />

      <DialogReadMore
        open={openModReadMore}
        title="นโยบายการยกเลิกการนัดหมายแพทย์"
        onChange={handleCloseModReadMore}
      >
        <>
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
          <div className="title">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </div>
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of{" "}
          </div>
        </>
      </DialogReadMore>

      <DialogConfirm
        open={props.successMessage?.[ACTION_REALTIME]}
        title={"ยกเลิกการนัดหมายสำเร็จ"}
        description={
          cancelType.includes("none-refundable")
            ? ""
            : "ท่านทำการยกเลิกการนัดหมาย\nเสร็จเรียบร้อยแล้ว"
        }
        approveText="เสร็จสิ้น"
        img={IMAGES.check}
        imgStyle={{ width: "4rem", margin: "-0.75rem 0 1.25rem" }}
        // config
        hideDeny={true}
        onApprove={handleCloseModCancelSuccess}
      >
        {cancelType.includes("none-refundable") &&
        !cancelType.includes("hospital-visit") ? (
          <PaymentAlertMessage
            type="info-red"
            style={{ padding: "5px 16px", marginTop: "0.75rem" }}
          >
            ยกเลิกนัดหมายนี้ไม่สามารถคืนเงินได้
          </PaymentAlertMessage>
        ) : cancelType.includes("hospital-visit") ? (
          <div style={{ whiteSpace: "pre-line", color: "#797878" }}>
            {"ท่านทำการยกเลิกการนัดหมาย \nเสร็จเรียบร้อยแล้ว"}
          </div>
        ) : (
          <>
            <div style={{ whiteSpace: "pre-line", color: "#797878" }}>
              {
                "โรงพยาบาลจะดำเนินการคืนเงินตามช่องทาง \nที่ท่านชำระเงิน ระยะเวลาในการคืนเงินภายใน \n7-45 วันทำการ \nหากคุณมีข้อสงสัยเกี่ยวกับการทำรายการนี้ \n"
              }
            </div>
            <div
              style={{
                display: "flex",
                color: "#797878",
                whiteSpace: "nowrap",
                justifyContent: "center",
              }}
            >
              {
                <span
                  className="highlight"
                  onClick={() => handleCallingService()}
                  style={{
                    textDecorationLine: "underline",
                  }}
                >
                  {"โปรดติดต่อ"}
                </span>
              }
              {"ฝ่ายการเงินโรงพยาบาลกรุงเทพ"}
            </div>
          </>
        )}
      </DialogConfirm>

      <DialogConfirm
        open={props.successMessage?.[ACTION_WAITING]}
        title={"รอการยกเลิก\nนัดหมายจากโรงพยาบาล"}
        description={
          cancelType.includes("none-refundable")
            ? ""
            : "ท่านทำการรอการยกเลิกการนัดหมาย\nเสร็จเรียบร้อยแล้ว"
        }
        approveText="เสร็จสิ้น"
        img={IMAGES.request_app}
        imgSize={"7.5rem"}
        // config
        hideDeny={true}
        onApprove={handleCloseModWaitingSuccess}
      >
        {cancelType.includes("none-refundable") ? (
          <PaymentAlertMessage
            type="info-red"
            style={{ padding: "5px 16px", marginTop: "0.75rem" }}
          >
            ยกเลิกนัดหมายนี้ไม่สามารถคืนเงินได้
          </PaymentAlertMessage>
        ) : null}
      </DialogConfirm>

      <DialogConfirm
        open={!!errorMessage}
        title={"ไม่สามารถยกเลิกนัดหมายได้"}
        approveText="ปิด"
        img={IMAGES.request_app}
        imgSize="12.5rem"
        // config
        hideDeny={true}
        onApprove={handleClearErrorMessage}
      >
        <>
        {"ไม่สามารถยกเลิกนัดหมายได้"}
          {/* <PaymentAlertMessage
            type="info-red"
            style={{ marginTop: "1rem", padding: "13px 16px" }}
          >
            <>{props.errorMessage?.[locState.requestType !== "REALTIME"
                  ? ACTION_WAITING
                  : ACTION_REALTIME]}</>
          </PaymentAlertMessage> */}
        </>
      </DialogConfirm>
    </>
  );
};

export default React.memo(CancelAppointment);
