import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Modal,
  Radio,
  TextArea,
  SemanticICONS,
} from "semantic-ui-react";
import {
  IntlProvider,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// UI
import PatientPanel from "./PatientPanel";
import {
  DateTextBox,
  ModConfirm,
  TimeTextBox,
} from "../../react-lib/apps/common";
import { DMDatePicker } from "./DMEmotionRestChart";

// Interface
import { convertToADDate } from "../MobClinicInterface";

import * as DrugDMConst from "./DMDrug";

// ---------- SubNormalDrug
type SubNormalDrugProps = {
  onEvent: any;
  onShowDrugDetail: any;
  onShowDrugDescription?: any;
  onShowHoldDrug: any;
  onShowDeleteDrug: any;
  title: string;
  icon: any;
  data: any;
  textField: string;
};

const DATE_FORMAT = "DD/MM/YYYY";

const SubNormalDrug: React.FC<SubNormalDrugProps> = (props) => {
  const intl = useIntl();
  const handleShowDrugDetail = (drug: any) => {
    props.onShowDrugDetail(drug);
  };

  const handleShowDrugDescription = (drug: any) => {
    props.onShowDrugDescription?.(drug);
  };
console.log("props.data",props.data)
  return (
    <Accordion>
      <AccordionSummary
        style={{ alignItems: "baseline" }}
        expandIcon={<ExpandMoreIcon color="primary" />}
      >
        <Header as="h4" style={{ flexBasis: "30%", marginBottom: 0 }}>
          {props.title}
        </Header>
        <div style={{ flexBasis: "60%" }}>
          <img height="31px" width="37px" src={props.icon} />
          {/* <Icon name={props.icon} color="yellow" size="big" /> */}
        </div>
        {props.data?.warning && (
          <Icon
            name="warning sign"
            color="red"
            size="large"
            style={{ flexBasis: "10%" }}
          />
        )}
      </AccordionSummary>

      {props.data?.items?.map((item: any, index: number) => {
        return (
          <AccordionDetails
            key={index}
            style={{ display: "block", padding: "0px", margin: "10px" }}
          >
            <Grid style={{ margin: "0px" }}>
              <Grid.Column
                width={3}
                style={{
                  ...DrugDMConst.styles.noPadding,
                  ...DrugDMConst.styles.textCenter,
                }}
                onClick={(event: object) => {
                  handleShowDrugDetail(item);
                }}
              >
                <Image src={item.image} />
              </Grid.Column>
              <Grid.Column width={13}>
                <Grid verticalAlign="middle">
                  <Grid.Column
                    width={11}
                    style={{
                      padding: "5px 0px 5px 5px",
                    }}
                    onClick={(event: object) => {
                      handleShowDrugDetail(item);
                    }}
                  >
                    <Header as="h4" style={DrugDMConst.styles.drugName}>
                      {item.drug_name}
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    width={5}
                    style={{
                      padding: "5px 0px",
                      textAlign: "right",
                    }}
                  >
                    <Header
                      as="h4"
                      style={{ color: DrugDMConst.COLOR_CODE.lightGrey }}
                    >
                      {item.dose} 
                      {
                        item.unit === "Tablet" ? `${intl.formatMessage({ id: "bplusClinicKey715" })}` : 
                        item.unit === "Unit" ? `${intl.formatMessage({ id: "bplusClinicKey503" })}` : 
                        item.unit === "mg" ? `${intl.formatMessage({ id: "bplusClinicKey573" })}` : item.unit
                      }
                    </Header>
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{
                      ...DrugDMConst.styles.noPadding,
                      textAlign: "right",
                      color: DrugDMConst.COLOR_CODE.lightGrey,
                    }}
                  >
                    {item.frequency}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>

            <Grid verticalAlign="middle" style={{ margin: "10px 0px" }}>
              <Grid.Row
                columns={4}
                style={{
                  ...DrugDMConst.styles.noPadding,
                  ...DrugDMConst.styles.textCenter,
                }}
              >
                <Grid.Column width={3} style={DrugDMConst.styles.noPadding}>
                  {/* Todo Numnim: ถ้าเลือก hold เฉพาะ today ในปุ่ม hold ของวันนี้ก้อจะแสดงปุ่มปุ่มที่ถูกกด แล้วตรงเวลาขึ้นคำว่า hold เฉพาะวันนี้ พอถึงวันพรุ่งนี้ ปุ่มก้อจะเด้งกลับมาเป็นปุ่มที่ไม่ถูกกดค่ะ until ก้อเป็นแบบเดียวกันค่า hold ไปจนถึงวันที่เลือก พอพ้นวันนั้นปุ่มก้อจะกลับมากดได้อีกครั้ง */}
                  <Button
                    fluid
                    toggle
                    active={item.status === "hold"}
                    color="red"
                    size="mini"
                    onClick={(event: object) => {
                      props.onShowHoldDrug({
                        meal: props.textField,
                        index: index,
                        drug: item,
                      });
                    }}
                  >
                    {item.status === "hold" ? "Cont" : "Hold"}
                  </Button>
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column width={7}>
                  <Button
                    fluid
                    color="blue"
                    size="mini"
                    disabled={item.status === "hold"}
                    style={{ width: "143px" }}
                    onClick={(event) => {
                      props.onEvent({
                        message: "handleConfirmNormalDrug",
                        params: {
                          meal: props.textField,
                          index: index,
                          drug: item,
                        },
                      });
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey119" />
                  </Button>
                </Grid.Column>
                <Grid.Column width={4}>
                  {item?.note_medication && (
                    <Image
                      src="/images/exercise/drug-description-icon.png"
                      style={{ margin: "0 -8px auto auto", width: "22px" }}
                      onClick={() => {
                        handleShowDrugDescription(item);
                      }}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={4} style={DrugDMConst.styles.noPadding}>
                <Grid.Column width={3} style={DrugDMConst.styles.textCenter}>
                  {!item.compliance && (
                    <Icon
                      name="trash alternate outline"
                      color="red"
                      size="large"
                      onClick={(event: object) => {
                        props.onShowDeleteDrug({
                          meal: props.textField,
                          index: index,
                          drug: item,
                        });
                      }}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={2}></Grid.Column>
                <Grid.Column
                  width={7}
                  style={{
                    ...DrugDMConst.styles.noPadding,
                    ...DrugDMConst.styles.textCenter,
                  }}
                >
                  <TimeTextBox
                    style={{ transform: "scale(0.8)", minWidth: "100%" }}
                    // value={item.confirmTime}
                    value={item.confirmTime || moment().format("HH:mm")}
                    interval={10}
                    disabled={item.status === "hold"}
                    onTimeChange={(event: object, data: string) => {
                      props.onEvent({
                        message: "handleChangeDrugDetail",
                        params: {
                          meal: props.textField,
                          index: index,
                          drug: { ...item, confirmTime: data },
                        },
                      });
                    }}
                    confirmTime={item.confirmTime}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </AccordionDetails>
        );
      })}

      <AccordionActions>
        <Button
          fluid
          style={DrugDMConst.styles.confirmButton}
          onClick={(event) => {
            props.onEvent({
              message: "handleConfirmDrugMeal",
              params: { meal: props.textField },
            });
          }}
        >
          <FormattedMessage id="bplusClinicKey120" /> {props.title}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

// ---------- SubOtherDrug
type SubOtherDrugProps = {
  onEvent: any;
  onShowDrugDetail: any;
  onShowDrugDescription?: any;
  onShowHoldDrug: any;
  onShowDeleteDrug: any;
  title: string;
  data: any;
  textField: string;
};

const SubOtherDrug: React.FC<SubOtherDrugProps> = (props) => {
  const handleShowDrugDetail = (drug: any) => {
    props.onShowDrugDetail(drug);
  };

  const handleShowDrugDescription = (drug: any) => {
    props.onShowDrugDescription?.(drug);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
        <Header as="h4" style={{ flexBasis: "30%" }}>
          {props.title}
        </Header>
        <div style={{ flexBasis: "60%" }}>
          <Icon.Group size="big">
            <Icon name="clock outline" color="yellow" />
            <Icon corner="top right" name="plus" color="yellow" />
          </Icon.Group>
        </div>
        {props.data?.warning && (
          <Icon
            name="warning sign"
            color="red"
            size="large"
            style={{ flexBasis: "10%" }}
          />
        )}
      </AccordionSummary>

      {props.data?.items?.map((item: any, index: number) => {
        return (
          <AccordionDetails key={index}>
            <div style={{ width: "100%" }}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "19%" }}>
                  <Image
                    src={item.image}
                    style={{ paddingBottom: "10px" }}
                    onClick={(event: object) => {
                      handleShowDrugDetail(item);
                    }}
                  />
                </div>
                <div style={{ width: "81%" }}>
                  <Grid style={{ padding: "0px 10px" }}>
                    <Grid.Row columns={2}>
                      <Grid.Column
                        width={11}
                        onClick={(event: object) => {
                          handleShowDrugDetail(item);
                        }}
                      >
                        <Header as="h4" style={DrugDMConst.styles.drugName}>
                          {item.drug_name}
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={5}
                        style={{
                          ...DrugDMConst.styles.noPadding,
                          textAlign: "right",
                        }}
                      >
                        <Header
                          as="h4"
                          style={{ color: DrugDMConst.COLOR_CODE.lightGrey }}
                        >
                          {item.dose} {item.unit}
                        </Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} style={DrugDMConst.styles.noPadding}>
                      <Grid.Column
                        width={16}
                        style={{
                          ...DrugDMConst.styles.noPadding,
                          textAlign: "right",
                        }}
                      >
                        <label
                          style={{ color: DrugDMConst.COLOR_CODE.lightGrey }}
                        >
                          {item.frequency}
                        </label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  alignItems: "baseline",
                }}
              >
                <div style={{ ...DrugDMConst.styles.textCenter, width: "19%" }}>
                  <Button
                    fluid
                    toggle
                    active={item.status === "hold"}
                    color="red"
                    size="mini"
                    onClick={(event: object) => {
                      props.onShowHoldDrug({
                        meal: props.textField,
                        index: index,
                        drug: item,
                      });
                    }}
                  >
                    {item.status === "hold" ? "Cont" : "Hold"}
                  </Button>
                  {!item.compliance && (
                    <Icon
                      name="trash alternate outline"
                      color="red"
                      size="large"
                      style={{ padding: "10px 0px" }}
                      onClick={(event: object) => {
                        props.onShowDeleteDrug({
                          meal: props.textField,
                          index: index,
                          drug: item,
                        });
                      }}
                    />
                  )}
                </div>
                <div style={{ width: "75%" }}>
                  <Grid verticalAlign="middle">
                    <Grid.Row columns={2} style={{ paddingBottom: "0px" }}>
                      {item.confirmTime?.map((tItem: any, tIndex: number) => {
                        return (
                          <>
                            <Grid.Column width={11}>
                              <TimeTextBox
                                key={`${index}_${tIndex}`}
                                style={{
                                  transform: "scale(0.8)",
                                  marginLeft: "10px",
                                }}
                                value={tItem || moment().format("HH:mm")}
                                interval={10}
                                disabled={item.status === "hold"}
                                onTimeChange={(event: object, data: string) => {
                                  let confirmTime: any[] = [
                                    ...item.confirmTime,
                                  ];
                                  confirmTime[tIndex] = data;
                                  props.onEvent({
                                    message: "handleChangeDrugDetail",
                                    params: {
                                      meal: props.textField,
                                      index: index,
                                      drug: {
                                        ...item,
                                        confirmTime: confirmTime,
                                      },
                                    },
                                  });
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <Icon
                                name="minus square"
                                color="red"
                                size="large"
                                disabled={item.status === "hold"}
                                onClick={(event: object) => {
                                  let confirmTime: any[] = [
                                    ...item.confirmTime,
                                  ];
                                  confirmTime.splice(tIndex, 1);
                                  props.onEvent({
                                    message: "handleChangeDrugDetail",
                                    params: {
                                      meal: props.textField,
                                      index: index,
                                      drug: {
                                        ...item,
                                        confirmTime: confirmTime,
                                      },
                                    },
                                  });
                                }}
                              />
                            </Grid.Column>
                          </>
                        );
                      })}
                      <Grid.Column width={3}>
                        <Icon
                          name="plus square"
                          color="orange"
                          size="large"
                          disabled={item.status === "hold"}
                          onClick={(event: object) => {
                            props.onEvent({
                              message: "handleChangeDrugDetail",
                              params: {
                                meal: props.textField,
                                index: index,
                                drug: {
                                  ...item,
                                  confirmTime: [...item.confirmTime, "00:00"],
                                },
                              },
                            });
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {item.confirmTime?.length > 0 && (
                      <Grid.Row
                        columns={2}
                        style={{
                          paddingTop: "0px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Grid.Column width={11}>
                          <Button
                            fluid
                            color="blue"
                            size="mini"
                            style={{ width: "68.75%" }}
                            disabled={item.status === "hold"}
                            onClick={(event) => {
                              props.onEvent({
                                message: "handleConfirmNormalDrug",
                                params: {
                                  meal: props.textField,
                                  index: index,
                                  drug: item,
                                },
                              });
                            }}
                          >
                            <FormattedMessage id="bplusClinicKey119" />
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Grid>
                </div>

                <div>
                  {item?.note_medication && (
                    <Image
                      src="/images/exercise/drug-description-icon.png"
                      style={{ margin: "0 0 -8px 13px", width: "22px" }}
                      onClick={() => {
                        handleShowDrugDescription(item);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </AccordionDetails>
        );
      })}
      <AccordionActions>
        <Button
          fluid
          style={DrugDMConst.styles.confirmButton}
          onClick={(event) => {
            props.onEvent({
              message: "handleConfirmDrugMeal",
              params: { meal: props.textField },
            });
          }}
        >
          <FormattedMessage id="bplusClinicKey120" /> {props.title}
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

// ---------- Tab Detail
type DMDrugTabDetailProps = {
  onEvent: any;
  onShowDrugDetail: any;
  onShowDrugDescription?: any;
  onShowDrugManually: any;
  onShowRecordTime: any;
  holdDrugReasonOptions: any;
  selectedPatient: any;
  drugDataTabDetail: any;
};

const DMDrugTabDetailInitial: DMDrugTabDetailProps = {
  onEvent: () => null,
  onShowDrugDetail: () => null,
  onShowDrugManually: () => null,
  onShowRecordTime: () => null,
  holdDrugReasonOptions: {},
  selectedPatient: {},
  drugDataTabDetail: {},
};

const DMDrugTabDetail: React.FC<DMDrugTabDetailProps> = (props) => {
  const intl = useIntl();
  const [sideEffectNote, setSideEffectNote] = useState<string>("");
  // Modal Hold Drug
  const [modHoldDrug, setModHoldDrug] = useState<any>({
    open: false,
    error: null,
    meal: "",
    index: 0,
    drug: null,
    holdDateType: "today",
    holdEndDate: "",
    holdReason: "",
  });
  const [holdReasonOptions, setHoldReasonOptions] = useState<any>([]);
  // Modal Delete Drug
  const [modConfirm, setModConfirm] = useState<any>({
    open: false,
    title: "",
    content: "",
    meal: "",
    index: 0,
    drug: null,
  });

  useEffect(() => {
    let options: any = [];
    props.holdDrugReasonOptions?.items?.forEach((item: any, index: number) => {
      options.push({
        key: index,
        text:
          item.name === "ระดับน้ำตาลในเลือดต่ำ"
            ? `${intl.formatMessage({ id: "bplusClinicKey712" })}`
            : item.name === "เกิดอาการข้างเคียง"
            ? `${intl.formatMessage({ id: "bplusClinicKey713" })}`
            : item.name === "ยาหมด"
            ? `${intl.formatMessage({ id: "bplusClinicKey714" })}`
            : item.name === "แพทย์สั่งหยุดยา"
            ? `${intl.formatMessage({ id: "bplusClinicKey833" })}`
            : item.name === "รับประทานครบแล้ว"
            ? `${intl.formatMessage({ id: "bplusClinicKey834" })}`
            : "",
        value: item.id,
      });
    });
    setHoldReasonOptions(options);
  }, [props.holdDrugReasonOptions]);

  useEffect(() => {
    setSideEffectNote(props.drugDataTabDetail.sideEffectNote);
  }, [props.drugDataTabDetail.sideEffectNote]);

  const handleGetDrugDetail = (event: object, data: any) => {
    let targetDate: any = moment(props.drugDataTabDetail?.date, "DD/MM/YYYY");
    if (data.name === "backward") {
      targetDate = targetDate.add(-1, "days");
      props.onEvent({
        message: "handleGetDrugDetail",
        params: { date: targetDate },
      });
    } else {
      targetDate = targetDate.add(1, "days");
      props.onEvent({
        message: "handleGetDrugDetail",
        params: { date: targetDate },
      });
    }
  };

  const handleChangeDateRecord = (date: any) => {
    props.onEvent({
      message: "handleGetDrugDetail",
      params: { date: moment(convertToADDate(date), DATE_FORMAT) },
    });
  };

  const handleChangeSideEffectNote = (event: object, { value }: any) => {
    setSideEffectNote(value);
  };

  const handleSaveSideEffectNote = () => {
    props.onEvent({
      message: "handleSaveSideEffectNote",
      params: { sideEffectNote: sideEffectNote },
    });
  };

  // ----- Modal Hold Drug
  const handleShowHoldDrug = ({ meal, index, drug }: any) => {
    console.log(drug.status);
    if (drug.status === "hold") {
      props.onEvent({
        message: "handleContinueDrug",
        params: { meal: meal, index: index, drug: drug },
      });
    } else {
      setModHoldDrug({
        open: true,
        meal: meal,
        index: index,
        drug: drug,
        holdDateType: drug.holdDateType || "today",
        holdEndDate: drug.holdEndDate || "",
        holdReason: drug.holdReason || "",
      });
    }
  };

  const handleCloseHoldDrug = () => {
    setModHoldDrug({ open: false, drug: null, error: null });
  };

  const handleChangeHoldDrug = (event: object, data: any) => {
    if (data.type === "radio" && data.value === "today") {
      setModHoldDrug({
        ...modHoldDrug,
        [data.name]: data.value,
        holdEndDate: "",
      });
    } else if (data.type === "radio" && data.value === "until") {
      setModHoldDrug({
        ...modHoldDrug,
        [data.name]: data.value,
        holdEndDate: "",
      });
    }else {
      setModHoldDrug({ ...modHoldDrug, [data.name]: data.value });
    }
  };

  const handleChangeHoldDrugDate = (date: string) => {
    setModHoldDrug({ ...modHoldDrug, holdEndDate: date });
  };

  const handleHoldDrug = () => {
    if (
      modHoldDrug.holdDateType === "until" &&
      modHoldDrug.holdEndDate === ""
    ) {
      setModHoldDrug({ ...modHoldDrug, error: "ระบุวันที่ต้องการ Hold" });
      return;
    } else if (modHoldDrug.holdReason === "") {
      setModHoldDrug({ ...modHoldDrug, error: "ระบุเหตุผลการ Hold" });
      return;
    }

    props.onEvent({ message: "handleHoldDrug", params: modHoldDrug });

    handleCloseHoldDrug();
  };

  console.log(modHoldDrug);

  // ----- Modal Delete Drug
  const handleShowDeleteDrug = ({ meal, index, drug }: any) => {
    setModConfirm({
      open: true,
      title: "Delete Drug",
      content: `ยืนยันลบยา ${drug.drug_name} ใช่หรือไม่`,
      meal: meal,
      index: index,
      drug: drug,
    });
  };

  const handleCloseDeleteDrug = () => {
    setModConfirm({
      open: false,
      drug: null,
    });
  };

  const handleDeleteDrug = () => {
    props.onEvent({
      message: "handleDeleteDrug",
      params: {
        meal: modConfirm.meal,
        index: modConfirm.index,
        drug: modConfirm.drug,
      },
    });

    handleCloseDeleteDrug();
  };

  return (
    <>
      <PatientPanel
        patientInfo={props.selectedPatient}
        haveBackground={false}
      />

      <DMDatePicker
        date={moment(props.drugDataTabDetail?.date, "DD/MM/YYYY")}
        onChangeStepDateRecord={handleGetDrugDetail}
        onChangeDateRecord={handleChangeDateRecord}
      />
      <Grid verticalAlign="middle" style={{ padding: "20px 10px" }}>
        <Grid.Row columns={2}>
          <Grid.Column floated="left" width={9}>
            <Header
              as="h5"
              style={{ color: DrugDMConst.COLOR_CODE.darkBlue }}
              onClick={props.onShowRecordTime}
            >
              <FormattedMessage id="bplusClinicKey416" />
              <Icon name="cog" />
            </Header>
          </Grid.Column>
          <Grid.Column
            floated="right"
            width={7}
            style={{
              ...DrugDMConst.styles.noPadding,
              flexBasis: "max-content",
            }}
          >
            <Button
              style={DrugDMConst.styles.confirmAllButton}
              size="tiny"
              onClick={(event) => {
                props.onEvent({
                  message: "handleConfirmDrugMeal",
                  params: { meal: "ALL" },
                });
              }}
            >
              <FormattedMessage id="bplusClinicKey121" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SubNormalDrug
        onEvent={props.onEvent}
        onShowDrugDetail={props.onShowDrugDetail}
        onShowDrugDescription={props.onShowDrugDescription}
        onShowHoldDrug={handleShowHoldDrug}
        onShowDeleteDrug={handleShowDeleteDrug}
        title={intl.formatMessage({ id: "bplusClinicKey329" })}
        textField="morning"
        icon="images/drug/icon_morning.png"
        // icon="sun outline"
        data={props.drugDataTabDetail?.morning}
      />
      <SubNormalDrug
        onEvent={props.onEvent}
        onShowDrugDetail={props.onShowDrugDetail}
        onShowDrugDescription={props.onShowDrugDescription}
        onShowHoldDrug={handleShowHoldDrug}
        onShowDeleteDrug={handleShowDeleteDrug}
        title={intl.formatMessage({ id: "bplusClinicKey708" })}
        textField="afternoon"
        icon="images/drug/icon_lunch.png"
        // icon="sun"
        data={props.drugDataTabDetail?.afternoon}
      />
      <SubNormalDrug
        onEvent={props.onEvent}
        onShowDrugDetail={props.onShowDrugDetail}
        onShowDrugDescription={props.onShowDrugDescription}
        onShowHoldDrug={handleShowHoldDrug}
        onShowDeleteDrug={handleShowDeleteDrug}
        title={intl.formatMessage({ id: "bplusClinicKey190" })}
        textField="evening"
        icon="images/drug/icon_evening.png"
        // icon="cloudversify"
        data={props.drugDataTabDetail?.evening}
      />
      <SubNormalDrug
        onEvent={props.onEvent}
        onShowDrugDetail={props.onShowDrugDetail}
        onShowDrugDescription={props.onShowDrugDescription}
        onShowHoldDrug={handleShowHoldDrug}
        onShowDeleteDrug={handleShowDeleteDrug}
        title={intl.formatMessage({ id: "bplusClinicKey81" })}
        textField="bedtime"
        icon="images/drug/icon_night.png"
        // icon="bed"
        data={props.drugDataTabDetail?.bedtime}
      />
      <SubOtherDrug
        onEvent={props.onEvent}
        onShowDrugDetail={props.onShowDrugDetail}
        onShowDrugDescription={props.onShowDrugDescription}
        onShowHoldDrug={handleShowHoldDrug}
        onShowDeleteDrug={handleShowDeleteDrug}
        title={intl.formatMessage({ id: "bplusClinicKey358" })}
        textField="others"
        data={props.drugDataTabDetail?.others}
      />
      <Form style={{ padding: "10px" }}>
        <TextArea
          rows="3"
          placeholder={intl.formatMessage({ id: "bplusClinicKey709" })}
          value={sideEffectNote}
          onChange={handleChangeSideEffectNote}
        />
        <Icon
          name="save outline"
          color="green"
          size="big"
          style={{ marginBottom: "20px", float: "right" }}
          onClick={handleSaveSideEffectNote}
        />
        <Button
          basic
          fluid
          color="blue"
          style={{ margin: "20px 0px 25px" }}
          onClick={props.onShowDrugManually}
        >
          <Icon name="plus" />
          <FormattedMessage id="bplusClinicKey42" />
        </Button>
      </Form>

      <Modal
        closeIcon
        open={modHoldDrug.open}
        closeOnDimmerClick={true}
        onClose={handleCloseHoldDrug}
        className="Modal-holdMedicine"
      >
        <Modal.Header>
          <FormattedMessage id="bplusClinicKey251" />
        </Modal.Header>
        <Modal.Content>
          <Header as="h4" style={{ color: DrugDMConst.COLOR_CODE.cyan }}>
            {modHoldDrug.drug?.drug_name}
          </Header>
          {!!modHoldDrug.error && <Message error header={modHoldDrug.error} />}
        </Modal.Content>
        <Modal.Description style={{ padding: "10px" }}>
          <Grid verticalAlign="middle">
            <Grid.Row columns={3}>
              <Grid.Column width={3}>
                <Radio
                  label={intl.formatMessage({ id: "bplusClinicKey710" })}
                  name="holdDateType"
                  checked={modHoldDrug.holdDateType === "today"}
                  value={intl.formatMessage({ id: "bplusClinicKey710" })}
                  onChange={(e, data) => {handleChangeHoldDrug(e, {...data, value: "today"})}}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Radio
                  label={intl.formatMessage({ id: "bplusClinicKey711" })}
                  name="holdDateType"
                  checked={modHoldDrug.holdDateType === "until"}
                  value={intl.formatMessage({ id: "bplusClinicKey711" })}
                  onChange={(e, data) => {handleChangeHoldDrug(e, {...data, value: "until"})}}
                />
              </Grid.Column>
              <Grid.Column width={10}>
                <DateTextBox
                  style={{ transform: "scale(0.8)" }}
                  disabled={modHoldDrug.holdDateType !== "until"}
                  value={modHoldDrug.holdEndDate}
                  minDate={moment()}
                  onChange={handleChangeHoldDrugDate}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={DrugDMConst.styles.noPadding}>
              <Grid.Column width={16}>
                <FormattedMessage id="bplusClinicKey380" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: "0px 0px 20px" }}>
              <Grid.Column width={16}>
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  search
                  name="holdReason"
                  value={modHoldDrug.holdReason}
                  options={holdReasonOptions}
                  onChange={handleChangeHoldDrug}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
        <Modal.Actions>
          <Button
            fluid
            style={{
              ...DrugDMConst.styles.confirmButton,
              margin: "0px 0px 15px",
            }}
            onClick={handleHoldDrug}
          >
            <FormattedMessage id="bplusClinicKey119" />
          </Button>
        </Modal.Actions>
      </Modal>
      <ModConfirm
        openModal={modConfirm.open}
        onDeny={handleCloseDeleteDrug}
        onApprove={handleDeleteDrug}
        onCloseWithDimmeClick={handleCloseDeleteDrug}
        titleColor="red"
        titleName={modConfirm.title}
        content={modConfirm.content}
      />
    </>
  );
};

DMDrugTabDetail.defaultProps = DMDrugTabDetailInitial;

export default React.memo(DMDrugTabDetail);
