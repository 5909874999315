import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
// Mui
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { History } from "history";
import { FormattedMessage } from "react-intl";

// Icon
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";

// UX
import IDCardVerify from "./IDCardVerify";
import StartFaceScan from "./StartFaceScan";
import FaceRecognition from "./FaceRecognition";
import EditUserProfile from "./EditUserProfile";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Types
import { dropDownOption, registerInfo } from "bplus-lib/register/TypeModal";

type CardKYCStepProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  history: History<{ idCardBase64?: string }>;
  type: "id-card" | "passport" | "face-init" | "face-scan" | "edit-profile";
  rotateType?: "Portrait" | "LandscapeLeft" | "LandscapeRight";

  profileInfo?: any;
  registerInfo?: registerInfo;
  // CommonInterface
  loadingStatus?: Record<string, any>;
  loading?: boolean;
  consentStatus?: boolean;
  errorMessage?: Record<string, any>;
  // options
  dropDownOption?: dropDownOption;
};

// Images
const IMAGES = {
  cancel: "/images/register/cancel-hn.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
  red: "rgb(218, 0, 0)",
  light_blue: "rgb(204 218 237)",
  grey: "#2c2c2c",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  flex_end: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

export const stepeKYC = {
  step_ekyc: {
    marginTop: "2rem",
    "& .number": {
      color: COLORS.blue,
      // padding: "10px",
      borderRadius: "500rem",
      backgroundColor: COLORS.light_blue,
      ...styles.center,
      fontSize: "1.2rem",
      height: "2rem",
      width: "2rem",
      fontWeight: "bold",
    },
    "& .title": {
      marginLeft: "0.5rem",
      fontSize: "1.05rem",
    },
    "& .divider": {
      width: "40px",
      height: "4px",
      borderRadius: "500rem",
      marginTop: "1.15rem",
      backgroundColor: COLORS.light_blue,
      display: "flex",
      "& > div": {
        width: "33.33%",
        "&.step-active": {
          backgroundColor: COLORS.blue,
          height: "100%",
          borderRadius: "500rem",
          width: "35%",
        },
      },
    },
  },
};

const topbarStyles = {
  display: "grid",
  gridTemplateColumns: "50px 1fr 50px",
  paddingTop: "2.5rem",
  padding: "0 1rem",
  "& svg": {
    color: COLORS.grey,
    fontSize: "30px",
    position: "absolute",
  },
  "& .divider": {
    height: "2.5px",
    width: "100%",
    display: "flex",
    marginTop: "5px",
    "& > div:nth-child(1)": {
      backgroundColor: COLORS.red,
      width: "78%",
    },
    "& > div:nth-child(2)": {
      backgroundColor: COLORS.blue,
      width: "23%",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    overflowY: "hidden",

    "& .top-bar": topbarStyles,
    "& [class*=description].PASSPORT": {
      marginBottom: "0rem",
      "& ~ .button-submit-bottom": {
        paddingBottom: "18px",
        marginTop: "1rem !important",
      },
    },
    "@media (max-height: 812px)": {
      "& .top-bar": {
        paddingTop: "1.25rem",
      },
      "& [class*=step_ekyc]": {
        marginTop: "1.25rem",
        "& .divider": {
          marginTop: "0.75rem",
        },
        "& ~ [class*=description].PASSPORT": {
          margin: "0 0 0rem !important",
        },
      },
    },
  },
  header: {
    fontSize: "1.35rem",
    fontWeight: "bold",
    color: COLORS.blue,
  },
  ...stepeKYC,
}));

const CardKYCStep = (props: CardKYCStepProps) => {
  const classes = useStyles();

  useEffect(()=>{
    if (globalThis.mobile?.checkRotate) {
      globalThis.mobile?.checkRotate()
    }
  },[props.type])

  const [openModConfirmCancel, setOpenModConfirmCancel] =
    useState<boolean>(false);

  const handleGoback = () => {
    props.history.goBack();
  };

  const handleOpenModConfirmCancel = () => {
    setOpenModConfirmCancel(true);
  };

  const handleCloseModConfirmCancel = () => {
    setOpenModConfirmCancel(false);
  };

  const handleGotoFeed = async () => {
    props.onEvent({
      message: "HandleCanceleKYC",
      params: { history: props.history },
    });
  };

  return (
    <div
      className={`${classes.screen}${
        props.type === "edit-profile" ? " --15" : ""
      } bg-gradient-container`}
    >
      <div className="top-bar">
        <div>
          {!["face-init", "edit-profile"].includes(props.type) && (
            <KeyboardArrowLeftIcon onClick={handleGoback} />
          )}
        </div>
        <div style={styles.center}>
          <div>
            <div className={classes.header}>
              {props.type === "edit-profile" ? (
                <FormattedMessage id="bplusClinicKey1023" />
              ) : (
                <FormattedMessage id="bplusClinicKey1025" />
              )}
            </div>
            <div className="divider">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="close-icon-right" style={{ margin: "0 13px 10px 0" }}>
          {["edit-profile"].includes(props.type) && (
            <MuiButton
              color="primary"
              variant="text"
              className="--medium"
              onClick={handleOpenModConfirmCancel}
            >
              <CloseIcon />
            </MuiButton>
          )}
        </div>
      </div>
      <StepContent {...props} />

      <DialogConfirm
        open={openModConfirmCancel}
        title={<FormattedMessage id="bplusClinicKey1089" />}
        description={
          <div style={{ whiteSpace: "pre-line", marginTop: "0.5rem" }}>
            <FormattedMessage id="bplusClinicKey1091" />
          </div>
        }
        subTitle={<FormattedMessage id="bplusClinicKey1090" />}
        img={IMAGES.cancel}
        imgSize="150px"
        denyBasic={true}
        // callback
        onApprove={handleGotoFeed}
        onDeny={handleCloseModConfirmCancel}
      />
    </div>
  );
};

const StepContent = (props: CardKYCStepProps) => {
  if (["id-card", "passport"].includes(props.type)) {
    const type = props.type === "id-card" ? "ID_CARD" : "PASSPORT";

    return (
      <IDCardVerify
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        rotateType={props.rotateType}
        type={type}
        history={props.history}
        // CommonInterface
        loadingStatus={props.loadingStatus}
        errorMessage={props.errorMessage}
      />
    );
  } else if (props.type === "face-init") {
    return (
      <StartFaceScan
        onEvent={props.onEvent}
        // data
        history={props.history}
      />
    );
  } else if (props.type === "face-scan") {
    return (
      <FaceRecognition
        onEvent={props.onEvent}
        setProp={props.setProp}
        // data
        rotateType={props.rotateType}
        history={props.history}
        // CommonInterface
        loadingStatus={props.loadingStatus}
        errorMessage={props.errorMessage}
      />
    );
  } else if (props.type === "edit-profile") {
    return (
      <>
        <Dimmer active={props.loading} style={{ position: "fixed" }}>
          <Loader />
        </Dimmer>
        <EditUserProfile
          onEvent={props.onEvent}
          setProp={props.setProp}
          // data
          history={props.history}
          profileInfo={props.profileInfo}
          registerInfo={props.registerInfo}
          consentStatus={props.consentStatus}
          // options
          dropDownOption={props.dropDownOption}
          // CommonInterface
          loadingStatus={props.loadingStatus}
          errorMessage={props.errorMessage}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default React.memo(CardKYCStep);
