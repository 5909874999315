import base from "../config/base.json";

var _settings: Settings | null = null;
const getSettings = () => {
  if (_settings !== null) {
    return _settings
  }
  _settings = new Settings()
  console.log(_settings, "_settings")
  return _settings
}

class Settings {
  API_HOST: string;
  FAST_API: string;
  WS_HOST: string;
  STATIC_HOST: string;
  WEB_RTC: string;
  BOOKING_FUNCTION: boolean;
  OPEN_FORM_ENCOUNTER: boolean;
  HIDE_CALL_BUTTON: boolean;
  CHECKUP_APPOINTMENT_ID: number;
  COMPANY: string;
  PROJECT: string;
  DM_BPLUS_URL: string;
  MOM_BPLUS_URL: string;
  CHILD_BPLUS_URL: string;
  IGNORE_CAN_CHAT: boolean;
  DUBAI_TELE_CONSULT_CODE: string;
  DM_FEATURE: string;
  MOM_FEATURE: string;
  CHILD_FEATURE: string;
  SHOW_POST_PROMOTION_FEED: boolean;
  E_CONSENT_ENABLE: boolean;
  FIREBASE_PROJECT: string;
  PROMOTION_STORAGE_NAME: string;
  DISABLE_CHAT_BOX_DIVISION: string[];
  DISABLE_CHATBOX_SEND_FILE_IMAGE: string[];
  SHARE_CONTENT_URL: string;
  TELEMED_CONSENT_DIAG_RULE: number;
  OAUTH_CLIENT_ID: string;
  OAUTH_CLIENT_SECRET: string;
  OAUTH_REDIRECT_URL: string;
  DO_END_POINT_URL: string;
  ENABLE_PHASEC: boolean;
  ENABLE_CURRENT_MEDICATION: boolean;
  ENABLE_ALLERGY: boolean;
  ENABLE_IMPLANT: boolean;
  SHOW_PATIENT_INFO_FROM_BDMS: boolean;
  PROXY_FUNCTION: {
    "LALAMOVE_QUOTATION": string;
    "GOOGLE_MAP_GEOCODE": string
    "GOOGLE_MAP_SEARCH_TEXT": string
  };
  DISABLE_HAMBURGER_IH: any;
  NO_PATH_REDIRECT: any;
  SSO_HOST: any;
  ENABLE_SMART_PAYMENT: boolean;
  ENABLE_CHATBOX_DOWNLOAD_FILE: boolean;
  MED_SERVICE_TELEMED_ID: number;
  LOGO_REPORT: { type: number, width: number, height: number, src: string; };
  ENABLE_HOSPITAL_APPOINTMENT: string[];
  ENABLE_SMARTAPPOINMENT: boolean;

  constructor(domain = "bplus-expansion-staging.web.app") {

    // กันไม่ให้พลาดตรง config.ts
    if (process.env.NODE_ENV === "production") {
      domain = window.location.hostname;
    }

    const favor = this.selectDomain(domain);
    // url of api server
    this.API_HOST = favor.API_HOST || window.location.origin;
    // url of fastapi server
    this.FAST_API = favor.FAST_API || window.location.origin;
    // websocket server host name
    this.WS_HOST = favor.WS_HOST || window.location.hostname;
    // static host for api such as TRIAGE_LEVEL_CLASSIFY
    this.STATIC_HOST = favor.STATIC_HOST || 'https://admin.ishealth.app';
    // host of web rtc server
    this.WEB_RTC = favor.WEB_RTC || 'https://webrtc.drjaysayhi.com';

    // this.APP_NAME = favor.APP_NAME || null;

    // this.USERNAME_PASSWORD_LOGIN = favor.USERNAME_PASSWORD_LOGIN === undefined ?
    // true : favor.USERNAME_PASSWORD_LOGIN;

    // Enable accounts.thevc authentication options
    // this.PENTA_ACC_FACEBOOK_LOGIN = favor.PENTA_ACC_FACEBOOK_LOGIN === undefined ?
    //   true : favor.PENTA_ACC_FACEBOOK_LOGIN;
    // this.PENTA_ACC_LINE_LOGIN = favor.PENTA_ACC_LINE_LOGIN === undefined ?
    //   true : favor.PENTA_ACC_LINE_LOGIN;
    // this.PENTA_ACC_EMAIL_LOGIN = favor.PENTA_ACC_EMAIL_LOGIN === undefined ?
    //   true : favor.PENTA_ACC_EMAIL_LOGIN;

    // Booking function
    this.BOOKING_FUNCTION = favor.BOOKING_FUNCTION === undefined ?
      true : favor.BOOKING_FUNCTION;

    // // Dashboard
    // this.DASHBOARD = favor.DASHBOARD === undefined ?
    //   true : favor.DASHBOARD;

    // // Webpush
    // this.WEB_PUSH_PUBLIC_KEY = favor.WEB_PUSH_PUBLIC_KEY ||
    //   "BDKyMQyvGoPQ5LvRPBmQGec1_geS441vKvkdYhaNM4WDfd0a403b0BTunKNBST_-mcaR5at3B8FYL-N4xo4x9a0";

    // this.LATEST_ENCOUNTER_TOP = favor.LATEST_ENCOUNTER_TOP === undefined ?
    //   false : favor.LATEST_ENCOUNTER_TOP;

    // this.HIDE_QUEUE_FILTER_DATE = favor.HIDE_QUEUE_FILTER_DATE === undefined ? false : favor.HIDE_QUEUE_FILTER_DATE;

    // this.USE_PAYMENT_STATUS_LABEL = favor.USE_PAYMENT_STATUS_LABEL === undefined ? false : favor.USE_PAYMENT_STATUS_LABEL;

    this.OPEN_FORM_ENCOUNTER = favor.OPEN_FORM_ENCOUNTER === undefined ? true : favor.OPEN_FORM_ENCOUNTER

    this.HIDE_CALL_BUTTON = favor.HIDE_CALL_BUTTON === undefined ? false : favor.HIDE_CALL_BUTTON

    // this.ENABLED_SELECT_HOSPITAL = favor.ENABLED_SELECT_HOSPITAL === undefined ? true : favor.ENABLED_SELECT_HOSPITAL

    this.CHECKUP_APPOINTMENT_ID = favor.CHECKUP_APPOINTMENT_ID === undefined ? 21 : favor.CHECKUP_APPOINTMENT_ID

    // this.RETRY_TIMEOUT = favor.RETRY_TIMEOUT === undefined ? 10 : favor.RETRY_TIMEOUT

    this.COMPANY = favor.COMPANY === undefined ? "" : favor.COMPANY

    this.PROJECT = favor.PROJECT === undefined ? "" : favor.PROJECT

    this.DM_BPLUS_URL = favor.DM_BPLUS_URL === undefined ? "" : favor.DM_BPLUS_URL

    this.MOM_BPLUS_URL = favor.MOM_BPLUS_URL === undefined ? "" : favor.MOM_BPLUS_URL

    this.CHILD_BPLUS_URL = favor.CHILD_BPLUS_URL === undefined ? "" : favor.CHILD_BPLUS_URL

    this.IGNORE_CAN_CHAT = favor.IGNORE_CAN_CHAT === undefined ? false : favor.IGNORE_CAN_CHAT

    this.MED_SERVICE_TELEMED_ID = favor.MED_SERVICE_TELEMED_ID === undefined ? 11 : favor.MED_SERVICE_TELEMED_ID

    this.DUBAI_TELE_CONSULT_CODE = favor.DUBAI_TELE_CONSULT_CODE === undefined ? "dubaiexpo123" : favor.DUBAI_TELE_CONSULT_CODE

    // this.DUBAI_TELE_CONSULT_ENABLE = favor.DUBAI_TELE_CONSULT_ENABLE === undefined ? false : favor.DUBAI_TELE_CONSULT_ENABLE

    this.DM_FEATURE = favor.DM_FEATURE === undefined ? "" : favor.DM_FEATURE;

    this.MOM_FEATURE = favor.MOM_FEATURE === undefined ? "" : favor.MOM_FEATURE;

    this.CHILD_FEATURE =
      favor.CHILD_FEATURE === undefined ? "" : favor.CHILD_FEATURE;

    this.SHOW_POST_PROMOTION_FEED = favor.SHOW_POST_PROMOTION_FEED === undefined ? false : favor.SHOW_POST_PROMOTION_FEED;

    this.E_CONSENT_ENABLE = favor.E_CONSENT_ENABLE === undefined ? false : favor.E_CONSENT_ENABLE;

    this.FIREBASE_PROJECT = favor.FIREBASE_PROJECT === undefined ? "" : favor.FIREBASE_PROJECT

    this.PROMOTION_STORAGE_NAME = favor.PROMOTION_STORAGE_NAME === undefined ? "" : favor.PROMOTION_STORAGE_NAME

    this.DISABLE_CHAT_BOX_DIVISION = favor.DISABLE_CHAT_BOX_DIVISION === undefined ? "" : favor.DISABLE_CHAT_BOX_DIVISION

    this.DISABLE_CHATBOX_SEND_FILE_IMAGE = favor.DISABLE_CHATBOX_SEND_FILE_IMAGE === undefined ? "" : favor.DISABLE_CHATBOX_SEND_FILE_IMAGE

    this.SHARE_CONTENT_URL = favor.SHARE_CONTENT_URL === undefined ? "" : favor.SHARE_CONTENT_URL

    this.TELEMED_CONSENT_DIAG_RULE = favor.TELEMED_CONSENT_DIAG_RULE === undefined ? "" : favor.TELEMED_CONSENT_DIAG_RULE

    this.OAUTH_CLIENT_ID = favor.OAUTH_CLIENT_ID === undefined ? "" : favor.OAUTH_CLIENT_ID

    this.OAUTH_CLIENT_SECRET = favor.OAUTH_CLIENT_SECRET === undefined ? "" : favor.OAUTH_CLIENT_SECRET

    this.OAUTH_REDIRECT_URL = favor.OAUTH_REDIRECT_URL === undefined ? "" : favor.OAUTH_REDIRECT_URL

    this.DO_END_POINT_URL = favor.DO_END_POINT_URL === undefined ? "" : favor.DO_END_POINT_URL

    this.ENABLE_PHASEC = favor.ENABLE_PHASEC === undefined ? false : favor.ENABLE_PHASEC

    this.ENABLE_CURRENT_MEDICATION = favor.ENABLE_CURRENT_MEDICATION === undefined ? false : favor.ENABLE_CURRENT_MEDICATION

    this.ENABLE_ALLERGY = favor.ENABLE_ALLERGY === undefined ? false : favor.ENABLE_ALLERGY

    this.ENABLE_IMPLANT = favor.ENABLE_IMPLANT === undefined ? false : favor.ENABLE_IMPLANT

    this.SHOW_PATIENT_INFO_FROM_BDMS = favor.SHOW_PATIENT_INFO_FROM_BDMS === undefined ? false : favor.SHOW_PATIENT_INFO_FROM_BDMS

    this.PROXY_FUNCTION = favor.PROXY_FUNCTION === undefined ? "" : favor.PROXY_FUNCTION

    this.DISABLE_HAMBURGER_IH = favor.DISABLE_HAMBURGER_IH === undefined ? false : favor.DISABLE_HAMBURGER_IH

    this.NO_PATH_REDIRECT = favor.NO_PATH_REDIRECT === undefined ? false : favor.NO_PATH_REDIRECT

    this.SSO_HOST = favor.SSO_HOST === undefined ? "" : favor.SSO_HOST

    this.ENABLE_SMART_PAYMENT = favor.ENABLE_SMART_PAYMENT === undefined ? false : favor.ENABLE_SMART_PAYMENT

    this.ENABLE_SMART_PAYMENT = favor.ENABLE_SMART_PAYMENT === undefined ? false : favor.ENABLE_SMART_PAYMENT

    this.ENABLE_CHATBOX_DOWNLOAD_FILE = favor.ENABLE_CHATBOX_DOWNLOAD_FILE === undefined ? false : favor.ENABLE_CHATBOX_DOWNLOAD_FILE

    this.LOGO_REPORT = favor.LOGO_REPORT === undefined ? [
      { type: 1, width: 600, height: 202, src: "/static/images/logochula_dent.jpg" },
      { type: 2, width: 600, height: 600, src: "/static/images/logochula.png" }
    ] : favor.LOGO_REPORT

    this.ENABLE_HOSPITAL_APPOINTMENT = favor.ENABLE_HOSPITAL_APPOINTMENT === undefined ? [] : favor.ENABLE_HOSPITAL_APPOINTMENT

    this.ENABLE_SMARTAPPOINMENT = favor.ENABLE_SMARTAPPOINMENT === undefined ? false : favor.ENABLE_SMARTAPPOINMENT
    // this.DM_URL = favor.DM_URL
    // this.MOMNCHILD = favor.MOMNCHILD
    // this.IGNORE_CAN_CHAT = favor.IGNORE_CAN_CHAT
    // console.log(`Settings for ${domain}`);
    // console.log(this);
  }

  selectDomain(domain) {
    if (domain in base) {
      return base[domain]
    } else if (!('default' in base)) {
      throw `cannot find "${domain}" or "default" in settings`
    }
    return base.default
  }
}

// export default new Settings();
export default getSettings();