export const LIMIT_SEARCH = 20;

// Options
export const DOCTOR_SCORE_OPTIONS = [
  { id: 5, value: 5 },
  { id: 4, value: 4 },
  { id: 3, value: 3 },
  { id: 2, value: 2 },
  { id: 1, value: 1 },
];

//code เอามาจากฝั่งเขา เช็คที่ issues 69119 
export const CANCEL_REASON_OPTIONS = [
  { id: 1, name: "ไม่ต้องการปรึกษาแพทย์แล้ว", value: "06"},
  { id: 2, name: "ต้องการเปลี่ยนแพทย์", value: "04"},
  { id: 3, name: "ต้องการเปลี่ยนประเภทการนัดหมาย", value: "07"},
  { id: 4, name: "ไม่สะดวกตามวันเวลาที่นัดหมาย", value: "08"},
];

export const APPOINTMENT_ACTION_OPTIONS = [
  {
    id: 1,
    name: "แก้ไขการนัดหมาย",
  },
  { id: 2, name: "ยกเลิกนัดหมาย" },
];

export const DOCTOR_REVIEW_OPTIONS = [
  // { name: "พร้อมให้บริการ" },
  { id: 1, name: "ล่าสุด" },
  { id: 2, name: "เรตติ้ง - มากไปน้อย" },
  { id: 3, name: "เรตติ้ง - น้อยไปมาก" },
];

export const DOCTOR_SORT_OPTIONS = [
  // { name: "พร้อมให้บริการ" },
  { id: 1, name: "ใกล้ฉัน" },
  { id: 2, name: "ราคา-ต่ำไปสูง", order: { field: "doctor_fee", by: "asc" } },
  { id: 3, name: "ราคา-สูงไปต่ำ", order: { field: "doctor_fee", by: "desc" } },
  {
    id: 4,
    name: "ประสบการณ์แพทย์-มากไปน้อย",
    order: { field: "experience", by: "desc" },
  },
  {
    id: 5,
    name: "ประสบการณ์แพทย์-น้อยไปมาก",
    order: { field: "experience", by: "asc" },
  },
];

export const APIS = {
  CENTER_GROUP_APP: "/appointment/center-group/",
  CENTER_APP: "/appointment/center/",
  DOCTOR_APP: "/appointment/doctor/",
};

export const URLS = {
  SELECT_CLINIC: "/select-clinic",
  APPOINTMENT_FOR: "/appointment-for",
  APPOINTMENT_REASON: "/appointment-reason",
  SELECT_DATETIME: "/select-datetime",
  SUBMIT_REQUEST: "/submit-request",
  CANCEL_APPOINTMENT: "/cancel-appointment",
  SEARCH_DOCTOR: "/search-doctor",
  SELECT_CENTER: "/select-center",
  SELECT_CHECKUP_TYPE: "/select-checkup-type",
  DOCTOR_PROFILE: "/doctor-profile",
  CONFIRM_APPOINTMENT: "/confirm-appointment",
  SELECT_CENTERGROUP: "/select-centergroup",
  SELECT_CHECKUP_HOSPITAL: "/select-checkup-hospital",
  HEALTH_PROBLEM: "/health-problem",
};
