import WasmController from "../react-lib/frameworks/WasmController";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";
import axios from "axios";
import * as utils from "../react-lib/utils";
import CONFIG from "config/config";

// Interface
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";

import V3LabResultView from "issara-sdk/apis/V3LabResultView_apps_PRX";
import V3ChildLabResultView from "issara-sdk/apis/V3ChildLabResultView_apps_PRX";
import V3LabResultByEncounter from "../issara-sdk/apis/V3LabResultByEncounter_apps_PRX";
import {
  ConsultDoctorType,
  DoctorType,
  LabOrderType,
  HistoryResultsType,
} from "bplus-lib/MobHistoryinterface";

export type State = {
  language?: "en-US" | "th-TH";
  intl?: IntlShape;
  userId?: string | number;
  loggedin?: boolean;
  apiToken?: string;
  // message
  loadingStatus?: boolean;
  pdfDownloadStatus?: boolean;
  errorMessage?: any;
  successMessage?: any;

  abortLabResultView?: any
  abortLabResultByEncounter?: any
  loadingLabByEn?: boolean;
  loadingHospital?: boolean;
  getLabParent?: any;
  labParentItems?: LabOrderType[];
  childLabItems?: ChildLabResult[];
  selectedParent?: any;
  visitDateItems?: VisitDateItemsType[];
} & SelectHospitalI.State;

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  userId: "",
  loggedin: false,
  // message
  errorMessage: null,
  successMessage: null,

  loadingLabByEn: false,
  loadingHospital: false,
  pdfDownloadStatus: false,

  abortLabResultView: null,
  abortLabResultByEncounter: null,
  getLabParent: null,
  labParentItems: [],
  visitDateItems: [],
  ...SelectHospitalI.StateInitial,
};

export type VisitDateItemsType = {
  id?: number | string;
  started?: string;
  diag_text?: string;
  chief_complaint?: string;
  present_illness?: string;
  lab_order?: LabOrderType[];
  division_name?: string;
  doctor_details?: DoctorType;
  created?: string;
  edited?: string;
  active?: boolean;
  checkout_time?: string;
  checkout_cause?: string;
  edit_user?: string;
  encounter?: string;
  doctor?: number;
  Diagnosis?: string;
  PatientInstruction?: string;
  hospital_name?: string;
  hospital_code?: string;
  consult_doctors?: ConsultDoctorType[];
};

export type ChildLabResult = {
  id?: number | string;
  product_id?: string;
  specimen_name?: string;
  lab_division_name?: string;
  lab_type_label?: string;
  unit?: string;
  product?: string;
  name?: string;
  name_en?: string;
  service_code?: string;
  active_flag?: 1;
  acronym?: string;
  lab_code?: string;
  is_default?: boolean;
  ref_value_min?: number;
  ref_value_max?: number;
  ref_value_txt?: string;
  restrict_flag?: boolean;
  lab_speciality?: number;
  lab_type?: number;
  is_confidential?: boolean;
  name_confidential?: boolean;
  p_type?: string;
  lab_division?: string;
  specimen?: string;
  lab_unit?: string;
  lab_results?: HistoryResultsType[];
  display_seq?: string;
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | { message: "GetLoginInfo"; params: {} }
  | { message: "closeSnackMessage" }
  | { message: "HandleEvent"; params: { name: string } }
  | {
      message: "handleGetLabParent";
      params: { hospital: any };
    }
  | {
      message: "handleGetChildLabResult";
      params: { hospital: any; code: any };
    }
  | {
      message: "handleDownloadLabResult";
      params: { hospital: any; code: any };
    }
  | { message: "HandleLabResultByEncounter"; params: { hospital: any } }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | SelectHospitalI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {
  console.log("MobLAB DidMount: ");

  SelectHospitalI.SelectedInitialHospital(controller, params);
};

export const DidUpdate: Handler = async (controller, params) => {};

export const closeSnackMessage: Handler = async (controller) => {
  controller.setState({ errorMessage: null, successMessage: null });
};

export const handleGetLabParent: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });
  const state = controller.getState();

  if (state.abortLabResultView) {
    state.abortLabResultView.abort();
  }

  const abortController = new AbortController
  controller.setState({
    abortLabResultView: abortController,
  });

  const [res, resError] = await V3LabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
      period: 1,
    },
    extra: {
      signal: abortController.signal
    },
  });
  if (resError) {
    controller.setState({
      labParentItems: [],
      loadingStatus: false,
      abortLabResultView: null
    });
  } else if (res) {
    if (res.parents_lab_data) {
      controller.setState({
        labParentItems: res.parents_lab_data,
        loadingStatus: false,
        abortLabResultView: null
      });
    } else {
      controller.setState({
        labParentItems: [],
        loadingStatus: false,
        abortLabResultView: null
      });
    }
  } else {
    // case cancel do noting
  }
};

export const handleGetChildLabResult: Handler = async (controller, params) => {
  // controller.setState({ loadingStatus: true });
  const state = controller.getState();
  controller.setState({
    childLabItems: [],
    selectedParent: {},
  });

  const [response, error, network] = await V3ChildLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      parent_lab: params.code,
      period: 1,
      hospital: params.hospitalCode,
    },
  });
  controller.setState({
    loadingStatus: false,
    errorMessage: error ? error : null,
    successMessage: null,
    childLabItems: response,
    selectedParent: { title: params.parentName, code: params.code },
  });
};

export const handleDownloadLabResult: Handler = async (controller, params) => {
  if (globalThis.mobile?.downloadPdf) {
    // Android, Webview
    globalThis.mobile.downloadPdf(params.url + "&filename=" + params.filename);
  } else {
    // PC, Browser
    controller.setState({
      pdfDownloadStatus: true,
    });

    const HOST = `${CONFIG.API_HOST}`;
    let url = `${HOST}/${params.url}`;
    let apiToken = controller.apiToken || Cookies.get("apiToken");

    axios
      .get(url, {
        headers: {
          Authorization: `Token ${apiToken}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        utils.downloadFile(response, params.filename);
        controller.setState({
          pdfDownloadStatus: false,
        });
      })
      .catch((error: any) => {
        console.log("Error getting pdf file", error);
        controller.setState({
          pdfDownloadStatus: false,
          errorMessage: error ? error : null,
        });
      });
  }
};

export const HandleLabResultByEncounter: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    visitDateItems: [],
    loadingLabByEn: true,
  });

  if (state.abortLabResultByEncounter) {
    state.abortLabResultByEncounter.abort();
  }

  const abortController = new AbortController;
  controller.setState({
    abortLabResultByEncounter: abortController,
  });

  const [res, error] = await V3LabResultByEncounter.get({
    apiToken: Cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
      period: 1,
    },
    extra: {
      signal: abortController.signal,
    },
  });

  if (error) {
    controller.setState({
      visitDateItems: [],
      loadingLabByEn: false,
      abortLabResultByEncounter: null
    });
  } else if (res) {
    if (res.results) {
      controller.setState({
        visitDateItems: res.results,
        loadingLabByEn: false,
        abortLabResultByEncounter: null
      });
    } else {
      controller.setState({
        visitDateItems: [],
        loadingLabByEn: false,
        abortLabResultByEncounter: null
      });
    }
  } else {
    // case cancel do noting
  }
};
