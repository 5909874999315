import React, { CSSProperties, useEffect, useState } from "react";
import moment from "moment";
import { Divider, Grid, Header, Button, Icon } from "semantic-ui-react";
import { AppBar } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import "./roundedBarCorners";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import { Skeleton } from "@material-ui/lab";

// UI
import MobHead from "../MobHead";
import { width } from "@mui/system";

// LabResultChart
type LabResultChartProps = {
  // function
  onEvent?: any;
  onBackPress?: any;
  // data
  childLabResult?: any[];
  title?: string;
  height?: number;
  selectedHospital?: any;
  loadingStatus?: boolean;
  pdfDownloadStatus?: boolean;
  selectedParent?: any;
};

const LabResultChartInitial = {
  // function
  onEvent: () => null,
  onBackPress: () => null,
  // data
  childLabResult: [],
  title: "",
  height: 200,
  selectedHospital: null,
  loadingStatus: false,
  pdfDownloadStatus: false,
  selectedParent: {},
};

const COLORS = {
  primary: "var(--primary-theme-color)",
  title: "#0147A3",
  ice_blue: "#E6F1F9",
  dark_ice_blue: "#338EC9",
  light_blue: "#0072BC",
  dark_grey: "#797878",
  super_dark_grey: "#393939",
  red: "#DA0000",
  font_white: "white",
};

const styles = {
  button_back: {
    borderRadius: "5px",
    padding: "10px",
    boxShadow: "0px 2px 3px #c4c4c4",
    margin: "10px",
    background: COLORS.font_white
  } as CSSProperties,
};

const LabResultChart: React.FunctionComponent<LabResultChartProps> = (
  props
) => {
  const [result, setResult] = useState<any[]>([]);
  const [latestVisitNumber, setLatestVisitNumber] = useState("");
  const [latestVisitDate, setLatestVisitDate] = useState("");

  const prepareDrawData = ({
    chart,
    allowIndex,
    allowStack,
  }: {
    chart: any;
    allowIndex?: number[];
    allowStack?: (string | number)[];
  }) => {
    const metaDataList: any[] = [];
    let stacks: any = {};
    const barList: any[] = [];

    for (const [i, dataset] of chart.config.data.datasets.entries()) {
      const meta = chart.getDatasetMeta(i);
      if (allowIndex?.includes(i)) {
        metaDataList.push(meta?.data || []);
      }
      if (allowStack?.includes(dataset.stack)) {
        stacks[`${dataset.stack}`] = [
          ...(stacks[`${dataset.stack}`] || []),
          meta.data.map((bar: any, index: number) => ({
            bar,
            value:
              bar._chart.config.data.datasets[bar._datasetIndex].data[
                bar._index
              ],
          })),
        ];
      }
    }

    stacks = Object.keys(stacks).map((key) => {
      return stacks[key].reduce((result: any, item: any) => {
        item.map((acc: any, index: number) => {
          result[index] = acc;
          // result?.[index]?.value > acc.value ? result?.[index] : acc;
        });
        return result;
      }, []);
    });

    for (const list of [...metaDataList, ...stacks]) {
      list.forEach((bar: any, index: number) => {
        // console.log(index, bar._chart.config.data.datasets.length, bar);
        
        // highlight lastest bar
        if (index >= bar._chart.config.data.datasets[0].origData.length - 1) {
          bar._view.backgroundColor = COLORS.dark_ice_blue;
          bar._model.backgroundColor = COLORS.dark_ice_blue;
        }
        barList.push("bar" in bar ? bar.bar : bar);
      });
    }

    return { barList };
  };

  const leftShift = (parent: any, idx: number) => {
    let tmpData: any = [...(result || [])];
    if (tmpData[idx].startIndex - 1 >= 0) {
      tmpData[idx].startIndex -= 1;
      tmpData[idx].data.labels = [...parent.data.origLabels].slice(
        tmpData[idx].startIndex,
        tmpData[idx].startIndex + 7
      );
      for (let data of tmpData[idx].data.datasets) {
        data.data = [...data.origData].slice(
          tmpData[idx].startIndex,
          tmpData[idx].startIndex + 7
        );
      }
      setResult(tmpData);
    }
  };

  const rightShift = (parent: any, idx: number) => {
    let tmpData: any = [...(result || [])];
    if (tmpData[idx].startIndex + 7 < tmpData[idx].data.origLabels.length) {
      tmpData[idx].startIndex += 1;
      tmpData[idx].data.labels = [...parent.data.origLabels].slice(
        tmpData[idx].startIndex,
        tmpData[idx].startIndex + 7
      );
      for (let data of tmpData[idx].data.datasets) {
        data.data = [...data.origData].slice(
          tmpData[idx].startIndex,
          tmpData[idx].startIndex + 7
        );
      }
      setResult(tmpData);
    }
  };

  useEffect(() => {
    let labResults: any[] = [];
    props.childLabResult?.forEach((item: any) => {
      let labels: any[] = [];
      let datas: any[] = [];
      let values: any[] = [];
      let critical_flags: any[] = [];
      let refValueText: any[] = [];
      let yearNow = moment(new Date(), "YYYY");

      if (item.lab_results?.[0]?.is_numeric) {
        item.lab_results?.forEach((lab: any) => {

          let dd = moment(
            lab?.reported_datetime,
            "YYYY-MM-DDTHH:mm:ssZ"
          )

          let tt = moment(
            lab?.specimen_collect_time,
            "HH:mm:ss"
          )

          // let dt_formatted = `${dd.format("DD/MM/YYYY")} [${tt.isValid() ? tt.format("HH:mm") : dd.format("HH:mm")}]`

          // if (yearNow.diff(dd, "year") < 1) {
          //   labels.push(
          //     [dd.locale("en").format("DD MMM,YY"), tt.isValid() ? `[${tt.format("HH:mm")}]` : `[${dd.format("HH:mm")}]`]
              
          //   );
          //   datas.push(lab?.value);
          //   setLatestVisitNumber(lab?.visit_number);
          //   setLatestVisitDate(lab?.visit_date);
          //   if (lab?.ref_value_txt?.trim() !== "") {
          //     refValueText.push(lab?.ref_value_txt);
          //   }
          // }

          labels.push(
            [dd.format( navigator.languages[0].toLowerCase().includes("th") ? "DD MMM YY" : "DD MMM,YY"), tt.isValid() ? `[${tt.format("HH:mm")}]` : `[${dd.format("HH:mm")}]`]
            
          );
          datas.push({value:lab?.value, critical_flag:lab?.critical_flag});
          values = [...datas.map((a:any)=>a.value)]
          critical_flags = [...datas.map((a:any)=>a.critical_flag)]

          setLatestVisitNumber(lab?.visit_number);
          setLatestVisitDate(lab?.visit_date);
          if (lab?.ref_value_txt?.trim() !== "") {
            refValueText.push(lab?.ref_value_txt);
          }
        });
        
        let minDatas: any[] = [];
        let maxDatas: any[] = [];
        refValueText?.forEach((ref: string) => {
          let tmpMin: number | null = 0;
          let tmpMax: number | null  = 0;
          let tmpRef: string = ref;
          tmpRef = tmpRef.replace("(", "");
          tmpRef = tmpRef.replace(")", "");
          tmpRef = tmpRef.replace("=", "");

          if (tmpRef.search("<") > -1) {
            tmpMin = null;
            tmpMax = parseFloat(tmpRef.replace("<", ""));
            if (tmpMin !== null && tmpMin >= tmpMax) {
              tmpMin = tmpMax - 1;
            }
          } else if (tmpRef.search(">") > -1) {
            tmpMin = parseFloat(tmpRef.replace(">", ""));
            tmpMax = null; 
            if (tmpMax !== null && tmpMin >= tmpMax) {
              tmpMax = tmpMin + 1;
            }
          } else if (tmpRef.search("-") > -1) {
            const tmpArr = tmpRef.split("-");
            tmpMin = parseFloat(tmpArr[0]);
            tmpMax = parseFloat(tmpArr[1]);
          }
          minDatas.push(tmpMin);
          maxDatas.push(tmpMax);
        });

        let chartsets: any[] = [
          {
            label: item?.name,
            data: [...values].splice(-7),
            origData: [...values],
            fill: false,
            backgroundColor: COLORS.ice_blue,
            hoverBackgroundColor: COLORS.dark_ice_blue,
            borderColor: COLORS.ice_blue,
            borderWidth: 0,
            borderRadius: 8,
            borderSkipped: false,
            yAxisID: "lab",
            type: "bar",
          },
        ];
        
        if (minDatas.length > 0) {
          chartsets.push({
            label: "min",
            data: [...minDatas].splice(-7),
            origData: [...minDatas],
            fill: false,
            backgroundColor: "white",
            borderColor: "white",
            yAxisID: "lab",
            type: "line",
          });
        }
        if (maxDatas.length > 0) {
          chartsets.push({
            label: "max",
            data: [...maxDatas].splice(-7),
            origData: [...maxDatas],
            fill: false,
            backgroundColor: "white",
            borderColor: "white",
            yAxisID: "lab",
            type: "line",
          });
        }
        chartsets.push({
          label: "critical_flag",
          data: [...critical_flags].splice(-7),
          origData: [...critical_flags],
        });

        const data: any = {
          origLabels: labels,
          labels: [...labels].splice(-7),
          datasets: [...chartsets],
        };

        const top = 20;
        const radius = 16;
        const allowIndex = [0];

        const plugins = [
          {
            id: "plugins-chart",
            afterDraw: (chart: any) => {
              const ctx = chart.ctx;
              const left = chart.chartArea.left;
              const right = chart.chartArea.right;
              const topY = 0;
              const bottomY = chart.chartArea.bottom;
              const rightY = chart.chartArea.right;

              // draw line
              if (ctx) {
                ctx.save();
                //  left line
                ctx.beginPath();
                ctx.moveTo(left, topY);
                ctx.lineTo(left, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "#C4C4C4";
                ctx.stroke();

                //  right line
                ctx.beginPath();
                ctx.moveTo(right, topY);
                ctx.lineTo(right, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "#C4C4C4";
                ctx.stroke();

                // bottom line
                ctx.beginPath();
                ctx.moveTo(left, bottomY);
                ctx.lineTo(rightY, bottomY);
                ctx.lineWidth = 1;
                ctx.strokeStyle = "#C4C4C4";
                ctx.stroke();
                ctx.restore();
              }
            },

            afterDatasetsDraw: (chart: any) => {
              const ctx = chart.ctx;
              ctx.textAlign = "center";
              ctx.textBaseline = "bottom";
              const barDrawText = (bar: any, barlist: any, index: number) => {
                const ctx = chart.ctx;
                const y_axis = bar._yScale;
                const _model = bar._model;
                const centerX = _model.x;
                const half = _model.width / 2;
                const topY = y_axis.top;
                const centerY = topY + _model.y - top;
                let value = "";
                let critical_flag = "";

                const yAxis = chart.scales["lab"];
                const max = yAxis.max;
                const range = yAxis.ticksAsNumbers[0] - yAxis.ticksAsNumbers[1];
                const labelItems = yAxis._labelItems;
                const lineAt1 =
                  bar._chart.config.data.datasets[2]?.data[bar._index] || undefined; //max
                const lineAt2 =
                  bar._chart.config.data.datasets[1]?.data[bar._index] || undefined; //min

                const scale = (labelItems[1].y - labelItems[0].y) / range;

                const line1 =
                  max < lineAt1 ? 0 : scale * (max - lineAt1) + labelItems[0].y;
                const line2 =
                  max < lineAt2 ? 0 : scale * (max - lineAt2) + labelItems[0].y;

                if (
                  bar._chart.config.data.datasets[bar._datasetIndex]?.data[
                    bar._index
                  ] !== 0
                ) {
                  value =
                    bar._chart.config.data.datasets[bar._datasetIndex]?.data[
                      bar._index
                    ].toString();
                  let flagsData = bar._chart.config.data.datasets.filter((a:any)=>a.label==="critical_flag")[0]?.data
                  if (flagsData)
                    critical_flag = flagsData[bar._index]
                }

                // Label
                ctx.beginPath();
                ctx.font = `${radius}px Arial`;
                ctx.fillStyle = "grey";
                if (lineAt1 > 0 && Number(value) > lineAt1) {
                  ctx.fillStyle = COLORS.red;
                }
                if (lineAt2 > 0 && Number(value) < lineAt2) {
                  ctx.fillStyle = COLORS.red;
                }
                if (critical_flag) {
                  ctx.fillStyle = COLORS.red;
                }
                ctx.fillText(value, centerX, centerY + (radius / 2 - 3));

                // Line 1
                if (line1) {
                  ctx.beginPath();
                  ctx.moveTo(_model.x - half, line1);
                  ctx.lineTo(_model.x + half, line1);
                  ctx.lineWidth = 2;
                  ctx.setLineDash([3, 3]);
                  ctx.strokeStyle = COLORS.light_blue;
                  ctx.stroke();
                  ctx.restore();
                }

                // Line2
                if (line2) {
                  ctx.beginPath();
                  ctx.moveTo(_model.x - half, line2);
                  ctx.lineTo(_model.x + half, line2);
                  ctx.lineWidth = 2;
                  ctx.setLineDash([3, 3]);
                  ctx.strokeStyle = COLORS.light_blue;
                  ctx.stroke();
                  ctx.restore();
                }

                // Circle
                ctx.beginPath();
                ctx.arc(centerX, centerY + 13, 6, 0, 2 * Math.PI, false);
                ctx.fillStyle = "white";
                ctx.fill();
                ctx.setLineDash([]);
                ctx.lineWidth = 3;
                ctx.strokeStyle = "#3c80dd";
                ctx.stroke();
              };

              const { barList } = prepareDrawData({
                chart,
                allowIndex,
              });
              // for (const bar of barList) {
              for (var i = 0; i < barList.length; i++) {
                barDrawText(barList[i], barList, i);
              }
            },
          },
        ];

        const refMaxx = Math.max(...values.concat(...maxDatas));
        let maxx = Math.max(...values.concat(...maxDatas));
        let minn = Math.min(...values.concat(...minDatas));

        if (maxx == minn) {
          minn = minn / 2;
          maxx = maxx * 2;
        }
        if (maxx >= refMaxx) {
          maxx = refMaxx * 1.1;
        }
        maxx = Math.round(maxx / 10) * 10;

        const options: any = {
          onClick: (evt: any, element: any) => {
            if (element.length > 0) {
              // you can also get dataset of your selected element
              console.log(data)
              console.log(data.datasets[element[0]._datasetIndex]);
            }
          },
          cornerRadius: 8, // <= bar chart border radius
          legend: {
            display: false,
          },
          scales: {
            gridLines: {
              color: "white",
              display: false,
            },
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                stacked: true,
                barThickness: 30,
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  beginAtZero: true,
                  max: handleAddScales(maxx),
                  min: 0,
                  // stepSize: (Math.abs(minn) + maxx) / 2,
                  stepSize: (handleAddScales(maxx))/2,
                  // issue 59582 แลปแกน Y แสดงผลผิดปกติในกรณีที่ result เป็นจุดทศนิยม 
                  callback: function(value:any) {
                    return `${value}`
                  }
                },
                type: "linear",
                display: true,
                position: "left",
                id: "lab",
                stacked: false,
                fontColor: COLORS.dark_grey,
              },
            ],
          },
        };

        let lastCriticalFlag = datas[datas.length-1].critical_flag;

        labResults.push({
          labName: item?.name,
          labUnit: item?.unit,
          refValueText: refValueText[refValueText.length - 1],
          lastValue: values[values.length - 1],
          lastCriticalFlag: lastCriticalFlag,
          lastMaxRef: maxDatas[maxDatas.length - 1],
          lastMinRef: minDatas[minDatas.length - 1],
          startIndex: values.length - 7,
          data: data,
          options: options,
          plugins: plugins,
          is_numeric: true,
        });
      } else {
        let items: any[] = [];
        setLatestVisitDate(item.lab_results?.[0]?.visit_date);
        setLatestVisitNumber(item.lab_results?.[0]?.visit_number);

        item.lab_results?.forEach((lab: any) => {
          let dd = moment(
            lab?.reported_datetime,
            "YYYY-MM-DDTHH:mm:ssZ"
          )

          let tt = moment(
            lab?.specimen_collect_time,
            "HH:mm:ss"
          )

          let dt_formatted = `${dd.format("DD/MM/YYYY")}  ${tt.isValid() ? tt.format("HH:mm") : dd.format("HH:mm")}`
          
          items.push({
            reportedDate: dt_formatted,
            data: lab?.value,
          });
        });

        if (props.selectedParent?.code === "M438") {
          if (item?.lab_code === "M4381")
            labResults.push({
              labName: item?.name,
              items: items,
              is_numeric: false,
            });
        } else if (props.selectedParent?.code === "M087") {
          if (item?.lab_code === "M4381")
            labResults.push({
              labName: item?.name,
              items: items,
              is_numeric: false,
            });
        } else {
          labResults.push({
            labName: item?.name,
            items: items,
            is_numeric: false,
          });
        }
      }
    });
    setResult(labResults);
  }, [props.childLabResult]);

  const handleAddScales = (max : number) => {
    let scale = (max*0.1)
    if (scale <= 0) {
      scale = 1.2
    } else if ((max - (max+scale) < 4)) {
      scale = 4
    }
    return max+scale
  }

  const handleDownloadFile = () => {

    const url = "apis/PRX/v3/lab_report_file/?en="+latestVisitNumber+"&hospital="+props.selectedHospital?.code;

    console.log("globalThis", globalThis);
    console.log("----- typeof globalThis.mobile", typeof globalThis.mobile);

    props.onEvent({
      message: "handleDownloadLabResult",
      params: { url: url, filename: `${props.title}-${latestVisitDate}.pdf` },
    });

    // handleCloseModConfirm();
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <Grid verticalAlign="middle" style={{ margin: "0px" }}>
          <Grid.Column floated="left" width={2}>
            <div style={{ position: "absolute" }}>
              <Button
                icon
                size="large"
                onClick={props.onBackPress}
                style={styles.button_back}
              >
                <Icon name="angle left" />
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{ padding: "20px 0px 0px" }}>
            <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                color: "#797878",
                paddingTop: "0px",
              }}
            >
              {props.selectedHospital.name || "-"}
              <Header
                as="h2"
                style={{ color: COLORS.title, marginTop: "10px", fontSize: "18px",
                fontWeight: "bold", }}
              >
                {props.title || "-"}
              </Header>
            </div>
          </Grid.Column>
          <Grid.Column floated="right" width={2}>
            <></>
          </Grid.Column>
        </Grid>
      </div>

      {/* <div
        style={{
          marginTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          width: "100%",
          height: "50px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          fluid
          onClick={handleDownloadFile}
          color="primary"
          loading={props.pdfDownloadStatus}
          style={{
            border: "2px solid",
            fontSize: "20px",
            fontWeight: "bold",
            borderRadius: 25,
            height: "50px",
            background: COLORS.primary,
          }}
        ><FormattedMessage id="bplusClinicKey947" /></Button>
      </div> */}

      <div
        style={{
          display: "flex",
          color: "#787979",
          textAlign: "center",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <FormattedMessage id="bplusClinicKey939" />
      </div>

      {(result || []).length === 0 && props.loadingStatus ? (
        <div style={{ padding: "20px" }}>
          <Skeleton animation="pulse" height={18} width="100%" />
          <Skeleton animation="pulse" height={18} width="100%" />
          <Skeleton animation="pulse" height={18} width="100%" />
        </div>
      ) : (result || []).length === 0 ? (
        <Header as="h3" color="grey" style={{ textAlign: "center" }}>
          <FormattedMessage id="bplusClinicKey336" />
        </Header>
      ) : (
        <></>
      )}

      {result?.map((item: any, index: number) => {
        if (item?.is_numeric) {
          return (
            <div key={index} style={{ width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "14px",
                  backgroundColor: COLORS.ice_blue,
                }}
              >
                <Header
                  as="h3"
                  floated="left"
                  style={{ color: "#000000", fontWeight: "bold", fontSize: "17px" }}
                >
                  {item?.labName}
                </Header>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "14px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    color: "#393939",
                    fontSize: "14px",
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: "auto",
                  }}
                >
                  <FormattedMessage id="bplusClinicKey948" />&nbsp;
                  {((item?.lastMaxRef && item?.lastValue>item?.lastMaxRef) || item?.lastCriticalFlag) ? (
                    <Header as="h2" style={{ color: COLORS.red }}>
                      {item?.lastValue}
                    </Header>
                  ) : (
                    <Header as="h2" style={{ color: "var(--text-dark-blue)" }}>
                      {item?.lastValue}
                    </Header>
                  )}
                  &nbsp;&nbsp;{`${item?.labUnit}`}
                </div>
              </div>

              {item?.refValueText === undefined ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      color: "#393939",
                      fontSize: "14px",
                      textAlign: "center",
                      margin: "auto"
                    }}
                  >
                    <FormattedMessage id="bplusClinicKey949" />&nbsp;
                    {item?.refValueText}
                  </div>
                </div>
              )}

              <div style={{marginTop: "14px",}}>
                <Bar
                  type="bar"
                  height={props.height+10}
                  data={item?.data}
                  options={item?.options}
                  plugins={item?.plugins}
                />
                {item?.data.datasets[0].origData.length >= 5 ? (
                  <>
                  <Button
                    icon
                    size="tiny"
                    onClick={() => {
                      leftShift(item, index);
                    }}
                  >
                    <Icon name="angle left" />
                  </Button>
                  <Button
                    icon
                    size="tiny"
                    onClick={() => {
                      rightShift(item, index);
                    }}
                    style={{ float: "right" }}
                  >
                    <Icon name="angle right" />
                  </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          );
        } else {
          return (
            <Grid key={index} style={{ margin: "0px", width: "100%" }}>
              <Grid.Column width={16} style={{ paddingBottom: "0px" }}>
                <div
                  style={{
                    display: "flex",
                    marginTop: "4px",
                    marginBottom: "10px",
                    backgroundColor: COLORS.ice_blue,
                  }}
                >
                  <Header
                    as="h3"
                    floated="left"
                    style={{ color: "var(--text-black)" }}
                  >
                    {item?.labName}
                  </Header>
                </div>
              </Grid.Column>

              {[...item?.items]?.reverse()?.map((lab: any, index: number) => (
                <>
                <Grid.Row
                  key={index}
                  style={{ padding: "0px", marginLeft: "10px" }}
                >
                  <Grid.Column width={16}>
                    {lab?.data === "DETECTED" ? (
                      <Header
                        as="h3"
                        style={{ color: COLORS.red, margin: "0px" }}
                      >
                        {lab?.data}
                      </Header>
                    ) : (
                      <Header
                        as="h3"
                        style={{
                          color: "var(--text-dark-blue)",
                          margin: "0px",
                        }}
                      >
                        {lab?.data}
                      </Header>
                    )}
                    <Header
                      as="h4"
                      style={{
                        color: "var(--text-grey)",
                        margin: "0px",
                        fontWeight: "normal",
                      }}
                    >
                      {lab?.reportedDate}
                    </Header>
                    <Header
                      as="h4"
                      style={{ color: "var(--text-grey)", margin: "0px" }}
                    >
                      {props.selectedHospital?.name}
                    </Header>
                  </Grid.Column>
                  {/* <Grid.Column width={12} textAlign="right">
                    
                  </Grid.Column> */}
                </Grid.Row>
                <Divider style={{ margin: "14px 18px" }} />
                </>
              ))}

              {/* <Divider /> */}
            </Grid>
          );
        }
      })}
    </>
  );
};

LabResultChart.defaultProps = LabResultChartInitial;

export default LabResultChart;
