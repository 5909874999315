import WasmController from "react-lib/frameworks/WasmController";

import { History } from "history";
import moment from "moment";
import Cookies from "js-cookie";

// APIs
import FastAPIRequestView from "issara-sdk/apis/FastAPIRequestViewM";
// Core
// Users
import UserWithPatientDetail from "issara-sdk/apis/UserWithPatientDetail_users";
// BILL
import get_payment_parameter_api_view from "issara-sdk/apis/get_payment_parameter_api_view_apps_BIL";
// PROFILE
import ProxyMyProfile from "../issara-sdk/apis/ProxyMyProfile_profileM";
// PRX
import ShippingAddressList from "issara-sdk/apis/ShippingAddressList_apps_PRX";
import PendingInvoiceView from "issara-sdk/apis/PendingInvoiceView_apps_PRX";
import EncounterInvoiceView from "issara-sdk/apis/EncounterInvoiceView_apps_PRX";
import ShippingAddressQuote from "issara-sdk/apis/ShippingAddressQuote_apps_PRX";
import EncounterInvoiceConfirmView from "issara-sdk/apis/EncounterInvoiceConfirmView_apps_PRX";
import CompletedInvoiceView from "issara-sdk/apis/CompletedInvoiceView_apps_PRX";
import EncounterCompletedInvoiceView from "issara-sdk/apis/EncounterCompletedInvoiceView_apps_PRX";
import MyPatientAddressList from "issara-sdk/apis/MyPatientAddressList_apps_PRX";
import MyPatientAddressDetail from "issara-sdk/apis/MyPatientAddressDetail_apps_PRX";
import ShippingCompanyList from "issara-sdk/apis/ShippingCompanyList_apps_PRX";
import ProxyOrganizationView from "issara-sdk/apis/ProxyOrganizationView_apps_PRX";
import PendingInvoiceCountView from "issara-sdk/apis/PendingInvoiceCountView_apps_PRX";
import MyShippingOrderDrugList from "issara-sdk/apis/MyShippingOrderDrugList_apps_PRX";
import ReceiptDownloadView from "issara-sdk/apis/ReceiptDownloadView_apps_PRX";
import SmartCheckQueueType from "issara-sdk/apis/SmartCheckQueueType_apps_PRX";
import SmartGetQueueDispense from "issara-sdk/apis/SmartGetQueueDispense_apps_PRX";
import SmartAppointmentPendingPayment from "issara-sdk/apis/SmartAppointmentPendingPayment_apps_PRX";

// Serializer
import PatientAddressSerializer from "issara-sdk/types/PatientAddressSerializer_core";
import ProxyOrganizationSerializer from "issara-sdk/types/ProxyOrganizationSerializer_apps_PRX";

// Types
import * as Types from "./telephar/Types";

// Interface
import * as SelectHospitalI from "./appointment/SelectHospitalInterface";
import * as MobAppointmentI from "./MobAppointmentInterface";

// Utils

// Config

// Types
export type State = Partial<{
  loggedin: boolean;
  platform: string;
  theme: string;
  projectName: string;
  device_id: string | number;
  subscription: any;
  apiToken: string;
  userId: string | number;
  permit: string;
  language: "en-US" | "th-TH";
  channelId: string | number;

  // Common
  patientDetail: any;

  // message
  loadingStatus: Record<string, any>;
  errorMessage: Record<string, any>;
  successMessage: Record<string, any>;

  // Payment
  episodePaymentList: Types.EpisodeDetailType[];
  depositPaymentList: Types.DepositPaymentType[];
  pendingInvoiceDetail:
  | (Types.EpisodeDetailType &
    Types.MedicationFeeDetail & { type: any; checkoutStatus: any })
  | null;
  notiPendingPayment: {
    onsite: number;
    online: number;
    telemed_en: [];
    on_site_en: [];
  };
  paidInvoiceList: Types.EpisodeDetailType[];
  allPaidInvoiceList: Types.EpisodeDetailType[];
  myProfileDetail: Record<string, any>;
  organizationDetail: ProxyOrganizationSerializer & {};

  shippingCompanyList: Types.ShippingCompanySerializer[];
  shippingPendingOrderList: Record<string, any>[];
  // Address
  patientAddressLists: PatientAddressSerializer[];
  selectedPatientAddressId: number | null;
  quotationSelectedAddress: Types.QuotationSelectedAddressType | null;
  medicineReceivingOption: Types.MedicineReceivingOptionType;
  checkQueueType: Types.CheckQueueType | null;
  queueDispenseInfo: Types.QueueDispenseInfoType | null;
  shippingAddressLists: any[];
  patientAddressDetail: PatientAddressSerializer;
  couponAvailableLists: Types.CouponAvailableType[];
}> &
  SelectHospitalI.State &
  MobAppointmentI.State;

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  loadingStatus: {},
  errorMessage: {},
  successMessage: {},
  patientAddressLists: [],
  selectedPatientAddressId: null,
  depositPaymentList:[],
  patientDetail: [],
  notiPendingPayment: {
    onsite: 0,
    online: 0,
    telemed_en:[],
    on_site_en:[]
  },

  ...SelectHospitalI.StateInitial,
};

export type Event =
  // Native
  | { message: "GetLoginInfo"; params: {} }
  | { message: "HandleSetOpenBurger"; params: {} }
  | { message: "HandleBacktoNative"; params: {} }
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  // Payment
  | {
    message: "GetListWaitingPayment";
    params: {
      card: string;
      hn: string;
      status?: boolean;
      telemed?: boolean;
      telemedInvUpdate?: boolean;
    };
  }
  | {
    message: "GetListPaddingPayment";
    params: {card: string, status?: boolean};
  }
  | {
    message: "GetCheckPendingInvoiceQueue";
    params: {
      hospital: string;
      patientId: number;
      card?: string;
      status?: boolean;
    };
  }
  | { message: "GetMyProfileDetail"; params: {} }
  | {
    message: "GetCheckQueueType";
    params: { hospital: string; card?: string; status?: boolean };
  }
  | {
    message: "GetQueueDispenseInfo";
    params: { hospital: string; card?: string; status?: boolean };
  }
  | {
    message: "HandleGetPendingInvoiceDetail";
    params: {
      data: Types.EpisodeDetailType;
      card: string;
      complete?: boolean;
      status?: boolean;
      cardId?: number;
    };
  }
  | {
    message: "PostPaymentParameters";
    params: { invoiceId: number; card: string };
  }
  | { message: "GetOrganizationDetail"; params: {} }
  | {
    message: "GetListMyShippingPendingOrder";
    params: { hospital?: string; status?: boolean; card: string };
  }
  | {
    message: "ConfirmPendingInvoice";
    params: {
      card: string;
      history: History;
      selectedPatientAddressId: string | number;
      deliveryType: "express" | "normal" | "";
      couponCode?: string;
      deliveryDiscount?: number;
      encounterId?: string;
    };
  }
  | {
    message: "ConfirmPayment";
    params: {
      card: string;
      cardCoupon: string;
      deliveryFlag: boolean;
      quotationId?: string;
      encounterId: number;
      patientAddressId?: number;
      couponCode?: string;
      price: string | number;
      shippingCompanyId?: number;
      telemed?: boolean;
      shippingOrderId?: number;
    };
  }
  | {
    message: "GetListPaidInvoice";
    params: { card: string; patientId: number; telemed?: boolean; status?: boolean;};
  }
  | {
    message: "GetListAllPaidInvoice";
    params: { card: string; patientId: number; telemed?: boolean; hospitalCode?: string };
  }
  | { message: "HandleGetCouponListAvailable"; params: {} }
  | { message: "GetShippingCompanyList"; params: {} }
  | {
    message: "HandleDownloadReceipt";
    params: {
      card: string;
      receiptId: number;
      hospital: string;
      linkcode: string;
    };
  }
  // Address
  | { message: "AddNewAddress"; params: { detail: any; card: string } }
  | {
    message: "UpdateAddress";
    params: {
      detail: any;
      editAddress: any;
      card?: string;
      isDefault: boolean;
      withoutReload: boolean;
    };
  }
  | {
    message: "DeleteAddress";
    params: { detail: any; editAddress: any; card: string };
  }
  | {
    message: "GetPatientAddressLists";
    params: {
      patient?: number;
      default?: boolean;
      card?: string;
      status?: boolean;
    };
  }
  | { message: "GetQuotationLalamove"; params: { patientAddressId: number } }
  | { message: "SelectDefaultAddress"; params: { card: string } }
  | {
    message: "GetPatientAddressDetail";
    params: { patientAddressId: string; card: string; status?: boolean };
  }
  | {
    message: "ConfirmShippingAddress";
    params: {
      card: string;
      encounterId: string;
      selectedAddress: Record<string, any>;
    };
  }
  | { message: "GetAppointmentPendingPayment"; params: { patient?: string; backURL: string; } }
  | { message: "GetShippingAddressLists"; params: { encounterId: string } }
  | SelectHospitalI.Event
  | MobAppointmentI.Event;

export type Data = {};

export const DataInitial = {};

const searchParams = new URLSearchParams(window.location.search);
const app = searchParams.get("app");

const MOB_APP = `app=${app}`;

const backURL = encodeURIComponent(
  `${
    window.location.origin
  }/payment-complete/${"appointment"}/?app=MobPayment&deliveryflag=false`
);

export const SHIPPING_COMPANY_CODE = {
  express: "Lala",
  normal: "EMS",
  "": "",
};

type Handler<P = any, R = any> = (
  controller: WasmController<State, Event, Data>,
  params: P
) => R;

type Params<A extends Event["message"]> = Extract<
  Event,
  { message: A }
>["params"];

export const DidMount: Handler = async (controller, params) => {
  console.log("MobPayment DidMount: ");

  let userId = controller.cookies.get("userId");
  let apiToken = controller.cookies.get("apiToken");

  if (!controller.apiToken && apiToken) {
    controller.apiToken = apiToken;
  }

  if (!controller.user && userId) {
    controller.user = userId;
  }

  await SelectHospitalI.SelectedInitialHospital(controller, params);

  const [patientDetail, patientError] = await GetUserWithPatientDetail(
    controller,
    {}
  );

  if (patientError) {
    console.warn("can't get patient Detail");
    return;
  }

  if (patientDetail) {
    controller.setState({
      patientDetail: patientDetail,
      selectedPatientAddressId: null,
    });
  }
};

export const DidUpdate: Handler = async (controller, params) => {
  //
};

export const GetListWaitingPayment: Handler<
  Params<"GetListWaitingPayment">
> = async (controller, params) => {
  let state = controller.getState();

  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });

  // -const patient = {
  //   hospital: state.selectedHospital?.code || "",
  //   hn: params.hn,
  // };
  // console.log("state.selectedHospital?.code:", state.selectedHospital?.code)

  // 01-20-003423
  const [episode] = await PendingInvoiceView.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      is_telemed: params.telemed ? "True" : "False",
      ...(Number(state.selectedHospital?.code) && { hospital: Number(state.selectedHospital?.code)}),
      // hospital: Number(state.selectedHospital?.code),
      telemed_inv_update: params.telemedInvUpdate ? "True" : "False",
    },
  });

  // -GetListEpisodePayments(controller, {
  //   ...patient,
  //   flag: "P",
  // });

  let items = (episode || []).map((item: any) => ({
    ...item,
    hospital_name: state.selectedHospital?.name,
    hospital_code: state.selectedHospital?.code,
  }));

  // -if (params.invoice) {
  //   const promiseArr = items.map((item) =>
  //     GetInvoicePayment(controller, {
  //       ...patient,
  //       en: item.EN,
  //       flag: "y",
  //       delivery_fee: params.deliveryFee,
  //     }).then((res: any) => ({
  //       ...item,
  //       HospitalName: state.selectedHospital?.name,
  //       ...res[0],
  //     }))
  //   );
  //   items = await Promise.all(promiseArr);
  //   items = items.filter((item) => item.invoice);
  // }
  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    episodePaymentList: items.length ? items : [],
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });
};

export const GetListPaddingPayment: Handler<
  Params<"GetListPaddingPayment">
> = async (controller, params) => {

  let state = controller.getState()

  let status = params.status ? "LOADING" : true;
  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });

  const [res, error] = await SmartAppointmentPendingPayment.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      back_url: backURL,
      hospital: Number(state.selectedHospital?.code),
      patient: state.myProfileDetail?.patient,
    },
  })

  const paymentList = (res || []).map((item:any)=> {
    return {...item, 
      hospital_name: state.selectedHospital?.name,
    hospital_code: state.selectedHospital?.code,}
  })
 

  state = controller.getState()
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    depositPaymentList: paymentList || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });
}

export const GetListMyShippingPendingOrder: Handler<
  Params<"GetListMyShippingPendingOrder">
> = async (controller, params) => {
  let state = controller.getState();

  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });

  const [order] = await MyShippingOrderDrugList.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
      status: "PENDING",
      created__gte: moment().format("YYYY-MM-DD"),
      ordering: "-id",
    },
  });

  state = controller.getState();

  status = params.status ? "SUCCESS" : false;

  controller.setState({
    shippingPendingOrderList: order?.items || [],
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });
};

export const HandleGetPendingInvoiceDetail: Handler<
  Params<"HandleGetPendingInvoiceDetail">
> = async (controller, params) => {
  let state = controller.getState();

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  let result = null;

  if (params.complete) {
    [result] = await EncounterCompletedInvoiceView.get({
      apiToken: controller.cookies.get("apiToken"),
      encounter_id: params.data.id,
      params: { hospital: state.selectedHospital?.code },
    });
  } else {
    [result] = await EncounterInvoiceView.get({
      apiToken: controller.cookies.get("apiToken"),
      encounter_id: params.data.id,
    });
  }

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  if (!result.invoice.length && result.online_request_invoice_items?.length) {
    result.invoice = formatInvoiceItems(result.online_request_invoice_items);
  }


 (params.data.receipts||[]).forEach((item : any)=>{
  const medicineReceivingOption = params.data["medicine_receiving_option"]
    if (item.id === params.cardId ) {
      if (!item.checkout_status || !item.drug_status) {
        params.data["checkout_status"] = item.checkout_status
        params.data["drug_status"] = item.drug_status
        params.data["medicine_receiving_option"] =  undefined
      } else if (item.drug_status === "delivery") {
        if (!medicineReceivingOption?.address) {
          params.data["medicine_receiving_option"] =  undefined
        } else {
          params.data["medicine_receiving_option"] = {...medicineReceivingOption, type:item.drug_status}
        }
      } else if (item.drug_status === "pharmacy_room") {
        params.data["medicine_receiving_option"] = {...medicineReceivingOption, type:item.drug_status}
      }
    }
  })

  controller.setState({
    pendingInvoiceDetail: result
      ? {
        ...result,
        invoices: result.invoice,
        ...params.data,
        cardSelected: params.cardId
      }
      : null,
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });
};

export const GetCheckPendingInvoiceQueue: Handler<
  Params<"GetCheckPendingInvoiceQueue">
> = async (controller, params) => {
  let state = controller.getState();

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;
  const bilType = searchParams.get("bil_type") || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  const [[invoice], [queueType]] = await Promise.all([
    PendingInvoiceCountView.get({
      apiToken: controller.cookies.get("apiToken"),
      params: { is_telemed: "False" },
    }),
    GetSMPCheckQueueType(controller, {
      hospital: params.hospital,
    }),
  ]);

  const result = queueType?.Result;
  const queueBadge =
    (result?.queue_type === "B" && result?.is_accept) ||
    result?.queue_type === "A";

  let onsiteCount = queueBadge ? invoice?.on_site : 0;
  let telemedCount = invoice?.telemed || 0

  let invoiceOnSiteList = queueBadge ? invoice.on_site_en : [];
  let invoiceTelemedList = invoice.telemed_en || []
  
  if (bilType !== "") {
    const [episode] = await PendingInvoiceView.get({
      apiToken: controller.cookies.get("apiToken"),
      params: {
        is_telemed: bilType === "telemed"? "True" : "False",
        ...(Number(state.selectedHospital?.code) && { hospital: Number(state.selectedHospital?.code)}),
        // hospital: Number(state.selectedHospital?.code),
      },
    });

    if (bilType === "telemed") {
      telemedCount = (invoice.telemed_en || []).filter((telemedItem : string ) => episode.some((item :any)=> telemedItem === item.number))?.length || 0;
    } else {
      onsiteCount = (invoice.on_site_en || []).filter((onSiteItem : string ) => episode.some((item :any)=> onSiteItem === item.number))?.length || 0;
    }
  }

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
    notiPendingPayment: {
      onsite: onsiteCount || 0,
      online: telemedCount || 0,
      telemed_en: invoiceTelemedList || [],
      on_site_en: invoiceOnSiteList || []
    },
    checkQueueType: queueType,
  });
};

export const SelectDefaultAddress: Handler<
  Params<"SelectDefaultAddress">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const address =
    state.patientAddressLists?.find(
      (item) => item.id === state.selectedPatientAddressId
    ) || {};

  await UpdateAddress(controller, {
    detail: {},
    editAddress: address,
    isDefault: true,
    card: params.card,
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const GetPatientAddressDetail: Handler<
  Params<"GetPatientAddressDetail">
> = async (controller, params) => {
  let state = controller.getState();

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  const [result] = await MyPatientAddressDetail.retrieve({
    apiToken: controller.cookies.get("apiToken"),
    pk: params.patientAddressId,
  });

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
    patientAddressDetail: result,
  });
};

export const ConfirmShippingAddress: Handler<
  Params<"ConfirmShippingAddress">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const profile = state.myProfileDetail || {};

  const selfPickup = state.medicineReceivingOption === "pharmacy_room";

  const data = {
    patient_address: selfPickup ? null : params.selectedAddress.id,
    encounter: params.encounterId,
    self_pickup: selfPickup,
    receiver_name: `${profile.first_name} ${profile.last_name}`,
    receiver_tel: params.selectedAddress.tel_mobile,
  };

  await ShippingAddressList.create({
    apiToken: controller.cookies.get("apiToken"),
    data,
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const ConfirmPendingInvoice: Handler<
  Params<"ConfirmPendingInvoice">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const isTelemed = !!params.encounterId;

  await PendingInvoiceView.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      is_telemed: isTelemed ? "True" : "False",
      hospital: Number(state.selectedHospital?.code),
      ...(isTelemed && { telemed_inv_update: true }),
    },
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });

  const channelid = searchParams.get("channelid");
  const chatParams = channelid ? `&channelid=${channelid}` : "";
  const invUpdateParams = isTelemed ? "&telemedinvupdate=true" : "";

  params.history.push({
    pathname: `/waiting-payment/${params.encounterId || ""}`,
    search: `?${MOB_APP}${chatParams}${invUpdateParams}`,
    state: {},
  });

  // -const currentDate = moment().format("YYYYMMDD");
  const selfPickup = state.medicineReceivingOption === "pharmacy_room";

  const shippingCompanyId = state.shippingCompanyList?.find(
    (item) => item.code === SHIPPING_COMPANY_CODE[params.deliveryType]
  )?.id;

  const data = {
    selfPickup,
    patientAddressId: selfPickup ? null : params.selectedPatientAddressId,
    deliveryType: params.deliveryType,
    couponCode: params.couponCode || "",
    deliveryDiscount: params.deliveryDiscount,
    shippingCompanyId: selfPickup ? null : shippingCompanyId,
  };

  const userId = controller.cookies.get("userId") || "";
  const storage = JSON.parse(localStorage.getItem("opdSelfPay") || "{}");

  localStorage.setItem(
    "opdSelfPay",
    JSON.stringify({ ...storage, [userId]: data })
  );
};

export const ConfirmPayment: Handler<Params<"ConfirmPayment">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const channelid = searchParams.get("channelid") || state.channelId;
  const backurl = `${window.location.origin}/payment-complete/${params.encounterId
    }/?${MOB_APP}&deliveryflag=${params.deliveryFlag}&quotationid=${params.quotationId || ""
    }&patientaddressid=${params.patientAddressId || ""}&price=${params.price || ""
    }&channelid=${channelid || ""}`;

  if (globalThis.MobNative?.storeChatChannel) {
    globalThis.MobNative?.storeChatChannel(channelid)
  } else if ( globalThis.iosNative?.storeChatChannel) {
    globalThis.iosNative?.storeChatChannel(channelid)
  }

  const data = {
    delivery_flag: params.deliveryFlag,
    quotation_id: params.quotationId,
    patient_address: params.patientAddressId,
    coupon_code: params.couponCode,
    shipping_company: params.shippingCompanyId,
    shipping_order: params.shippingOrderId,
    backurl,
  };

  const [result, error] = await EncounterInvoiceConfirmView.post({
    apiToken: controller.cookies.get("apiToken"),
    encounter_id: params.encounterId,
    data,
    params: { hospital: state.selectedHospital?.code },
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
    errorMessage: {
      ...state.errorMessage,
      [params.cardCoupon]: error?.coupon_code ? error.coupon_code : null,
    },
  });

  if (result?.override_payment_link) {
    globalThis.location.href = result?.override_payment_link;
  }
};

export const GetShippingAddressLists: Handler<
  Params<"GetShippingAddressLists">
> = async (controller, params) => {
  const [result] = await ShippingAddressList.list({
    apiToken: controller.cookies.get("apiToken"),
    params: { encounter: params.encounterId },
  });

  controller.setState({
    shippingAddressLists: result?.items || [],
  });
};

export const GetMyProfileDetail: Handler = async (controller, params) => {
  const [res] = await ProxyMyProfile.get({
    apiToken: controller.cookies.get("apiToken"),
  });

  let userId = controller.cookies.get("userId");

  controller.analytics.setUserId(
    userId !== undefined || userId !== "" ? userId : "0"
  );

  controller.setState({ myProfileDetail: res || {} });
};

export const GetOrganizationDetail: Handler = async (controller, params) => {
  const state = controller.getState();

  const [res] = await ProxyOrganizationView.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      code: state.selectedHospital?.code,
    },
  });

  controller.setState({ organizationDetail: res?.items?.[0] || {} });
};

export const GetCheckQueueType: Handler<Params<"GetCheckQueueType">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  const [res] = await GetSMPCheckQueueType(controller, {
    hospital: params.hospital,
  });

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
    checkQueueType: res,
  });
};

export const GetQueueDispenseInfo: Handler<
  Params<"GetQueueDispenseInfo">
> = async (controller, params) => {
  let state = controller.getState();

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  const [res] = await GetSMPQueueDispenseInfo(controller, {
    hospital: params.hospital,
  });

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
    queueDispenseInfo: res,
  });
};

export const GetListPaidInvoice: Handler<Params<"GetListPaidInvoice">> = async (
  controller,
  params
) => {
  let state = controller.getState();
  let status = params.status ? "LOADING" : true;

  if (state.loadingStatus[params.card] === "LOADING") {
    return;
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
  });

  const [result] = await CompletedInvoiceView.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      hospital: state.selectedHospital?.code,
      is_telemed: params.telemed ? "True" : "False",
    },
  });

  const items = (result || []).map((item: any) => ({
    ...item,
    hospital_name: state.selectedHospital?.name,
    hospital_code: state.selectedHospital?.code,
  }));

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: status },
    paidInvoiceList: items,
  });
};


export const GetListAllPaidInvoice: Handler<Params<"GetListAllPaidInvoice">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [result] = await CompletedInvoiceView.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      hospital: params.hospitalCode || state.selectedHospital?.code,
      ...(params.patientId && {patient:params.patientId}),
    },
  });

  const items = (result || []).map((item: any) => ({
    ...item,
    hospital_name: state.selectedHospital?.name,
    hospital_code: state.selectedHospital?.code,
  }));

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
    allPaidInvoiceList: items,
  });
};

export const GetAppointmentPendingPayment: Handler = async (
  controller,
  params
) =>  {

  const [result, error] = await SmartAppointmentPendingPayment.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      back_url: backURL,
      patient: params.patient,
    },
  });

  return [result, error]
}

export const HandleGetCouponListAvailable: Handler = async (
  controller,
  params
) => {
  const [result] = await FastAPIRequestView.get({
    bearer: controller.cookies.get("bearer"),
    endpoint: "/coupon/list-available/",
  });

  controller.setState({
    couponAvailableLists: result?.items || [],
  });
};

export const GetShippingCompanyList: Handler = async (controller, params) => {
  const [result] = await ShippingCompanyList.list({
    apiToken: controller.cookies.get("apiToken"),
  });

  controller.setState({
    shippingCompanyList: result?.items || [],
  });
};

export const HandleDownloadReceipt: Handler<
  Params<"HandleDownloadReceipt">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [arrayBuffer] = await ReceiptDownloadView.get({
    apiToken: controller.cookies.get("apiToken"),
    receipt_id: params.receiptId,
    params: {
      hospital: params.hospital,
    },
    extra: {
      responseType: "arraybuffer",
    },
  });

  if (arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);

    const dataArray = Array.from(uint8Array);
    const base64String = btoa(String.fromCharCode.apply(null, dataArray));

    if (globalThis.MobNative?.downloadPdf) {
      globalThis.MobNative?.downloadPdf(base64String, params.linkcode);
    } else if (globalThis.iosNative?.downloadPdf) {
      globalThis.iosNative?.downloadPdf(base64String, params.linkcode, state.channelId || "");
    } else {
      downloadPDF(base64String, params.linkcode);
    }
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

/* ------------------------------------------------------ */

/*                         // API                         */

/* ------------------------------------------------------ */
const GetSMPCheckQueueType: Handler<
  { hospital: string },
  Promise<[Types.CheckQueueType | null, any]>
> = async (controller, params) => {
  try {
    const [response,error,net] = await SmartCheckQueueType.get({
      apiToken: controller.cookies.get("apiToken"),
      params: { hospital: params.hospital },
    });
  
    let responseErr :any

    if (net?.response?.status === 404) {
      responseErr = {queue_type: "404",is_accept: false}
    }

    return [{ Result: response || responseErr },null];
  } catch (e: any) {
    return [null, e.response.data];
  }
};

const GetSMPQueueDispenseInfo: Handler<
  { hospital: string },
  Promise<[Types.QueueDispenseInfoType | null, any]>
> = async (controller, params) => {
  try {
    const [response] = await SmartGetQueueDispense.get({
      apiToken: controller.cookies.get("apiToken"),
      params: { hospital: params.hospital },
    });

    return [{ Result: response }, null];
  } catch (e: any) {
    return [null, e.response.data];
  }
};

export const GetUserWithPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await UserWithPatientDetail.retrieve({
    pk: controller.cookies.get("userId"),
    apiToken: controller.cookies.get("apiToken"),
  });

  return [res, error];
};

export const PostPaymentParameters: Handler<
  Params<"PostPaymentParameters">
> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const [res, error] = await get_payment_parameter_api_view.post({
    apiToken: controller.cookies.get("apiToken"),
    data: { invoice: params.invoiceId },
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });

  return [res, error];
};

export const AddNewAddress: Handler<{ detail: any; card: string }> = async (
  controller,
  params
) => {
  console.log("controller: ", controller);
  console.log(" params.card: ", params.card);

  let state = controller.getState();

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.card]: true,
    },
    ...(state.successMessage?.[params.card] && {
      successMessage: {
        ...state.successMessage,
        [params.card]: null,
      },
    }),
    ...(state.errorMessage?.[params.card] && {
      errorMessage: {
        ...state.errorMessage,
        [params.card]: null,
      },
    }),
  });

  if (!state.patientDetail) {
    console.warn("Can't get patient id inside patientDetail state");
  }
  let data: any = {};
  data.patient = state.patientDetail.patient;
  data.default = true;
  data.address = {
    name: params.detail.locationName,
    full_address_text: params.detail.address,
    owner_name: params.detail.recipientName,
    tel_mobile: params.detail.phone,
    // channel: params.detail.latlng,
    note: params.detail.extraAddress,
    latitude: params.detail.latitude,
    longitude: params.detail.longitude,
  };

  let [r, e] = await MyPatientAddressList.create({
    data,
    apiToken: controller.apiToken,
    extra: {
      division: controller,
    },
    // data,
  });

  state = controller.getState();

  if (e) {
    // Error
    controller.setState(
      {
        loadingStatus: {
          ...state.loadingStatus,
          [params.card]: false,
        },
        errorMessage: {
          ...state.errorMessage,
          [params.card]: "error",
        },
      },
      () => {
        state = controller.getState();
        controller.setState({
          loadingStatus: {
            ...state.loadingStatus,
            [params.card]: false,
          },
          errorMessage: {
            ...state.errorMessage,
            [params.card]: null,
          },
        });
      }
    );

    return;
  }

  // Sucess
  controller.setState(
    {
      loadingStatus: {
        ...state.loadingStatus,
        [params.card]: false,
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: { status: "success", address: r },
      },
      patientAddressLists: [],
    },
    () => {
      state = controller.getState();
      controller.setState({
        loadingStatus: {
          ...state.loadingStatus,
          [params.card]: false,
        },
        successMessage: {
          ...state.successMessage,
          [params.card]: null,
        },
      });
    }
  );
  // return;
};

export const UpdateAddress: Handler<{
  detail: any;
  editAddress: any;
  card?: string;
  isDefault: boolean;
  withoutReload?: boolean;
}> = async (controller, params) => {
  console.log("UpdateAddress: ");
  console.log("controller: ", controller);

  let state = controller.getState();
  console.log("showLoading");
  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      ...(params?.card && { [params?.card]: true }),
    },
    ...(params?.card &&
      state.successMessage?.[params?.card] && {
      successMessage: {
        ...state.successMessage,
        ...(params?.card && { [params?.card]: null }),
      },
    }),
    ...(params?.card &&
      state.errorMessage?.[params?.card] && {
      errorMessage: {
        ...state.errorMessage,
        ...(params?.card && { [params?.card]: null }),
      },
    }),
  });

  if (!state.patientDetail) {
    console.warn("Can't get patient id inside patientDetail state");
  }

  let data: any = {};

  data.patient = state.patientDetail.patient;
  data.default = params.isDefault || false;

  data.address = {
    id: params.editAddress.address.id,
    name: params.detail.locationName,
    full_address_text: params.detail.address,
    owner_name: params.detail.recipientName,
    tel_mobile: params.detail.phone,
    // channel: params.detail.latlng,
    note: params.detail.extraAddress,
    latitude: params.detail.latitude,
    longitude: params.detail.longitude,
  };

  let [r, e] = await MyPatientAddressDetail.patch({
    pk: params.editAddress.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller,
    },
    data,
  });

  state = controller.getState();

  if (e) {
    console.log("stop loading (error)");
    controller.setState({
      loadingStatus: {
        ...state.loadingStatus,
        ...(params?.card && { [params?.card]: false }),
      },
      errorMessage: {
        ...state.errorMessage,
        ...(params?.card && { [params?.card]: "error" }),
      },
    });
    return;
  }

  console.log("stop loading (success)");
  // Sucess
  controller.setState(
    {
      loadingStatus: {
        ...state.loadingStatus,
        ...(params?.card && { [params?.card]: false }),
      },
      successMessage: {
        ...state.successMessage,
        ...(params?.card && {
          [params?.card]: { status: "success", address: r },
        }),
      },
    },
    () => {
      console.log("clear address (success)");
      state = controller.getState();
      controller.setState({
        successMessage: {
          ...state.successMessage,
          ...(params?.card && { [params?.card]: null }),
        },
      });
    }
  );

  // return;
};

export const DeleteAddress: Handler<{
  detail: any;
  editAddress: any;
  card: string;
}> = async (controller, params) => {
  console.log("DeleteAddress: ");
  console.log("controller: ", controller);
  let state = controller.getState();

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [params.card]: true,
    },
    ...(state.successMessage?.[params.card] && {
      successMessage: {
        ...state.successMessage,
        [params.card]: null,
      },
    }),
    ...(state.errorMessage?.[params.card] && {
      errorMessage: {
        ...state.errorMessage,
        [params.card]: null,
      },
    }),
  });

  if (!state.patientDetail) {
    console.warn("Can't get patient id inside patientDetail state");
  }

  let data: any = {};
  data.patient = state.patientDetail.patient;
  data.default = false;
  data.active = false;
  data.address = {
    id: params.editAddress.address.id,
    name: params.detail.locationName,
    full_address_text: params.detail.address,
    owner_name: params.detail.recipientName,
    tel_mobile: params.detail.phone,
    // channel: params.detail.latlng,
    note: params.detail.extraAddress,
  };

  let [r, e, n] = await MyPatientAddressDetail.patch({
    pk: params.editAddress.id,
    apiToken: controller.apiToken,
    extra: {
      division: controller,
    },
    data,
  });

  state = controller.getState();

  if (e) {
    // Error Can't delete
    controller.setState({
      loadingStatus: {
        ...state.loadingStatus,
        [params.card]: false,
      },
      errorMessage: {
        ...state.successMessage,
        [params.card]: "error",
      },
    });
    return;
  }

  console.log(
    " setSTate clear selectedPatientAddressId params.editAddress.id: ",
    params.editAddress.id
  );
  console.log(
    " setSTate clear state.selectedPatientAddressId : ",
    state.selectedPatientAddressId
  );

  // Success
  controller.setState(
    {
      ...(state.selectedPatientAddressId === params.editAddress.id && {
        selectedPatientAddressId: null,
      }),
      loadingStatus: {
        ...state.loadingStatus,
        [params.card]: false,
      },
      successMessage: {
        ...state.successMessage,
        [params.card]: { status: "success", address: null },
      },
    },
    () => {
      state = controller.getState();
      controller.setState({
        loadingStatus: {
          ...state.loadingStatus,
          [params.card]: false,
        },
        successMessage: {
          ...state.successMessage,
          [params.card]: null,
        },
      });
    }
  );

  // return;
};

export const GetPatientAddressLists: Handler<
  Params<"GetPatientAddressLists">
> = async (controller, params) => {
  let state = controller.getState();

  if (!state.patientDetail) {
    console.warn("Can't get patient id inside patientDetail state");
  }

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  let [r] = await MyPatientAddressList.list({
    params: {
      patient: params.patient || state.patientDetail?.patient,
      default: params.default,
    },
    apiToken: controller.apiToken,
    extra: {
      division: controller,
    },
  });

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  controller.setState({
    patientAddressLists: [...(r?.items || [])],
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });
};

// lalamove

export const GetQuotationLalamove: Handler<
  Params<"GetQuotationLalamove">
> = async (controller, params) => {
  const state = controller.getState();
  
  controller.setState({ quotationSelectedAddress: null });

  const [result] = await ShippingAddressQuote.get({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      hospital: state.selectedHospital?.code,
      patient_address: params.patientAddressId ,
    },
  });

  controller.setState({ quotationSelectedAddress: result || null });
};

// Note - #Unused
// -export const GetQuotationLalamove: Handler<{ selectedAddress: any }> = async (
//   controller,
//   params
// ) => {
//   controller.setState({ quotationSelectedAddress: null });
//   let lat,
//     lng = "";
//   if (params?.selectedAddress?.channel?.length > 0) {
//     [lat, lng] = params?.selectedAddress?.channel?.split(",");
//   }
//   console.log(
//     "GetQuotationLalamove params?.selectedAddress: ",
//     params?.selectedAddress
//   );
//   if (lat === "" || lng === "") {
//     return;
//   }
//   // console.log('selectedAddress: ', selectedAddress);
//   // console.log('selJSON.stringify({ selectedAddress }): ', JSON.stringify({ selectedAddress }) );
//   // const body = JSON.stringify({
//   //   selectedAddress: params?.selectedAddress
//   // });
//   let body = {
//     data: {
//       serviceType: "MOTORCYCLE",
//       language: "th_TH",
//       stops: [
//         {
//           coordinates: {
//             lat: "13.7487515",
//             lng: "100.5832257",
//           },
//           address:
//             "2 ซ.ศูนย์วิจัย 7 ถ.เพชรบุรีตัดใหม่ แขวงบางกะปิ เขตห้วยขวาง กรุงเทพฯ 10310",
//         },
//         {
//           coordinates: {
//             lat,
//             lng,
//           },
//           address: params?.selectedAddress?.note,
//         },
//       ],
//     },
//   };
//   axios
//     .post(CONFIG.PROXY_FUNCTION.LALAMOVE_QUOTATION, body)
//     // .post("http://127.0.0.1:5001/my-b-plus-staging/asia-east1/proxyLalamoveQuotation", body)
//     .then((result) => {
//       // console.log("Status Code: ", result.status);
//       // console.log("Returned data: ", result.data);
//       if (result.data?.status === 201) {
//         controller.setState({ quotationSelectedAddress: result?.data });
//       } else {
//         controller.setState({ quotationSelectedAddress: null });
//       }
//     })
//     .catch((error) => {
//       if (error?.response?.status === 422) {
//         let primaryError = error?.response?.data?.result?.errors?.[0];
//         console.log(
//           `error (id: ${primaryError?.id}) message: ${primaryError?.message}`
//         );
//       } else {
//         console.log("error", error);
//       }
//       controller.setState({ quotationSelectedAddress: null });
//     });
// };

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const downloadPDF = (base64Data: string, name: string) => {
  // Create a Blob from the base64 data
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });

  // Create a download link
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.pdf`;

  // Trigger the download
  link.click();
};

const formatInvoiceItems = (items: any[]) => {
  return items.map((item: any) => ({
    billid: item.id,
    paymentbill: "Y",
    refcode: "",
    bg: [
      {
        bgcode: item.code,
        bgdesc: item.display_name,
        bgabbr: item.display_name,
        bsg: [
          {
            bsgcode: item.pricing_code,
            bsgdesc: item.display_name,
            bsgabbr: item.display_name,
            bsgitem: [
              {
                itemdiscount: item.discount,
                itemafteramount: Number(item.price) - Number(item.discount),
                itembeforeamount: item.price,
                Orderitemabbr: item.mode_name,
                itemquantity: item.quantity,
                UnitTH: "",
                UnitENG: "",
                drugcontrolflag: "Uncontrol",
              },
            ],
          },
        ],
      },
    ],
  }));
};
