import React, { useState, useEffect } from 'react';
import { Input, Button } from 'semantic-ui-react';

const TagManager = (props: any) => {

  const [mode, setMode] = useState("view");
  const [hover, setHover] = useState<number|null>(null);

  useEffect(() => {
    props.onEvent({
      message: "GetTagList",
      params: {}
    })
  }, []);

  const getTagTree = (parent: string | null, level: number, tagList: any[], output: any[]) => {
    for (let row = 0; row < tagList.length; row++) {
      if (tagList[row].parent === parent) {
        output.push({
          ...tagList[row],
          level: level + 1,
          row: row
        });
        output = getTagTree(tagList[row].id, level + 1, tagList, output);
      }
    }
    return output
  }

  let tagTree = getTagTree(null, 0, props.tagList, []);
  tagTree.unshift({ index: -1, id: null, name: "root", level: 0, parent: null });

  return(
    <div 
      style={{ 
        padding: "5px", 
        height: "94vh", 
        border: "solid #cccccc 1px",
        overflow: "scroll",
      }}>
      {tagTree?.map((tag: any, index: number) => (
        <div 
          key={index}
          style={{ cursor: "pointer"}}
          onMouseEnter={(e: any) => {
            setHover(index);
          }}
          onMouseLeave={(e: any) => {
            setHover(null);
          }}>
          {(mode === "edit" && tag.row === props.selectedTagIndex) ?
          <div style={{ marginLeft: `${tag.level}rem` }}>
            <input
              value={tag.name}
              onChange={(e: any) => {
                console.log(`tagList.${props.selectedTagIndex}.name`);
                props.setProp(`tagList.${props.selectedTagIndex}.name`, e.target.value);
              }}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  props.onEvent({
                    message: "SaveTag",
                  });
                  setMode("view");
                }
              }}
            />
            <button 
              onClick={(e: any) => { 
                props.onEvent({
                  message: "SaveTag",
                });
                setMode("view");
              }}>
              OK
            </button>
          </div>:

          <div
            style={{ marginLeft: `${tag.level}rem`, display: "flex" }}
            onClick={(e: any) => { 
              console.log(tag);
              props.setProp("selectedTagIndex", tag.row);
              setMode("edit")
            }}>
            <div>{" ".repeat(tag.level)}{tag.name}</div>
            {hover === index &&
            <div 
              style={{ 
                marginLeft: "5px", 
                padding: "0 5px 0 5px",
                background: "lightgreen", 
                borderRadius: "1rem"
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.onEvent({
                  message: "AddTag",
                  params: { parent: tag.id }
                })
              }}>
              +
            </div>}
            {hover === index &&
            <div 
              style={{ 
                marginLeft: "5px", 
                padding: "0 5px 0 5px",
                background: "red", 
                borderRadius: "1rem"
              }}
              onClick={(e) => {
                e.stopPropagation();
                props.onEvent({
                  message: "DeleteTag",
                  params: { id: tag.id }
                })
              }}>
              x
            </div>}
          </div>
          }
        </div>
      ))}
    </div>
  )
}

export default TagManager